import React from 'react'

export const ClarificationText = () => {
  return (
    <>
      <section className="legalinformation">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="mt-50 mb-120">
                <p className="legal-heading text-center ">
                  MUDİ FİNANSAL TEKNOLOJİ HİZMETLERİ A.Ş
                  <br />
                  MÜŞTERİLERE YÖNELİK KİŞİSEL VERİLERİN İŞLENMESİNE İLİŞKİN
                  <br />
                  AYDINLATMA METNİ
                </p>
                <p className="legal-text text-center">
                  <i>
                    Bu doküman kişisel verilerinizin işlenmesi konusunda 6698
                    sayılı Kişisel Verilerin Korunması Kanunu (“Kanun”) m. 10
                    çerçevesinde aydınlatılmanızı sağlamak amacıyla
                    hazırlanmıştır.
                  </i>
                </p>
                <div className="list-content">
                  <ol className="list-content" type="1">
                    <li className="legal-heading-2">
                      Verilerinizi Kim İşliyor?
                    </li>
                    <p className="legal-text align-justify">
                      Mudi Finansal Teknoloji Hizmetleri A.Ş. (“Şirket”), Ünalan
                      Mah. Libadiye Cad. Emaar Residence No: 82E İç Kapı No: 120
                      Üsküdar/İstanbul, <a href="/">www.getmundi.app</a>{' '}
                      olarak,siz Müşterilerimizden elde ettiğimiz kişisel
                      veriler bakımından Kanun uyarınca Veri Sorumlusu
                      sıfatıyla, talep ettiğimiz ve bizimle paylaşmış olduğunuz
                      kişisel verilerinizin işlenmelerini gerektiren amaç
                      çerçevesinde ve bu amaç ile bağlantılı, sınırlı ve ölçülü
                      şekilde, doğruluğunu ve en güncel halini koruyarak aşağıda
                      açıklanan kapsamda işliyoruz.
                    </p>
                    <li className="legal-heading-2">
                      Hangi Kişisel Verilerinizi, Hangi Amaçlarla ve Hangi
                      Hukuki Nedenlerle İşliyoruz?
                    </li>
                    <p className="legal-text align-justify mb-0">
                      Gerçek kişi Müşteri olarak, sizlerle çalışabilmemiz ve
                      karşılıklı yükümlülüklerimizi yerine getirebilmemiz
                      kişisel verilerinizi işlememizi gerektiriyor. Bu kapsamda;
                      kişisel verilerinizi, hukuka ve dürüstlük kurallarına
                      uygun, doğru ve gerektiğinde güncel; belirli, açık ve
                      meşru amaçlar doğrultusunda; amaçla bağlantılı, sınırlı ve
                      ölçülü bir biçimde işliyor ve saklıyoruz.
                      <br />
                      Bu kapsamda;
                    </p>
                    <ul className="list-text list-style-disc legal-text">
                      <li>
                        <b>Kimlik bilgileriniz</b> (örneğin, ad, soyad, doğum
                        tarihi, doğum yeri, anne adı, baba adı, T.C Kimlik No);
                      </li>
                      <li>
                        <b>İletişim bilgileriniz</b> (cep telefon numarası,
                        e-posta adresi, adres);
                      </li>
                      <li>
                        <b>Görsel ve İşitsel Bilgileriniz</b> (fotoğraf);
                      </li>
                      <li>
                        <b>Meslek bilgileriniz</b> (meslek);
                      </li>
                      <li>
                        <b>Finansal bilgiler</b> (İş ortaklarımız olan Üçüncü
                        Taraf Hizmet Sağlayıcıları nezdinde gerçekleştireceğiniz
                        finansal işlemlerinin tamamlanabilmesi için gerekli olan
                        bilgiler başta olmak üzere örneğin, aylık gelir bilgisi,
                        toplam mal varlığı bilgisi, malvarlığı kaynağı, IBAN
                        numaranız, hesabınızın bağlı bulunduğu Banka bilgisi,
                        bakiye bilgisi, mevduat türü, mevduat miktarı, yatırım
                        tercihleri, getiri tercihleri);
                      </li>
                      <li>
                        <b>İşlem güvenliği bilgileri</b> (kullanıcı adı, parola
                        bilgileri, unvan, web formlarının içeriği, IP adresleri,
                        internet sitemiz ve mobil aplikasyonumuzda izlediğiniz
                        yol gibi internet sitemiz ve mobil aplikasyonumuzu
                        kullanımınızla ilgili elektronik log ve kayıt bilgileri,
                        trafik verisi);
                      </li>
                      <li>
                        <b>Müşteri işlem bilgileri</b> (ürün ve hizmetlerin
                        kullanımı için gerekli olan talepler, talimatlar, çağrı
                        merkezi kayıtları, talep bilgileri);
                      </li>
                      <li>
                        <b>Pazarlama</b> (müşteri davranış bilgileri, anket,
                        çerez kayıtları, kampanya çalışmasıyla elde edilen
                        bilgiler, MAC ID vb.);
                      </li>
                      <li>
                        <b>Risk yönetimi bilgileri</b> (ticari, teknik, idari,
                        risklerin yönetilmesi için işlenen bilgiler);
                      </li>
                    </ul>
                    <p className="legal-text align-justify">
                      Acil durum yönetimi süreçlerinin yürütülmesi, bilgi
                      güvenliği süreçlerinin yürütülmesi, denetim/etik
                      faaliyetlerinin yürütülmesi, erişim yetkilerinin
                      yürütülmesi, faaliyetlerin mevzuata uygun yürütülmesi,
                      finans ve muhasebe işlerinin yürütülmesi,
                      firma/ürün/hizmetlere bağlılık süreçlerinin yürütülmesi,
                      hukuk işlerinin takibi ve yürütülmesi, iletişim
                      faaliyetlerinin yürütülmesi, iş faaliyetlerinin
                      yürütülmesi / denetimi, iş süreçlerinin iyileştirilmesine
                      yönelik önerilerin alınması ve değerlendirilmesi, iş
                      sürekliliğinin sağlanması faaliyetlerinin yürütülmesi,
                      organizasyon ve etkinlik yönetimi,mal / hizmet üretim ve
                      operasyon süreçlerinin yürütülmesi, satış sonrası destek
                      ve satış süreçlerinin yürütülmesi, müşteri ilişkileri
                      süreçlerinin yürütülmesi, müşteri memnuniyetine yönelik
                      aktivitelerin yürütülmesi, pazarlama analiz çalışmalarının
                      yürütülmesi, analiz ve şirket içi raporlama
                      faaliyetlerinin yürütülmesi, reklam/kampanya/promosyon
                      süreçlerinin yürütülmesi, sözleşme süreçlerinin
                      yürütülmesi, risk yönetimi süreçlerinin yürütülmesi,
                      saklama ve arşiv faaliyetlerinin yürütülmesi, stratejik
                      planlama faaliyetlerinin yürütülmesi, talep/şikayetlerin
                      takibi, ürün/hizmetlerin pazarlama süreçlerinin
                      yürütülmesi, veri sorumlusu operasyonlarının güvenliğinin
                      temini, yatırım süreçlerinin yürütülmesi, yetkili kişi,
                      kurum ve kuruluşlara bilgi verilmesi kapsamında
                      dolandırıcılığın önlenmesi, internet yoluyla işlenen
                      suçlarla mücadele edilmesi amaçlarıyla Şirketimiz
                      bünyesinde olan Müşterilerimize ait kişisel verileri
                      işliyoruz.
                      <br />
                      <br />
                      Bu amaçları{' '}
                      <b>aşağıdaki hukuki sebeplerle bütünleştirerek</b> veri
                      işleme süreçlerini yönetiyoruz. Nitekim bu kapsamda;
                      <br />
                      <br />
                      <b>
                        Müşteri sözleşmenizin kurulması, ifası ve sona ermesi
                        süreçlerinin hukuka uygun şekilde yürütülmesi
                      </b>{' '}
                      örneğin kullanıcı sözleşmeleri, onaylanmış protokoller
                      ve/veya sözleşmeler, dilekçe gibi belgelerinin saklanması
                      bu sözleşmeler kapsamında destek hizmetlerinin sunulması
                      vb. hukuki sebebiyle kişisel verilerinizi işliyoruz.
                      <br />
                      <br />
                      <b>Kanunda açık olarak öngörülen durumlar</b> (örneğin
                      5651 sayılı İnternet Ortamında Yapılan Yayınların
                      Düzenlenmesi ve Bu Yayınlar Yoluyla İşlenen Suçlarla
                      Mücadele Edilmesi Hakkında Kanun gereği tutulan internet
                      erişim kayıtları, trafik verisi vb.) da veri işleme
                      dayanaklarımızı oluşturuyor.
                      <br />
                      <br />
                      <b>
                        Müşterilerimizle ilişkimizde hukuki yükümlülüklerimizin
                        yerine getirilmesi{' '}
                      </b>
                      (örneğin iş ortaklarımızla gerçekleştireceğiniz işlemlerde
                      kullanılmak üzere gerekli veri aktarımının yapılabilmesi
                      için banka hesap bilgilerinizin, adresinizin, işlenmesi,
                      vb.) gerekçesiyle de verilerinizi işliyoruz.
                      <br />
                      <br />
                      <b>
                        Bir hakkın tesisi, kullanılması ve korunması için veri
                        işlemenin zorunlu olması
                      </b>{' '}
                      durumlarında (örneğin uyuşmazlıklarda hukuki süreçlerin
                      yürütülmesi) ve bunların dışında kalan durumlarda sizden
                      talep edeceğimiz açık, bilgilendirmeye dayanan ve özgür
                      iradeyle açıklayacağınız rızanız kişisel veri işlemesinin
                      başlıca hukuki dayanaklarını oluşturuyor.
                      <br />
                      <br />
                      <b>
                        Temel hak ve özgürlüklerinize aykırı olmamak koşuluyla
                        Şirketimizin meşru menfaatleri
                      </b>{' '}
                      için veri işlemenin zorunluğu olduğu durumlara ilişkin
                      Şirketimizin iştigal konusunun gerektirdiği
                      faaliyetlerimiz (Şirketimizin hizmetlerinden yararlanan
                      siz Müşterilerimize mobil aplikasyonumuz ve internet
                      sitemiz üzerinden tam ve eksiksiz bir hizmetin
                      sunulabilmesi için, talimatlarımızın iş ortaklarımıza
                      doğru ve eksiksiz aktarımı vb.) kapsamında, kişisel
                      verilerinizin işlenmesini gerekli kılıyor. Nitekim,
                      suistimallerin önlenmesi, usulsüz işlemlerin
                      gerçekleştirilmesinin önüne geçilebilmesi, hırsızlığın
                      engellenmesi, dahili denetimlerin ve araştırmaların
                      gerçekleştirilmesi, Şirkete ait gizli bilgilerin, sırların
                      veya diğer önemli bilgilerin belirlenmesi ve genel
                      güvenlik ve iş güvenliğinin sağlanması nedenleriyle veri
                      işleme faaliyeti yapıyoruz. Ancak, bu durumda da sizin
                      temel hak ve özgürlüklerinize zarar vermemeye büyük bir
                      özen göstermekteyiz.
                      <br />
                      <br />
                      <b>Özel (hassas) nitelikli verileriniz</b> kural olarak
                      işlediğimiz kişisel verileriniz arasında yer almıyor. Bu
                      noktada, Şirketimizle müşteri ilişkileriniz çerçevesinde
                      gerekli dokümanların iletilmesi sürecinde; kimlik
                      bilgilerinizi iletirken, eski nüfus cüzdanlarının
                      suretlerinde din hanesi ve kan grubunuza ilişkin bilgiler
                      yer alabildiğinden bu bilgilerin özel nitelikli kişisel
                      veri olmaları sebebiyle bize vermemenizi ya da karartarak
                      vermenizi önemle rica ediyoruz.
                      <br />
                      <br />
                      İşlemekte olduğumuz kişisel verilerinizi “Kimlik
                      Bilgileri, İletişim Bilgileri, Görsel ve İşitsel Bilgiler,
                      Meslek Bilgileri, Finansal Bilgiler, Müşteri İşlem
                      Bilgileri, Pazarlama, İşlem Güvenliği Bilgileri, Risk
                      Yönetimi Bilgileri” olarak kategorize ediyoruz.
                      <br />
                      <br />
                      Bu kapsamda sözleşmenizin kurulması, ifası ve sona ermesi
                      süreçlerinin hukuka uygun şekilde yürütülmesi, temel hak
                      ve özgürlüklerinize aykırı olmamak koşuluyla Şirketimizin
                      meşru menfaatleri, kanunda açık olarak öngörülen durumlar,
                      müşterilerimizle ilişkimizde hukuki yükümlülüklerimizin
                      yerine getirilmesi, yasal takip durumlarında hakkın
                      tesisi, kullanılması ve korunması için veri işlemenin
                      zorunlu olması durumları ve bunların dışında kalan
                      durumlarda sizden talep edeceğimiz açık, bilgilendirmeye
                      dayanan ve özgür iradeyle açıklayacağınız rızanız kişisel
                      veri işlemesinin hukuki dayanaklarını oluşturuyor.
                      <br />
                      <br />
                      Kişisel bilgilerinizin işlenmesi ile ilgili olarak,
                      Şirketin politika ve prosedürlerinde birçok kural
                      bulunuyor. Bu konuda mobil aplikasyonumuzun Ayarlar –
                      Yasal Bilgiler alanında ve internet sitemizde{' '}
                      <a href="/yasal-bilgiler">
                        www.getmundi.app/yasal-bilgiler
                      </a>{' '}
                      adresinde bulunan Kişisel Verilerin Korunması ve İşlenmesi
                      Politikamızı inceleyebilirsiniz.
                    </p>
                    <li className="legal-heading-2">
                      Kişisel Verilerinizi, Hangi Amaçlarla ve Hangi Hukuki
                      Nedenlerle Aktarıyoruz?
                    </li>
                    <p className="legal-text align-justify mb-0">
                      Kişisel verileriniz, yukarıda belirtilen amaçların
                      gerçekleştirilebilmesi ve kanundan doğan yükümlülüklerin
                      yerine getirilmesi için gerektiği ölçüde ve bu amaçlarla
                      sınırlı olmak kaydıyla; (a) Gerçek Kişiler veya Özel Hukuk
                      Tüzel Kişileri, (b) İş Ortakları, Tedarikçiler ve Üçüncü
                      Taraf Hizmet Sağlayıcıları, (c) Hissedarlar, (d) Yetkili
                      Kamu Kurum ve Kuruluşları (e) Yurtdışındaki İş Ortakları,
                      Tedarikçi (Üçüncü Taraf Hizmet Sağlayıcıları)yla
                      paylaşılacaktır. Veri paylaşılan kişiler ve paylaşım amaç
                      ve hukuki sebeplerini ise aşağıdaki şekilde belirliyoruz:
                    </p>
                    <ol type="a" className="list-text legal-text">
                      <li className="mb-16">
                        <b>Gerçek kişiler veya özel hukuk tüzel kişileri:</b> Bu
                        kapsamda; özellikle, Şirketin faaliyetlerini
                        sürdürebilmesi adına (sözleşmenin ifası), avukat ve
                        hukuk danışmanları (yasal takip durumlarında hakkın
                        tesisi, kullanılması ve korunması için veri işlemenin
                        zorunlu olması), danışmanlık şirketleriyle (sözleşmenin
                        ifası, Şirketin meşru menfaatleri),
                      </li>
                      <li className="mb-16">
                        <b>
                          İş Ortakları, Tedarikçilerimiz (Üçüncü Taraf Hizmet
                          Sağlayıcıları):
                        </b>{' '}
                        Şirket iş ve faaliyetlerinin yürütülmesi için yapılan
                        sözleşmelerin, kurulan sözleşmesel ve ticari ilişkilerin
                        yürütülmesi, Şirket süreçlerimizin verimliliğinin ve
                        güvenliğinin sağlanması, hukuka ve usulüne uygun
                        yürütülmesi için hizmet ve danışmanlık aldığımız
                        firmalar, denetim şirketleri, tedarikçilerimiz, alt
                        yüklenicilerimiz, iş ortaklarımız (Ünlü Menkul Değerler
                        A.Ş), hizmet sağlayan şirketlere (telekomünikasyon
                        hizmeti veren şirketler, bankalar vb.) (Hukuki
                        yükümlülüklerin yerine getirilmesi, sözleşmenin ifası,
                        şirketimizin meşru menfaatleri) gibi aktarım yaptığımız
                        üçüncü taraf hizmet sağlayıcılarının, yasal veri koruma
                        standartlarını sağlamalarını talep ediyor, üçüncü taraf
                        hizmet sağlayıcılarımız ile kişisel verilerinizin
                        güvenliğini taahhüt eden gizlilik anlaşmaları
                        imzalıyoruz.
                      </li>
                      <li className="mb-16">
                        <b>Hissedarlarımız:</b> TTK’dan doğan yükümlülüklerin
                        yerine getirilmesi, Şirket raporlamaları, denetimleri
                        gibi süreçlerle ilgili olarak Hissedarlarımızla (hukuki
                        yükümlülüklerin yerine getirilmesi, şirketin meşru
                        menfaatleri),
                      </li>
                      <li className="mb-16">
                        <b>Yetkili Kamu Kurum ve Kuruluşları:</b> Gerektiği
                        durumlarda özellikle belirtilen veri işleme amaçları
                        kapsamında kişisel verileriniz hukuken yetkili kamu
                        kurumu ve kuruluşlarıyla (kanunda açık olarak öngörülen
                        durumlar, hukuki yükümlülüklerin yerine getirilmesi,
                        Şirketin meşru menfaatleri)paylaşılabilmektedir.
                      </li>
                      <li>
                        <b>
                          Yurtdışındaki İş Ortakları, Tedarikçi (Üçüncü Taraf
                          Hizmet Sağlayıcıları):
                        </b>{' '}
                        Şirketimiz yurt dışında mukim veya sunucuları yurt
                        dışında bulunan iş ortaklarımız, tedarikçilerimiz ve
                        Üçüncü Taraf Hizmet Sağlayıcı şirketlerle (Sosyo Plus
                        Bilgi Bil. Tekn. Dan. Hiz. Tic. A.Ş., Adjust GmbH
                        Zendesk Inc., Meta Platforms Inc, Google LLC gibi)
                        Şirket iş ve faaliyetlerinin yürütülmesi için yapılan
                        sözleşmeler, Şirket süreçlerimizin verimliliğinin ve
                        güvenliğinin sağlanması, hukuka ve usulüne uygun
                        yürütülebilmesi adına yurt dışı ile veri paylaşımı
                        gereklilikleri söz konusu olabiliyor. Bu durumda, yine
                        veri işleme amaçları ve ilkeleri doğrultusunda, mevzuat
                        hükümlerine uygun olarak açık onayınız kapsamında
                        yabancı ülkelere aktarım yapıyoruz. Bu kuruluşlarla
                        çalışmalarımızı imzaladığımız özel KVK Veri Transferi
                        Sözleşmeleri ve gizlilik sözleşmeleri ile gereken
                        güvenlik tedbirleri alınarak kanundaki ilke ve kurallara
                        uygun olarak gerçekleştiriyoruz.{' '}
                        <b>
                          Bu süreçlerimizin güvenli şekilde sürdürülebilmesi
                          bakımından, kişisel verilerinizin yurtdışına
                          aktarılabilmesi için açık onayınızı talep ediyoruz.
                          (1)
                        </b>
                      </li>
                    </ol>
                    <li className="legal-heading-2">
                      Kişisel Verilerinizin Güvenliği ve Saklanması
                    </li>
                    <p className="legal-text align-justify">
                      Kişisel verilerinizi güvenli ortamlarda saklıyor ve
                      aktarım yapılacaksa bu konuda gereken tedbirleri alıyoruz.
                      Bilgilerinizi Şirketimizin kilitli arşivi, güvenlik
                      önlemleri alınmış araç ve uygulamalarında saklıyoruz.
                      Kişisel verilerinizi, işlendikleri ve saklandıkları
                      ortamlarda yetkisiz erişime maruz kalmamaları,
                      kaybolmamaları ve zarar görmemeleri amacıyla, bildirilen
                      amaç ve kapsam dışında kullanılmamak kaydı ile
                      gerektiğinde şifreleyerek, sadece yetkilendirilmiş
                      kişilerin erişebileceği şekilde ve gerekli diğer bilgi
                      güvenliği tedbirleri de alarak işliyoruz.
                      <br />
                      <br />
                      Toplanan kişisel verilerinizi öncelikle ilgili mevzuatta
                      zorunlu kılınan süreler boyunca saklıyoruz. Hukuki
                      gereklilikler özel bir sürenin uygulanmasını gerektiriyor
                      ise bu sürelere uygun hareket ediyoruz. Suça konu bir
                      eylemin söz konusu olması halinde ise kişisel verileriniz
                      5237 sayılı Türk Ceza Kanunu ve ilgili mevzuatta öngörülen
                      zamanaşımı süresince tutuluyor.
                    </p>
                    <li className="legal-heading-2">
                      Kişisel Verilerinizi Nasıl Topluyoruz?
                    </li>
                    <p className="legal-text align-justify">
                      İşlemekte olduğumuz kişisel verilerimizin büyük bir
                      çoğunluğunu fiziki veya elektronik ortamda Şirketimizin
                      siz Müşterilerimiz ile arasındaki ilişki çerçevesinde
                      sunduğunuz bilgilerden elde ediyoruz. İşlediğimiz bilgiler
                      kağıt ortamında (anket, başvuru formları gibi) otomatik
                      olmayan yöntemlerle toplanabildiği gibi, internet sitesi
                      ve/veya mobil aplikasyon üzerinden yapılmış olan tüm
                      yazılı/dijital başvurular gibi yöntemler ile e-mail
                      üzerinden gönderilen belgeler, elektronik formlar,
                      çevrimiçi kanallar vasıtasıyla Şirketimiz ile yapmış
                      olduğunuz yazılı ve sözlü iletişim aracılığıyla
                      paylaştığınız bilgi ve belgeler, SMS, faks veya mektup,
                      posta, kargo ya da kurye hizmetleri gibi araçlar üzerinden
                      Şirketimize göndermiş olduğunuz bilgi ve belgeler, çağrı
                      merkezi ile veya doğrudan Şirketimiz ve/veya iş
                      ortaklarımızla yapmış olduğunuz görüşmelerde paylaştığınız
                      bilgi ve belgeler, Şirketimiz ile imzalamış olduğunuz her
                      türlü sözleşme ve sair her türlü yolla sözlü, yazılı veya
                      elektronik ortamda paylaştığınız kişisel veriler kısmen
                      veya tamamen toplanabiliyor.
                    </p>
                    <li className="legal-heading-2">
                      Kişisel Verileriniz ile İlgili Haklarınız Nelerdir?
                    </li>
                    <p className="legal-text align-justify mb-0">
                      Kanun’un 11. Maddesi gereği kişisel verilerinizin;
                    </p>
                    <ol type="a" className="list-text legal-text">
                      <li>işlenip işlenmediğini öğrenme,</li>
                      <li>işlenmişse bilgi talep etme,</li>
                      <li>
                        işlenme amacını ve amacına uygun kullanılıp
                        kullanılmadığını öğrenme,
                      </li>
                      <li>
                        yurtiçinde/yurtdışında aktarıldığı 3. Kişileri bilme,
                      </li>
                      <li>eksik / yanlış işlenmişse düzeltilmesini isteme,</li>
                      <li>
                        Kanun’un 7. Maddesinde öngörülen şartlar çerçevesinde
                        silinmesini / yok edilmesini isteme,
                      </li>
                      <li>
                        aktarıldığı 3. Kişilere yukarıda sayılan (d) ve (e)
                        bentleri uyarınca yapılan işlemlerin bildirilmesini
                        isteme,
                      </li>
                      <li>
                        münhasıran otomatik sistemler ile analiz edilmesi
                        nedeniyle aleyhinize bir sonucun ortaya çıkmasına itiraz
                        etme,
                      </li>
                      <li>
                        kanuna aykırı olarak işlenmesi sebebiyle zarara
                        uğramanız hâlinde zararın giderilmesini talep etme
                        hakkına sahipsiniz.
                      </li>
                    </ol>
                    <p className="legal-text align-justify">
                      Yukarıda belirtilen haklarınızı kullanmak için kimliğinizi
                      tespit edici gerekli bilgiler ile Kanun’un 11. maddesinde
                      belirtilen haklardan kullanmayı talep ettiğiniz hakkınıza
                      yönelik açıklamalarınızı içeren talebinizi; Şirketimizin
                      mobil aplikasyonunda Ayarlar – Yasal Bilgiler alanında ve
                      web sitesi içerisinde{' '}
                      <a href="/yasal-bilgiler">
                        www.getmundi.app/yasal-bilgiler
                      </a>{' '}
                      adresinde bulunan formu doldurmak suretiyle, Şirketimizin
                      Ünalan Mah. Libadiye Cad. Emaar Residence No: 82E İç Kapı
                      No: 120 Üsküdar/İstanbul adresine kimliğinizi tespit edici
                      belgeler ile bizzat elden iletebilir, noter kanalıyla
                      gönderebilir veya Şirketimizin{' '}
                      <a href="mailto:nemateknoloji@hs01.kep.tr">
                        nemateknoloji@hs01.kep.tr
                      </a>{' '}
                      KEP adresine kayıtlı e-posta ile gönderebilir ya da
                      Kurumun belirlediği diğer yöntemlerle başvurabilirsiniz.
                    </p>
                    <table className="table-3">
                      <tbody>
                        <tr>
                          <td>
                            <i>
                              Yukarıda yer alan aydınlatma metnini tebliğ aldım,
                              okudum ve anladım.
                            </i>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <br />
                            Aydınlatma Metnini Tebliğ Alan Müşteri:
                            <br />
                            <br />
                            <b>Ad Soyad:</b>
                            <br />
                            <br />
                            <b>İmza:</b>
                            <br />
                            <br />
                            <br />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <br />
                    <p className="legal-text align-justify">
                      □
                      <i>
                        {' '}
                        Yukarıda yer alan aydınlatma metnini tebliğ aldığımı,
                        okuduğumu ve anladığımı onaylarım.
                      </i>
                    </p>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

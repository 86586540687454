import React from 'react'
import corporateImg from '../img/corporate-header.webp'
import nextIcon from '../img/next-icon.webp'

export const CorporateHero = () => {
  return (
    <div className="container">
      <div className="row corporate-common-margin-top-bottom">
        <div className="col-lg-5 d-none d-sm-block corporate-header-image order-lg-last order-md-first order-sm-first text-center">
          <img
            className="img-fluid corporate-main-img"
            src={corporateImg}
            alt="mundi"
          ></img>{' '}
        </div>
        <div className="col-lg-7 order-lg-12 order-md-12 order-sm-12 my-auto">
          <h1 className="header-title mb-0">
            YENİ NESİL FİNTEK UYGULAMASI MUNDİ SAYESİNDE
          </h1>
          <div className="header-body">
            Şirketin için yüksek getirili{' '}
            <br className="d-xs-block d-sm-none d-xl-block" />
            yatırım fırsatları Mundi{"'"}de
          </div>
          <div className="row header-point-margin-top">
            <div className="row mx-auto d-flex justify-content-between justify-content-md-start">
              <span className="col-4 col-sm-3 col-md-2 col-lg-3 col-xl-2 header-point">
                Güvenli
              </span>
              <span className="col-4 col-sm-3 col-md-2 col-lg-3 col-xl-2 header-point header-point-middle-margin">
                Masrafsız
              </span>
              <span className="col-3 col-sm-3 col-md-2 col-lg-3 col-xl-2 header-point header-point-end-margin">
                Kolay
              </span>
            </div>
          </div>
          <div className="header-message">
            TL ve USD birikimlerini yetkili kurumlar üzerinden sermaye piyasası
            ürünlerinde güvenle değerlendir, hesabında kalan bakiyeyi avantajlı
            oranlarla nemalandırma imkanı yakala.
          </div>
          <div className="mt-30-collapse-16">
            <a
              className="apply-button standart-button fixedsize-button"
              href="/kurumsal#CorporateForm"
            >
              Hemen Başvur
              <img className="next-icon" src={nextIcon} alt="next"></img>
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

import React from 'react'
import { CookiePolicy } from './cookiepolicy'
import { Privacy } from './privacy'
import { MessagePermission } from './messagepermission'
import { ExpressConsent } from './expressconsent'
import { ClarificationText } from './clarificationtext'
import { ApplicationForm } from './applicationform'
import { Meta } from '../meta'
import { UserAgreement } from './useragreemenet'

export const LegalInformation = () => {
  const metaTitle = 'Mundi - Yasal Bilgiler'
  const metaDescription =
    'KVKK, çevrim içi gizlilik, ticari ileti izni, özelleştirilmiş aydınlatma ve açık rıza metinleri ile başvuru formu bilgilerine ulaş.'
  Meta(metaTitle, metaDescription)

  const handleClick = (event) => {
    event.preventDefault()

    const allWithClass = Array.from(document.querySelectorAll('.option-button'))
    const allTabWithClass = Array.from(document.querySelectorAll('.tab-pane'))

    allWithClass.map((item) => {
      item.classList.remove('active')
    })

    allTabWithClass.map((item) => {
      item.classList.remove('active', 'show')
    })

    const selected = document.getElementById('select' + event.target.value)
    selected?.classList.add('active')
    const selectedtab = document.getElementById(
      'selecttab' + event.target.value
    )
    selectedtab?.classList.add('show', 'active')
  }
  return (
    <>
      <section>
        <div className="container">
          <div className="row mb-60 mt-50-collapse-30">
            <div className="col-sm-12 text-center">
              <p className="header-body">Yasal Bilgiler</p>
            </div>
          </div>
        </div>
        <hr />
      </section>
      <section>
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="row">
                <div className="d-sm-flex">
                  <div
                    className="col-sm-12 nav nav-tabs nav-pills nav-pills-width mt-50-collapse-30 mx-auto"
                    id="v-pills-tab"
                    role="tablist"
                    aria-orientation="vertical"
                  >
                    <div className="col-sm-12 d-none d-sm-none d-md-block">
                      <button
                        onClick={handleClick}
                        className="nav-link active faq-button"
                        id="v-pills-home-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#v-pills-home"
                        type="button"
                        role="tab"
                        aria-controls="v-pills-home"
                        aria-selected="true"
                        value={1}
                      >
                        Kişisel Verilerin Korunması ve İşlenmesi Politikası
                      </button>
                      <button
                        onClick={handleClick}
                        className="nav-link faq-button"
                        id="v-pills-profile-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#v-pills-profile"
                        type="button"
                        role="tab"
                        aria-controls="v-pills-profile"
                        aria-selected="false"
                        value={2}
                      >
                        Çevrim İçi Gizlilik ve Çerez Politikası
                      </button>
                      <button
                        onClick={handleClick}
                        className="nav-link faq-button"
                        id="v-pills-messages-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#v-pills-messages"
                        type="button"
                        role="tab"
                        aria-controls="v-pills-messages"
                        aria-selected="false"
                        value={3}
                      >
                        Ticari Elektronik İleti İzni
                      </button>
                      <button
                        onClick={handleClick}
                        className="nav-link faq-button"
                        id="v-pills-messages-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#v-pills-messages"
                        type="button"
                        role="tab"
                        aria-controls="v-pills-messages"
                        aria-selected="false"
                        value={4}
                      >
                        Özelleştirilmiş Aydınlatma Metinleri - Müşteri
                      </button>
                      <button
                        onClick={handleClick}
                        className="nav-link faq-button"
                        id="v-pills-messages-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#v-pills-messages"
                        type="button"
                        role="tab"
                        aria-controls="v-pills-messages"
                        aria-selected="false"
                        value={5}
                      >
                        İlgili Kişi Başvuru Formu
                      </button>
                      <button
                        onClick={handleClick}
                        className="nav-link faq-button"
                        id="v-pills-messages-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#v-pills-messages"
                        type="button"
                        role="tab"
                        aria-controls="v-pills-messages"
                        aria-selected="false"
                        value={6}
                      >
                        Özelleştirilmiş Açık Rıza Metinleri - Müşteri
                      </button>
                      <button
                        onClick={handleClick}
                        className="nav-link faq-button"
                        id="v-pills-messages-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#v-pills-messages"
                        type="button"
                        role="tab"
                        aria-controls="v-pills-messages"
                        aria-selected="false"
                        value={7}
                      >
                        Kullanıcı Sözleşmesi
                      </button>
                    </div>

                    <div className="col-sm-12 d-md-none mx-auto">
                      <select
                        className="form-select d-md-none"
                        aria-label="Default select example"
                        onChange={handleClick}
                      >
                        <option value="1">
                          Kişisel Verilerin Korunması ve İşlenmesi Politikası
                        </option>
                        <option value="2">
                          Çevrim İçi Gizlilik ve Çerez Politikası
                        </option>
                        <option value="3">Ticari Elektronik İleti İzni</option>
                        <option value="4">
                          Özelleştirilmiş Aydınlatma Metinleri - Müşteri
                        </option>
                        <option value="5">İlgili Kişi Başvuru Formu</option>
                        <option value="6">
                          Özelleştirilmiş Açık Rıza Metinleri - Müşteri
                        </option>
                        <option value="7">Kullanıcı Sözleşmesi</option>
                      </select>
                    </div>
                  </div>

                  <div className="col-sm-8 tab-content" id="v-pills-tabContent">
                    <div
                      className="tab-pane fade show active"
                      id="selecttab1"
                      role="tabpanel"
                      aria-labelledby="v-pills-home-tab"
                    >
                      <Privacy />
                    </div>
                    <div
                      className="tab-pane fade"
                      id="selecttab2"
                      role="tabpanel"
                      aria-labelledby="v-pills-profile-tab"
                    >
                      <CookiePolicy />
                    </div>
                    <div
                      className="tab-pane fade"
                      id="selecttab3"
                      role="tabpanel"
                      aria-labelledby="v-pills-messages-tab"
                    >
                      <MessagePermission />
                    </div>
                    <div
                      className="tab-pane fade"
                      id="selecttab4"
                      role="tabpanel"
                      aria-labelledby="v-pills-messages-tab"
                    >
                      <ClarificationText />
                    </div>
                    <div
                      className="tab-pane fade"
                      id="selecttab5"
                      role="tabpanel"
                      aria-labelledby="v-pills-messages-tab"
                    >
                      <ApplicationForm />
                    </div>
                    <div
                      className="tab-pane fade"
                      id="selecttab6"
                      role="tabpanel"
                      aria-labelledby="v-pills-messages-tab"
                    >
                      <ExpressConsent />
                    </div>
                    <div
                      className="tab-pane fade"
                      id="selecttab7"
                      role="tabpanel"
                      aria-labelledby="v-pills-messages-tab"
                    >
                      <UserAgreement />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

import React from 'react'

export const FeatureFaq = () => {
  return (
    <div className="accordion mt-50 mb-60" id="featureaccordionExample">
      <div className="faq-collapse-body">
        <span id="featureheadingOne">
          <button
            className="accordion-button collapsed bg1"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#featurecollapseOne"
            aria-expanded="false"
            aria-controls="featurecollapseOne"
          >
            <strong className="faq-message-title">
              Mundi neden güvenlidir?{' '}
            </strong>
          </button>
        </span>
        <div
          id="featurecollapseOne"
          className="collapse bg3"
          aria-labelledby="featureheadingOne"
          data-bs-parent="#featureaccordionExample"
        >
          <div className="faq-message-text">
            <p>
              Mundi’ye üye olduktan sonra açtığın tüm yatırım hesapları Sermaye
              Piyasası Kurulu (SPK) ve Mali Suçları Araştırma Kurulu (MASAK)
              denetimine tabi bir aracı kurum bünyesinde açılmaktadır.
            </p>
            <p>
              Ayrıca yatırım hesabındaki sermaye piyasası yatırımların Yatırımcı
              Tazmin Merkezi (YTM) sayesinde devlet güvencesi altındadır.
              Yatırımcı Tazmin Merkezi (YTM) güvencesiyle 2025 yılı için
              birikimin 1.645.665​​ TL’ye kadar tazmin kapsamındadır. Bu tutar
              her yıl ilan edilen yeniden değerleme katsayısı oranında
              artırılır.
            </p>
            <p>
              Mundi, senden aldığı bilgileri resmi kurumlar ve iş ortakları
              haricinde üçüncü şahıs ve kurumlarla asla paylaşmaz.
            </p>
          </div>
        </div>
      </div>
      <div className="faq-collapse-body">
        <span id="featureheadingTwo">
          <button
            className="accordion-button collapsed bg1"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#featurecollapseTwo"
            aria-expanded="false"
            aria-controls="featurecollapseTwo"
          >
            <strong className="faq-message-title">Mundi nasıl çalışır?</strong>
          </button>
        </span>
        <div
          id="featurecollapseTwo"
          className="accordion-collapse collapse"
          aria-labelledby="featureheadingTwo"
          data-bs-parent="#featureaccordionExample"
        >
          <div className="faq-message-text">
            <p>
              Üye olduktan sonra Mundi uygulaması üzerinden Tacirler Yatırım
              Menkul Değerler A.Ş. nezdinde yatırım hesabı açarsın. Banka
              hesaplarından dilediğin tutarı yatırım hesabına aktarırsın.
            </p>
            <p>
              Mundi üzerinden gerçekleştirdiğin bütün yatırım işlemleri Tacirler
              Yatırım Menkul Değerler A.Ş. bünyesinde gerçekleşmektedir.
              Tacirler Yatırım Menkul Değerler A.Ş. Sermaye Piyasası Kurulu
              (SPK) ve Mali Suçları Araştırma Kurulu (MASAK) denetimine tabi bir
              aracı kurumdur.
            </p>
          </div>
        </div>
      </div>
      <div className="faq-collapse-body">
        <span id="featureheadingFour">
          <button
            className="accordion-button collapsed bg1"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#featurecollapseFour"
            aria-expanded="false"
            aria-controls="featurecollapseFour"
          >
            <strong className="faq-message-title">
              Mundi’deki paramı nasıl kullanırım?
            </strong>
          </button>
        </span>
        <div
          id="featurecollapseFour"
          className="accordion-collapse collapse"
          aria-labelledby="featureheadingFour"
          data-bs-parent="#featureaccordionExample"
        >
          <div className="faq-message-text">
            <ul>
              <li>
                Mundi’deki vadesiz TL ve USD birikimini* istediğin zaman,
                istediğin banka hesabına ücretsiz olarak anında aktarabilirsin.
              </li>
              <li>
                Yatırım hesabındaki bakiyenin tamamını resmi tatiller dışında
                hafta içi her gün saat 09:00-15:00 arasında istediğin banka
                hesabına aktarabilirsin. Bu hesaplardaki bakiyen gecelik olarak
                değerlenmek üzere saat 15:00’te tekrar bağlanır.
              </li>
              <li>
                Mundi Kart ile vadesiz hesaplarındaki* TL ve USD bakiyeni banka
                hesabına transfer etmek zorunda kalmadan harcamaların için
                kullanabilirsin. Mundi Kart, Mastercard güvencesiyle sunduğumuz
                bir ön ödemeli karttır, bir kredi kartı değildir ve sadece
                hesabındaki bakiyeyi kullanır.
              </li>
            </ul>
            <p className="information-text">
              *Mundi Kart ve dijital cüzdan altyapısı 6493 sayılı kanun ve
              ilgili mevzuata uygun şekilde Sipay Elektronik Para ve Ödeme
              Hizmetleri A.Ş. tarafından sağlanmaktadır.
            </p>
          </div>
        </div>
      </div>
      <div className="faq-collapse-body">
        <span id="featureheadingFive">
          <button
            className="accordion-button collapsed bg1"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#featurecollapseFive"
            aria-expanded="false"
            aria-controls="featurecollapseFive"
          >
            <strong className="faq-message-title">
              Bilgim dışında herhangi bir işlem gerçekleşir mi?
            </strong>
          </button>
        </span>
        <div
          id="featurecollapseFive"
          className="accordion-collapse collapse"
          aria-labelledby="featureheadingFive"
          data-bs-parent="#featureaccordionExample"
        >
          <div className="faq-message-text">
            <p>
              Yatırım hesabında talimatın olmadan hiçbir işlem yapılmaz.
              Birikimlerinin tüm kontrolü her zaman sendedir.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

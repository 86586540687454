import React from 'react'
import product1 from '../img/productheader1.webp'
import product2 from '../img/productheader2.webp'
import productmobile from '../img/productheadermobile.webp'
import { FeatureFaq } from './featurefaq'
import { Features } from './feature'
import { Slider } from './slider'
import { DownloadButton } from './downloadbutton'
import { Meta } from '../meta'

export const Product = () => {
  const metaTitle = 'Mundi Özellikler - Tek yerden birikimlerini değerlendir'
  const metaDescription =
    'TL ve USD birikimlerini değerlendir. Ücretsiz para transferi yap. Mundi hakkında daha fazla bilgi için tıkla.'
  Meta(metaTitle, metaDescription)

  return (
    <>
      <>
        <section>
          <div className="container">
            <div className="row m-auto">
              <div className="col-sm-3 d-none d-sm-block">
                <div className="product-banner-left">
                  <img
                    className="product-banner-img"
                    src={product1}
                    alt="product"
                  ></img>{' '}
                </div>
              </div>
              <div className="col-sm-6 m-auto">
                <h1 className="product-header-title mb-10 pt-3">
                  Birikimin yeni nesli <br /> Herkesin{' '}
                  <span className="product-header-title-color">Mundi</span>
                  {"'"}si
                </h1>
                <div className="product-header-message mb-60 m-sm-5">
                  Mundi, TL ve USD birikimlerini sermaye
                  <br className="d-block d-sm-none" /> piyasası ürünlerinde
                  değerlendirmeni ve
                  <br className="d-block d-sm-none" /> hesabında kalan bakiyeni
                  avantajlı
                  <br className="d-block d-sm-none" /> oranlarla nemalandırmanı
                  sağlar.
                </div>
                <div className="mb-60">
                  <DownloadButton />
                </div>
              </div>
              <div className="col-sm-3 d-none d-sm-block">
                <div className="product-banner-right">
                  <img
                    className="product-banner-img mt-5"
                    src={product2}
                    alt="product"
                  ></img>{' '}
                </div>
              </div>
              <div className="col-sm-12 d-block d-sm-none">
                <img
                  className="product-header-banner"
                  src={productmobile}
                  alt="product"
                ></img>{' '}
              </div>
            </div>
          </div>
        </section>
        <section id="NedenMundi" className="bg1">
          <Features />
        </section>
        <section id="ÜrünÖzellikleri">
          <Slider />
        </section>
        <section id="SeffafGelirModeli" className="bg2">
          <div className="container">
            <div className="row">
              <div className="row commission-margin-top-bottom col-sm-12 mx-auto pt-5">
                <h2 className="product-income-title mb-0 text-center">
                  Şeffaf Gelir Modeli
                </h2>
                <div className="mt-30 text-center">
                  <div className="col-sm-12 product-income-text mx-auto">
                    Aracı kurum partnerimiz, kullanıcılarımıza sağladığı
                    avantajlı nema oranları
                    <br className="d-none d-md-block" /> üzerinden komisyon
                    alır. Komisyon oranı TL birikimlerin için yıllık %1,50,
                    <br className="d-none d-md-block" /> USD birikimlerin için
                    ise yıllık %0,75 olarak uygulanır.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="container">
            <div className="row">
              <div className="col">
                <h2 className="faq-title mb-0">Sıkça Sorulan Sorular</h2>
                <FeatureFaq />
                <div className="row">
                  <div className="text-center">
                    <p className="mt-55 mb-120">
                      <a
                        className="invitation-button-text nounderline"
                        href="/destek#Faq"
                      >
                        Daha Fazlası
                      </a>{' '}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    </>
  )
}

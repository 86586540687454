import React, { useEffect, useLayoutEffect, useState } from 'react'

export const Banner = () => {
  const isBannerVisible =
    sessionStorage.getItem('isBannerVisible') === null
      ? true
      : sessionStorage.getItem('isBannerVisible') === 'true'

  const [showBanner, setShowBanner] = useState(isBannerVisible)

  useEffect(() => {
    sessionStorage.setItem('isBannerVisible', showBanner.toString())
  }, [showBanner])

  useLayoutEffect(() => {
    const navbar = document.querySelector('.navbar')
    const outlet = document.querySelector('#outlet')
    if (navbar && outlet) {
      if (showBanner) {
        navbar.classList.add('navbar-top')
        navbar.classList.remove('fixed-top')
        outlet.classList.add('banner-margin-top')
      } else {
        navbar.classList.remove('navbar-top')
        navbar.classList.add('fixed-top')
        outlet.classList.remove('banner-margin-top')
      }
    }
  }, [showBanner])

  return (
    <>
      {showBanner && (
        <div className="fixed-top banner">
          <div className="container-sm d-flex">
            <a
              href="//blog.getmundi.app/mundi-yatirim-turu/"
              className="banner-text"
            >
              🚀 2.5 milyon dolarlık yatırım turu ile KOBİ'leri güçlendirmeye
              hazırlanıyoruz.{' '}
              <span className="text-decoration-underline">
                Detaylar için tıkla.
              </span>
            </a>
            <button
              className="banner-close"
              onClick={() => setShowBanner(false)}
            >
              X
            </button>
          </div>
        </div>
      )}
    </>
  )
}

import React from 'react'

export const HomeFaq = () => {
  return (
    <div className="accordion mt-50 mb-60" id="accordionExample">
      <div className="faq-collapse-body">
        <span id="headingOne">
          <button
            className="accordion-button collapsed bg1"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapseOne"
            aria-expanded="false"
            aria-controls="collapseOne"
          >
            <strong className="faq-message-title">
              Mundi{"'"}ye nasıl üye olabilirim?
            </strong>
          </button>
        </span>
        <div
          id="collapseOne"
          className="collapse bg3"
          aria-labelledby="headingOne"
          data-bs-parent="#accordionExample"
        >
          <div className="faq-message-text">
            <p>
              Birkaç adımda Mundi hesabını oluşturup birikimlerini
              değerlendirmeye başlayabilirsin.
              <br />
              1.{' '}
              <a
                href="https://apps.apple.com/tr/app/mudi-app/id6443530236"
                target="_blank"
                rel="noreferrer"
              >
                App Store
              </a>{' '}
              veya{' '}
              <a
                href="https://play.google.com/store/apps/details?id=com.mudi.android"
                target="_blank"
                rel="noreferrer"
              >
                Google Play
              </a>
              ’den mobil uygulamayı indir.
              <br />
              2. Üyelik ve hesap açma işlemlerin için yönlendirmeleri takip
              ederek gerekli bilgileri tamamla.
            </p>
            <p>
              Mundi üzerinden aracı kurumda yatırım hesabı açmak istersen
              uygulamadan ilgili adımları takip edebilirsin.
            </p>
          </div>
        </div>
      </div>
      <div className="faq-collapse-body">
        <span id="headingTwo">
          <button
            className="accordion-button collapsed bg1"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapseTwo"
            aria-expanded="false"
            aria-controls="collapseTwo"
          >
            <strong className="faq-message-title">
              Mundi{"'"}yi kimler kullanabilir?
            </strong>
          </button>
        </span>
        <div
          id="collapseTwo"
          className="accordion-collapse collapse"
          aria-labelledby="headingTwo"
          data-bs-parent="#accordionExample"
        >
          <div className="faq-message-text">
            <p>
              Türkiye’de ikamet eden, 18 yaşından büyük Türkiye Cumhuriyeti
              vatandaşları, Mavi Kart sahipleri ve çok vatandaşlığa sahip Türk
              vatandaşları Mundi’yi kullanabilirler.
            </p>
          </div>
        </div>
      </div>
      <div className="faq-collapse-body">
        <span id="headingFour">
          <button
            className="accordion-button collapsed bg1"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapseFour"
            aria-expanded="false"
            aria-controls="collapseFour"
          >
            <strong className="faq-message-title">
              Mundi neden güvenlidir?
            </strong>
          </button>
        </span>
        <div
          id="collapseFour"
          className="accordion-collapse collapse"
          aria-labelledby="headingFour"
          data-bs-parent="#accordionExample"
        >
          <div className="faq-message-text">
            <p>
              Mundi’ye üye olduktan sonra açtığın tüm yatırım hesapları Sermaye
              Piyasası Kurulu (SPK) ve Mali Suçları Araştırma Kurulu (MASAK)
              denetimine tabi bir aracı kurum bünyesinde açılmaktadır.
            </p>
            <p>
              Ayrıca yatırım hesabındaki sermaye piyasası yatırımların Yatırımcı
              Tazmin Merkezi (YTM) sayesinde devlet güvencesi altındadır.
              Yatırımcı Tazmin Merkezi (YTM) güvencesiyle 2025 yılı için
              birikimin 1.645.665​​ TL’ye kadar tazmin kapsamındadır. Bu tutar
              her yıl ilan edilen yeniden değerleme katsayısı oranında
              artırılır.
            </p>
            <p>
              Mundi, senden aldığı bilgileri resmi kurumlar ve iş ortakları
              haricinde üçüncü şahıs ve kurumlarla asla paylaşmaz.
            </p>
          </div>
        </div>
      </div>
      <div className="faq-collapse-body">
        <span id="headingFive">
          <button
            className="accordion-button collapsed bg1"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapseFive"
            aria-expanded="false"
            aria-controls="collapseFive"
          >
            <strong className="faq-message-title">
              Mundi{"'"}ye nasıl ulaşabilirim?
            </strong>
          </button>
        </span>
        <div
          id="collapseFive"
          className="accordion-collapse collapse"
          aria-labelledby="headingFive"
          data-bs-parent="#accordionExample"
        >
          <div className="faq-message-text">
            <p>Mundi Destek Ekibi’ne ulaşabileceğin birden fazla kanal var.</p>
            <ul>
              <li>
                <a href="mailto:destek@getmundi.app">destek@getmundi.app</a>{' '}
                adresine e-posta gönderebilirsin.
              </li>
              <li>
                <a href="/destek">www.getmundi.app/destek</a> sayfamızdaki
                iletişim formunu doldurabilirsin.
              </li>
              <li>
                Uygulamada “Profil” ekranındaki “Yardım Merkezi” menüsünden
                destek talebini iletebilirsin.
              </li>
            </ul>
            <p>
              Her iş günü 09:00-18:00 saatleri arasında{' '}
              <a href="tel:0850 242 24 24">0850 242 24 24</a> numaralı telefonu
              arayabilirsin.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

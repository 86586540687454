import React from 'react'

export const CorporateFaq = () => {
  return (
    <div
      className="accordion mt-50-collapse-30 mb-60"
      id="accordionExampleEight"
    >
      <div className="faq-collapse-body">
        <span id="headingOne">
          <button
            className="accordion-button collapsed bg1"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapseOne"
            aria-expanded="false"
            aria-controls="collapseOne"
          >
            <strong className="faq-message-title">
              Şirketim için nasıl hesap açarım?
            </strong>
          </button>
        </span>
        <div
          id="collapseOne"
          className="accordion-collapse collapse"
          aria-labelledby="headingOne"
          data-bs-parent="#accordionExampleEight"
        >
          <div className="faq-message-text">
            <p>
              Kurumsal Başvuru Formu doldurulduktan sonra hesap açılış
              işlemlerin için kısa süre içinde iletişime geçeriz. Gerekli
              belgeler ve sözleşme sürecin tamamlandıktan sonra hesap açılışın
              gerçekleşir.
            </p>
            <p>
              Mundi’den nasıl faydalanabileceğini paylaşmak ve hesap açılış
              işlemlerini takip etmek için ekibimiz sana her adımda eşlik eder.
            </p>
          </div>
        </div>
      </div>
      <div className="faq-collapse-body">
        <span id="headingTwo">
          <button
            className="accordion-button collapsed bg1"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapseTwo"
            aria-expanded="false"
            aria-controls="collapseTwo"
          >
            <strong className="faq-message-title">Mundi nasıl çalışır?</strong>
          </button>
        </span>
        <div
          id="collapseTwo"
          className="accordion-collapse collapse"
          aria-labelledby="headingTwo"
          data-bs-parent="#accordionExampleEight"
        >
          <div className="faq-message-text">
            <p>
              Üye olduktan sonra Mundi uygulaması üzerinden Tacirler Yatırım
              Menkul Değerler A.Ş. nezdinde yatırım hesabı açarsın. Banka
              hesaplarından dilediğin tutarı yatırım hesabına aktarırsın.
            </p>
            <p>
              Mundi üzerinden gerçekleştirdiğin bütün yatırım işlemleri Tacirler
              Yatırım Menkul Değerler A.Ş. bünyesinde gerçekleşmektedir.
              Tacirler Yatırım Menkul Değerler A.Ş. Sermaye Piyasası Kurulu
              (SPK) ve Mali Suçları Araştırma Kurulu (MASAK) denetimine tabi bir
              aracı kurumdur.
            </p>
          </div>
        </div>
      </div>
      <div className="faq-collapse-body">
        <span id="headingThree">
          <button
            className="accordion-button collapsed bg1"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapseThree"
            aria-expanded="false"
            aria-controls="collapseThree"
          >
            <strong className="faq-message-title">
              Mundi neden güvenlidir?
            </strong>
          </button>
        </span>
        <div
          id="collapseThree"
          className="accordion-collapse collapse"
          aria-labelledby="headingThree"
          data-bs-parent="#accordionExampleEight"
        >
          <div className="faq-message-text">
            <p>
              Mundi’ye üye olduktan sonra açtığın tüm yatırım hesapları Sermaye
              Piyasası Kurulu (SPK) ve Mali Suçları Araştırma Kurulu (MASAK)
              denetimine tabi bir aracı kurum bünyesinde açılmaktadır.
            </p>
            <p>
              Ayrıca yatırım hesabındaki sermaye piyasası yatırımların Yatırımcı
              Tazmin Merkezi (YTM) sayesinde devlet güvencesi altındadır.
              Yatırımcı Tazmin Merkezi (YTM) güvencesiyle 2025 yılı için
              birikimin 1.645.665​​ TL’ye kadar tazmin kapsamındadır. Bu tutar
              her yıl ilan edilen yeniden değerleme katsayısı oranında
              artırılır.
            </p>
            <p>
              Mundi, senden aldığı bilgileri resmi kurumlar ve iş ortakları
              haricinde üçüncü şahıs ve kurumlarla asla paylaşmaz.
            </p>
          </div>
        </div>
      </div>
      <div className="faq-collapse-body">
        <span id="headingFour">
          <button
            className="accordion-button collapsed bg1"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapseFour"
            aria-expanded="false"
            aria-controls="collapseFour"
          >
            <strong className="faq-message-title">
              {' '}
              Şirketim adına yaptığım işlemleri nasıl takip ederim?{' '}
            </strong>
          </button>
        </span>
        <div
          id="collapseFour"
          className="accordion-collapse collapse"
          aria-labelledby="headingFour"
          data-bs-parent="#accordionExampleEight"
        >
          <div className="faq-message-text">
            <p>
              Hesap hareketlerini {'"Profil"'} menüsünde {'"Hesap Hareketleri"'}{' '}
              seçeneğine dokunarak görebilirsin. Ayrıca şirketinin muhasebe
              işlemleri için gerekli olan raporlar ve hesap ekstreleri dilediğin
              sıklıkta e-posta ile paylaşılır.
            </p>
          </div>
        </div>
      </div>
      <div className="faq-collapse-body">
        <span id="headingFive">
          <button
            className="accordion-button collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapseFive"
            aria-expanded="false"
            aria-controls="collapseFive"
          >
            <strong className="faq-message-title">
              Hesap işletim ücreti veya işlem masrafı var mı?
            </strong>
          </button>
        </span>
        <div
          id="collapseFive"
          className="accordion-collapse collapse"
          aria-labelledby="headingFive"
          data-bs-parent="#accordionExampleEight"
        >
          <div className="faq-message-text">
            <p>
              Aracı kurum partnerimiz, kullanıcılarımıza sağladığı avantajlı
              nema oranları üzerinden komisyon alır. Komisyon oranı TL
              birikimlerin için yıllık %1,50, USD birikimlerin için ise yıllık
              %0,75 olarak uygulanır. Uygulanan komisyon dışında herhangi bir
              ücret yansıtılmaz.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

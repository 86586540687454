import React from 'react'
import { Meta } from '../meta'

export const Privacy = () => {
  const metaTitle = 'Mundi - Kişisel Verilerin Korunması ve Gizlilik İlkeleri'
  const metaDescription =
    'Kişisel verilerinin korunması ve gizlilik ilkelerimiz hakkında bilgi edin.'
  Meta(metaTitle, metaDescription)

  return (
    <>
      <section className="legalinformation">
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="mt-50 mb-120">
                <p className="legal-heading text-center ">
                  MUDİ FİNANSAL TEKNOLOJİ HİZMETLERİ A.Ş
                  <br />
                  KİŞİSEL VERİLERİN KORUNMASI VE İŞLENMESİ POLİTİKASI
                </p>
                <div className="list-content">
                  <ol className="list-content">
                    <li className="legal-heading-2">
                      BÖLÜM: GİRİŞ
                      <ol type="I">
                        <li className="legal-heading-2">
                          KİŞİSEL VERİLERİN KORUNMASININ ÖNEMİ
                        </li>
                        <p className="legal-text align-justify">
                          Kişisel verilerin korunması, Anayasal bir hak olup,
                          Şirketimizin öncelikleri kapsamında yer almaktadır.
                          Nitekim bu amaçla, Şirketimizde devamlı olarak
                          güncellenen bir sistem kurulması amaçlanmış ve işbu
                          politika oluşturulmuştur. 6698 sayılı Kişisel
                          Verilerin Korunması Kanunu (“Kanun”) kapsamında, Veri
                          Sorumlusu sıfatıyla,{' '}
                          <b>Mudi Finansal Teknoloji Hizmetleri A.Ş</b>
                          {"'"}nin(“Şirket”), genel aydınlatma yükümlülüğünü
                          yerine getirmek ve Şirketimiz kişisel veri işleme
                          kurallarının temel esaslarını belirlemek üzere işbu
                          Kişisel Verilerin Korunması ve İşlenmesi Politikası
                          (“Politika”) yapılmakta ve bu kapsamda
                          müşterilerimizin, potansiyel müşterilerimizin,
                          çalışanlarımızın, çalışan adaylarımızın, stajyer ve
                          öğrencilerimizin, tedarikçi/alt işveren çalışanlarının
                          ve yetkililerinin, şirket hissedarlarımızın ve şirket
                          ortaklarımızın, ziyaretçilerimizin ve diğer verisini
                          işlediğimiz üçüncü kişilerin kişisel verilerinin
                          korunması konusundaki temel esaslar düzenlenmektedir.
                          <br />
                          <br />
                          Bu Politikada belirtilen konuların uygulanmasına
                          yönelik olarak Şirket içerisinde gerekli prosedürler
                          düzenlenmekte, kişi kategorilerine özel ve uyumlu
                          aydınlatma metinleri oluşturulmakta, kişisel verilere
                          erişimi olan Şirket çalışanları ve üçüncü taraflarla
                          kişisel verilerin korunması ve gizlilik sözleşmeleri
                          yapılmakta, görev tanımları revize edilmekte, kişisel
                          verilerin korunması için Şirket tarafından gereken
                          idari ve teknik tedbirler alınmakta, bu kapsamda
                          gerekli denetimler yapılmakta veya yaptırılmaktadır.
                          Kişisel Verilerin Korunması konusu üst yönetim
                          tarafından da sahiplenilmekte, bu konuda özel bir
                          Komite oluşturulmak (
                          <b>
                            Şirket Kişisel Verilerin Korunması Çalışma Komitesi
                          </b>
                          ) suretiyle kişisel verilerin korunması süreçleri
                          yönetilmektedir.
                        </p>
                        <li className="legal-heading-2">POLİTİKANIN AMACI</li>
                        <p className="legal-text align-justify">
                          Bu Politikanın temel amacı, Şirket tarafından hukuka
                          uygun bir biçimde yürütülen kişisel veri işleme
                          faaliyeti ve kişisel verilerin korunmasına yönelik
                          esasları ortaya koymak, bu kapsamda kişisel verileri
                          Şirketimiz tarafından işlenen kişileri aydınlatarak ve
                          bilgilendirerek şeffaflığı sağlamaktır.
                        </p>
                        <li className="legal-heading-2">KAPSAM</li>
                        <p className="legal-text align-justify">
                          Bu Politika;{' '}
                          <i>
                            “müşterilerimiz, potansiyel müşterilerimiz,
                            çalışanlarımız, çalışan adaylarımız, stajyer ve
                            öğrencilerimiz, tedarikçi/alt işveren çalışanları ve
                            yetkilileri, Şirket hissedarlarımız ve Şirket
                            ortaklarımız, ziyaretçilerimiz, ürün veya hizmet
                            alan yetkilileri, çalışan referansları, çalışan aile
                            ve yakınları, veli/vasi/temsilciler ve verisini
                            işlediğimiz diğer üçüncü kişiler”
                          </i>{' '}
                          başlıkları altında kategorize ettiğimiz kişilerin
                          otomatik olan ya da herhangi bir veri kayıt sisteminin
                          parçası olmak kaydıyla otomatik olmayan yollarla
                          işlediğimiz tüm kişisel verilerine ilişkindir.
                        </p>
                        <li className="legal-heading-2">
                          POLİTİKANIN VE İLGİLİ MEVZUATIN UYGULANMASI
                        </li>
                        <p className="legal-text align-justify">
                          Kişisel verilerin işlenmesi ve korunması konusunda
                          yürürlükte bulunan ilgili kanuni düzenlemeler
                          öncelikle uygulama alanı bulacaktır. Yürürlükte
                          bulunan mevzuat ve Politika arasında uyumsuzluk
                          bulunması durumunda, Şirket’imiz yürürlükteki
                          mevzuatın uygulama alanı bulacağını kabul etmektedir.
                        </p>
                        <li className="legal-heading-2">
                          ERİŞİM VE GÜNCELLEME
                        </li>
                        <p className="legal-text align-justify">
                          İşbu Politika, Şirket’imizin web sitesinde{' '}
                          <a href="/yasal-bilgiler">
                            www.getmundi.app/yasal-bilgiler
                          </a>{' '}
                          adresinde ve mobil aplikasyonumuzun Ayarlar – Yasal
                          Bilgiler alanında yayımlanır ve kişisel veri
                          sahiplerinin talebi üzerine ilgili kişilerin erişimine
                          sunulur ve gerektiğinde güncellenir.
                        </p>
                      </ol>
                    </li>
                    <li className="legal-heading-2">
                      BÖLÜM: KİŞİSEL VERİLERİN İŞLENMESİ
                      <p className="legal-text align-justify ">
                        Şirket’imiz, Anayasa’nın 20. maddesine ve Kanun’un 4.
                        maddesine uygun olarak, kişisel verilerin işlenmesi
                        konusunda; hukuka ve dürüstlük kurallarına uygun, doğru
                        ve gerektiğinde güncel; belirli, açık ve meşru amaçlar
                        doğrultusunda; amaçla bağlantılı, sınırlı ve ölçülü bir
                        biçimde kişisel veri işleme faaliyetinde bulunmaktadır.
                        Şirket’imiz kanunlarda öngörülen veya kişisel veri
                        işleme amacının gerektirdiği süre kadar kişisel verileri
                        saklamaktadır.
                        <br />
                        <br />
                        Şirket’imiz, Anayasa’nın 20. ve Kanun’un 5. maddeleri
                        gereğince kişisel verileri, Kanun’un 5. maddesindeki
                        şartlardan bir veya birkaçına dayalı olarak
                        işlemektedir.
                        <br />
                        <br />
                        Şirket’imiz, Borçlar Kanun’un 419. maddesi gereğince,
                        Kanun hükümleri saklı kalmak kaydıyla, çalışanların ve
                        çalışan adaylarının kişisel verilerini, işe yatkınlık ve
                        iş sözleşmesinin ifası amaçlarına dayalı olarak
                        işlemektedir.
                        <br />
                        <br />
                        Şirket’imiz, Anayasa’nın 20. ve Kanun’un 10. maddelerine
                        uygun olarak, kişisel veri sahiplerini aydınlatmakta ve
                        kişisel veri sahiplerinin bilgi talep etmeleri ve
                        kanundan doğan haklarını kullanmak üzere başvurmaları
                        durumunda gerekli bilgilendirmeyi yapmakta, başvurulara
                        yasal süresi içinde yanıt vermektedir.
                        <br />
                        <br />
                        Şirket’imiz Kanun’un 6. maddesine uygun olarak özel
                        nitelikli kişisel verilerin işlenmesi bakımından
                        öngörülen düzenlemelere uygun hareket etmektedir.
                        <br />
                        <br />
                        Şirket’imiz, Kanun’un 8. ve 9. maddelerine uygun olarak,
                        kişisel verilerin aktarılması konusunda Kanunda
                        öngörülen kurallara uymakta ve{' '}
                        <b>Kişisel Verileri Koruma Kurulu (“Kurul”)</b>{' '}
                        tarafından alınan karar ve yayımlanan tebliğler ile
                        güvenli ülke listelerini dikkate alarak uygulama
                        yapmaktadır.
                      </p>
                      <ol type="I">
                        <li className="legal-heading-2">
                          KİŞİSEL VERİLERİN MEVZUATTA ÖNGÖRÜLEN İLKE VE
                          KURALLARA UYGUN OLARAK İŞLENMESİ
                          <ol>
                            <li className="legal-heading-2">
                              Kişisel Verilerin İşlenmesi İlkeleri
                              <ol type="A">
                                <li className="legal-heading-2">
                                  Hukuka ve Dürüstlük Kuralına Uygun İşleme
                                </li>
                                <p className="legal-text align-justify">
                                  Şirket’imiz; kişisel verilerin işlenmesinde
                                  hukuksal düzenlemelerle getirilen ilkeler ile
                                  dürüstlük kuralına uygun hareket etmektedir.
                                  Bu kapsamda Şirket’imiz, kişisel verilerin
                                  işlenmesini gerektirecek hukuksal dayanakları
                                  tespit ederek işlem yapmakta, ölçülülük
                                  gerekliliklerini dikkate almakta, kişisel
                                  verileri amacın gerektirdiği dışında
                                  kullanmamakta, kişilerin bilgisi dışında
                                  işleme faaliyeti yapmamaktadır.
                                </p>
                                <li className="legal-heading-2">
                                  Kişisel Verilerin Doğru ve Gerektiğinde Güncel
                                  Olmasını Sağlama
                                </li>
                                <p className="legal-text align-justify">
                                  Şirket’imiz; kişisel veri sahiplerinin temel
                                  haklarını ve kendi meşru menfaatlerini dikkate
                                  alarak işlediği kişisel verilerin doğru ve
                                  güncel olmasını sağlamakta, bu doğrultuda
                                  gerekli tedbirleri almaktadır. Bu kapsamda tüm
                                  kişi kategorilerine ilişkin veriler güncel
                                  tutulmaya çalışılmaktadır. Özellikle müşteri
                                  ve potansiyel müşteri verileri özenle
                                  güncellenmekte, kişilere rızalarına aykırı
                                  biçimde pazarlama ve tanıtım amaçlı e-posta ve
                                  teklifler gönderilmemektedir.
                                </p>
                                <li className="legal-heading-2">
                                  Belirli, Açık ve Meşru Amaçlarla İşleme
                                </li>
                                <p className="legal-text align-justify">
                                  Şirket’imiz, meşru ve hukuka uygun olan
                                  kişisel veri işleme amacını açık ve kesin
                                  olarak belirlemektedir. Şirket’imiz, kişisel
                                  verileri sunmakta olduğu hizmetle bağlantılı
                                  ve bunlar için gerekli olan kadar
                                  işlemektedir.
                                </p>
                                <li className="legal-heading-2">
                                  İşlendikleri Amaçla Bağlantılı, Sınırlı ve
                                  Ölçülü Olma
                                </li>
                                <p className="legal-text align-justify">
                                  Şirket’imiz, kişisel verileri belirlenen
                                  amaçların gerçekleştirilebilmesine elverişli
                                  bir biçimde işlemekte ve amacın
                                  gerçekleştirilmesiyle ilgili olmayan veya
                                  ihtiyaç duyulmayan kişisel verilerin
                                  işlenmesinden kaçınmaktadır. Bu kapsamda
                                  süreçler sürekli gözden geçirilmekte,{' '}
                                  <b>
                                    “Data Minimisation/Kişisel Verilerin
                                    Azaltılması”
                                  </b>{' '}
                                  ilkesi hayata geçirilmeye çalışılmaktadır.
                                </p>
                                <li className="legal-heading-2">
                                  İlgili Mevzuatta Öngörülen veya İşlendikleri
                                  Amaç için Gerekli Olan Süre Kadar Muhafaza
                                  Etme
                                </li>
                                <p className="legal-text align-justify">
                                  Şirket’imiz, kişisel verileri ancak ilgili
                                  mevzuatta belirtildiği veya işlendikleri amaç
                                  için gerekli olan süre kadar muhafaza
                                  etmektedir. Bu kapsamda, Şirket’imiz öncelikle
                                  ilgili mevzuatta kişisel verilerin saklanması
                                  için bir süre öngörülüp öngörülmediğini tespit
                                  etmekte, bir süre belirlenmişse bu süreye
                                  uygun davranmakta, bu kapsamda hukuk ve ceza
                                  zamanaşımı sürelerini dikkate almakta ve
                                  kişisel verileri işlendikleri amaç için
                                  gerekli olan süre kadar saklamaktadır. Sürenin
                                  bitimi veya işlenmesini gerektiren sebeplerin
                                  ortadan kalkması halinde kişisel veriler
                                  Şirketimizin{' '}
                                  <b>
                                    “Kişisel Verilerin Saklanması ve İmhası”
                                  </b>{' '}
                                  politikasına göre silinmekte, yok edilmekte
                                  veya anonim hale getirilmektedir.
                                </p>
                              </ol>
                            </li>
                            <li className="legal-heading-2">
                              Genel Nitelikteki Kişisel Verilerin İşlenmesi
                              Kuralları
                            </li>
                            <p className="legal-text align-justify mb-0">
                              Kişisel verilerin korunması Anayasal bir hak olup,
                              temel hak ve hürriyetler, özlerine dokunulmaksızın
                              yalnızca Anayasa’nın ilgili maddelerinde
                              belirtilen sebeplere bağlı olarak ve ancak kanunla
                              sınırlanabilir. Anayasa{"'"}nın 20. maddesinin
                              üçüncü fıkrası gereğince, kişisel veriler ancak
                              kanunda öngörülen hallerde veya kişinin açık
                              rızasıyla işlenebilecektir. Şirket’imizce, kişisel
                              verilerin işlenmesinde ancak aşağıdaki şartlar
                              varsa ilgili kişinin açık rızası aranmaksızın
                              kişisel verileri işlenmektedir;
                            </p>
                            <ol type="a" className="list-text legal-text">
                              <li>Kanunlarda açıkça öngörülmesi,</li>
                              <li>
                                Fiili imkânsızlık nedeniyle rızasını
                                açıklayamayacak durumda bulunan veya rızasına
                                hukuki geçerlilik tanınmayan kişinin kendisinin
                                ya da bir başkasının hayatı veya beden
                                bütünlüğünün korunması için zorunlu olması,
                              </li>
                              <li>
                                Bir sözleşmenin kurulması veya ifasıyla doğrudan
                                doğruya ilgili olması kaydıyla, sözleşmenin
                                taraflarına ait kişisel verilerin işlenmesinin
                                gerekli olması,
                              </li>
                              <li>
                                Veri sorumlusunun hukuki yükümlülüğünü yerine
                                getirebilmesi için zorunlu olması,
                              </li>
                              <li>
                                İlgili kişinin kendisi tarafından
                                alenileştirilmiş olması,
                              </li>
                              <li>
                                Bir hakkın tesisi, kullanılması veya korunması
                                için veri işlemenin zorunlu olması,
                              </li>
                              <li>
                                İlgili kişinin temel hak ve özgürlüklerine zarar
                                vermemek kaydıyla, veri sorumlusunun meşru
                                menfaatleri için veri işlenmesinin zorunlu
                                olması
                              </li>
                            </ol>
                            <p className="legal-text align-justify">
                              Yukarıdaki şartların bulunmaması halinde
                              Şirket’imizce ilgilinin açık, özgür iradeye ve
                              bilgilendirmeye dayalı rızasına başvurulmaktadır.
                              Özellikle İnsan Kaynakları ve Çalışma İlişkileri
                              alanında, çalışanın bağımlılık ilişkisi dikkate
                              alınarak verinin öncelikle rıza dışında kalan
                              hukuka uygunluk sebeplerine dayanılması esas
                              tutulmakta, ancak bu sebeplerin söz konusu
                              olmaması durumunda açık rızaya başvurulmaktadır.
                              Buna karşılık pazarlama gibi faaliyetlerde ilgili
                              kişinin açık rızası esas alınarak işleme faaliyeti
                              gerçekleştirilmektedir. Ancak her halde kişisel
                              verilerin işlendiği tüm durumlarda kişiler mutlaka{' '}
                              <b>“aydınlatılarak”</b> veri işleme faaliyeti
                              gerçekleştirilmektedir.
                            </p>
                            <li className="legal-heading-2">
                              Özel Nitelikli Kişisel Verilerin İşlenmesi
                              Kuralları
                            </li>
                            <p className="legal-text align-justify mb-0">
                              Şirket’imiz tarafından, Kanun’da “özel nitelikli”
                              olarak belirlenen kişisel verilerin işlenmesinde,
                              Kanun’da öngörülen düzenlemelere uygun
                              davranılmaktadır. Kanun’un 6. maddesinde, hukuka
                              aykırı olarak işlendiğinde kişilerin mağduriyetine
                              veya ayrımcılığa sebep olma riski taşıyan birtakım
                              kişisel veri “özel nitelikli” olarak belirlenmiş
                              olup bu verilerin işlenmesinde dikkat ve
                              hassasiyet gösterilmesi gerekmektedir. Bunlar;
                              ırk, etnik köken, siyasi düşünce, felsefi inanç,
                              din, mezhep veya diğer inançlar, kılık ve kıyafet,
                              dernek, vakıf ya da sendika üyeliği, sağlık,
                              cinsel hayat, ceza mahkûmiyeti ve güvenlik
                              tedbirleriyle ilgili veriler ile biyometrik ve
                              genetik verilerdir. Kanun’a uygun bir biçimde
                              Şirket’imiz tarafından; özel nitelikli kişisel
                              veriler, gerekli önlemlerin alınması kaydıyla
                              aşağıdaki durumlarda işlenmektedir:
                            </p>
                            <ul className="list-text list-style-tick legal-text">
                              <li>
                                İlgili kişinin sağlığı ve cinsel hayatı
                                dışındaki özel nitelikli kişisel veriler,
                                kanunlarda öngörülen hallerde veya ilgili
                                kişinin açık rızası var ise buna dayalı olarak,
                              </li>
                              <li>
                                İlgili kişinin sağlığına ve cinsel hayatına
                                ilişkin özel nitelikli kişisel veriler ise ancak
                                kamu sağlığının korunması, koruyucu hekimlik,
                                tıbbi teşhis, tedavi ve bakım hizmetlerinin
                                yürütülmesi, sağlık hizmetleri ile finansmanının
                                planlanması ve yönetimi amacıyla, sır saklama
                                yükümlülüğü altında bulunan kişiler veya yetkili
                                kurum ve kuruluşlar tarafından veya ilgili
                                kişinin açık rızası ile işlenmektedir.
                              </li>
                              <li>
                                Hangi nedene dayanırsa dayansın, işleme
                                süreçlerinde daima genel veri işleme ilkeleri
                                dikkate alınmakta ve bu ilkelere uygunluk
                                sağlanmaktadır (Kanun m. 4; bkz. yukarıda 2.
                                Bölüm, I, 1).
                              </li>
                            </ul>
                            <p className="legal-text align-justify">
                              Özel nitelikli verilerin korunması ile ilgili
                              olarak şirketimizde{' '}
                              <b>
                                “Özel Nitelikli Kişisel Verilerin Korunması
                                Prosedürü”
                              </b>{' '}
                              yürürlüğe konulmuş olup, iş birimlerimizde bu
                              prosedür hükümlerine göre hareket edilmekte ve
                              gereken tedbirler alınmaktadır.
                            </p>
                            <li className="legal-heading-2">
                              Verisi İşlenen İlgili Kişilerin Aydınlatılması ve
                              Bilgilendirilmesi
                            </li>
                            <p className="legal-text align-justify">
                              Şirketimiz, Kanun’un 10. maddesine uygun olarak,
                              kişisel verilerin elde edilmesi sırasında kişisel
                              veri sahiplerini aydınlatmaktadır. Bu kapsamda
                              verisi işlenen ilgili kişiye kişisel verilerin
                              hangi amaçla işleneceği, işlenen kişisel verilerin
                              kimlere ve hangi amaçla aktarılabileceği, kişisel
                              veri toplamanın yöntemi ve hukuki sebebi ile
                              kişisel verisi işlenen ilgili kişinin hakları
                              konusunda aydınlatma yapılmakta, Şirketimizin
                              ilgili birimleri Şirketimizin{' '}
                              <b>“Aydınlatma Esasları Prosedürü”</b>ne göre
                              gereken işlemleri yerine getirmektedir. Yine,
                              Kanun’un 11. maddesinde kişisel verisi işlenen
                              ilgili kişinin hakları arasında “Bilgi Talep Etme”
                              hakkı da sayılmış olup, Şirketimiz bu kapsamda,
                              Anayasa’nın 20. ve Kanun’un 11. maddelerine uygun
                              olarak kişisel verisi işlenen ilgili kişinin bilgi
                              talep etmesi durumunda gerekli bilgilendirmeler
                              yapılmakta, bu konuda Şirketimizde{' '}
                              <b>“İlgili Kişi Başvuru Prosedürü”</b> ne göre
                              işlem yapılmaktadır.
                            </p>
                          </ol>
                        </li>
                        <li className="legal-heading-2">
                          KİŞİSEL VERİLERİN AKTARILMASI
                          <p className="legal-text align-justify ">
                            Şirketimiz hukuka uygun olan kişisel veri işleme
                            amaçları doğrultusunda gerekli güvenlik önlemlerini
                            alarak kişisel verisi işlenen ilgili kişinin kişisel
                            verilerini ve özel nitelikli kişisel verilerini
                            üçüncü kişilere aktarabilmektedir. Şirketimiz bu
                            doğrultuda Kanun’un 8. maddesinde öngörülen
                            düzenlemelere uygun hareket etmektedir.
                          </p>
                          <ol>
                            <li className="legal-heading-2">
                              Kişisel Verilerin Aktarılma Esasları
                            </li>
                            <p className="legal-text align-justify mb-0">
                              Şirket’imiz meşru ve hukuka uygun kişisel veri
                              işleme amaçları doğrultusunda Kanun’un 5.
                              maddesinde belirtilen ve aşağıda sayılmakta olan
                              kişisel veri işleme şartlarından bir veya
                              birkaçına dayalı ve sınırlı olarak kişisel
                              verileri üçüncü kişilere aktarabilmektedir.
                              <br />
                              <br />
                              Kişisel verisi işlenen ilgili kişinin açık rızası
                              var ise buna dayalı olarak; veya
                            </p>
                            <ul className="list-text list-style-tick legal-text mb-32">
                              <li>
                                Kanunlarda kişisel verinin aktarılacağına
                                ilişkin açık bir düzenleme var ise,
                              </li>
                              <li>
                                İlgili kişinin veya başkasının hayatı veya beden
                                bütünlüğünün korunması için zorunlu ise ve
                                ilgili kişi fiili imkânsızlık nedeniyle rızasını
                                açıklayamayacak durumda ise veya rızasına hukuki
                                geçerlilik tanınmıyorsa;
                              </li>
                              <li>
                                Bir sözleşmenin kurulması veya ifasıyla doğrudan
                                doğruya ilgili olmak kaydıyla sözleşmenin
                                taraflarına ait kişisel verinin aktarılması
                                gerekli ise,
                              </li>
                              <li>
                                Şirket’imizin hukuki yükümlülüğünü yerine
                                getirmesi için kişisel veri aktarımı zorunlu
                                ise,
                              </li>
                              <li>
                                Kişisel veriler, ilgili kişinin kendisi
                                tarafından alenileştirilmiş ise,
                              </li>
                              <li>
                                Kişisel veri aktarımı bir hakkın tesisi,
                                kullanılması veya korunması için zorunlu ise,
                              </li>
                              <li>
                                Kişisel verisi işlenen ilgili kişinin temel hak
                                ve özgürlüklerine zarar vermemek kaydıyla,
                                Şirketimizin meşru menfaatleri için kişisel veri
                                aktarımı zorunlu ise aktarılmaktadır.
                              </li>
                            </ul>
                            <p className="legal-text align-justify">
                              Hangi nedene dayanırsa dayansın, aktarım
                              süreçlerinde daima genel veri işleme ilkeleri
                              dikkate alınmakta ve bu ilkelere uygunluk
                              sağlanmaktadır (Kanun m. 4; bkz. yukarıda 2.
                              Bölüm, I, 1).
                            </p>
                            <li className="legal-heading-2">
                              Özel Nitelikli Kişisel Verilerin Aktarılması
                            </li>
                            <p className="legal-text align-justify mb-0">
                              Şirket’imiz gerekli özeni göstererek, gerekli
                              güvenlik tedbirlerini alarak ve Kurul tarafından
                              öngörülen yeterli önlemleri alarak; meşru ve
                              hukuka uygun kişisel veri işleme amaçları
                              doğrultusunda kişisel verisi işlenen ilgili
                              kişinin özel nitelikli verilerini aşağıdaki
                              durumlarda üçüncü kişilere aktarabilmektedir.
                            </p>
                            <ul className="list-text list-style-circle legal-text">
                              <li>
                                İlgili kişinin açık rızası var ise buna dayalı
                                olarak veya
                              </li>
                              <li>
                                İlgili kişinin açık rızası yok ise;
                                <ul className="list-text list-style-tick">
                                  <li>
                                    İlgili kişinin sağlığı ve cinsel hayatı
                                    dışındaki özel nitelikli kişisel verileri
                                    (ırk, etnik köken, siyasi düşünce, felsefi
                                    inanç, din, mezhep veya diğer inançlar,
                                    kılık ve kıyafet, dernek, vakıf ya da
                                    sendika üyeliği, ceza mahkûmiyeti ve
                                    güvenlik tedbirleriyle ilgili veriler ile
                                    biyometrik ve genetik verilerdir),
                                    kanunlarda öngörülen hallerde,
                                  </li>
                                  <li>
                                    İlgili kişinin sağlığına ve cinsel hayatına
                                    ilişkin özel nitelikli kişisel verileri ise
                                    ancak kamu sağlığının korunması, koruyucu
                                    hekimlik, tıbbi teşhis, tedavi ve bakım
                                    hizmetlerinin yürütülmesi, sağlık hizmetleri
                                    ile finansmanının planlanması ve yönetimi
                                    amacıyla, sır saklama yükümlülüğü altında
                                    bulunan kişiler veya yetkili kurum ve
                                    kuruluşlar tarafından işlenebilir.
                                  </li>
                                </ul>
                              </li>
                            </ul>
                            <p className="legal-text align-justify">
                              Özel nitelikli kişisel verilerin aktarılması ile
                              ilgili olarak şirketimizde{' '}
                              <b>
                                “Özel Nitelikli Kişisel Verilerin Korunması
                                Prosedürü”
                              </b>{' '}
                              yürürlüğe konulmuş olup, iş birimlerimizde işbu
                              Politika ve bahsedilen prosedür hükümlerine göre
                              hareket edilmekte ve gereken tedbirler
                              alınmaktadır. Hangi nedene dayanırsa dayansın,
                              aktarım süreçlerinde daima genel veri işleme
                              ilkeleri dikkate alınmakta ve bu ilkelere uygunluk
                              sağlanmaktadır (Kanun m. 4; bkz. yukarıda 2.
                              Bölüm, I, 1).
                            </p>
                            <li className="legal-heading-2">
                              Kişisel Verilerin Yurtdışına Aktarılması
                            </li>
                            <p className="legal-text align-justify mb-0">
                              Şirketimiz hukuka uygun kişisel veri işleme
                              amaçları doğrultusunda gerekli güvenlik önlemleri
                              alarak işlediği kişisel verileri ve özel nitelikli
                              kişisel verileri yurt dışındaki üçüncü kişilere
                              aktarabilmektedir. Şirketimiz tarafından kişisel
                              veriler; Kurul tarafından yeterli korumaya sahip
                              olduğu ilan edilecek yabancı ülkelere (“Yeterli
                              Korumaya Sahip Yabancı Ülke”) veya yeterli
                              korumanın bulunmaması durumunda Türkiye’deki ve
                              ilgili yabancı ülkedeki veri sorumlularının
                              yeterli bir korumayı yazılı olarak taahhüt ettiği
                              ve Kurul’un izninin bulunduğu yabancı ülkelere
                              (“Yeterli Korumayı Taahhüt Eden Veri Sorumlusunun
                              Bulunduğu Yabancı Ülke”) aktarılabilecektir.
                              Şirketimiz bu doğrultuda Kanun’un 9. maddesinde
                              öngörülen düzenlemelere uygun hareket etmektedir.
                              <br />
                              Şirketimiz meşru ve hukuka uygun kişisel veri
                              işleme amaçları doğrultusunda kişisel verisi
                              işlenen ilgili kişinin açık rızası var ise veya
                              kişisel verisi işlenen ilgili kişinin açık rızası
                              yok ise aşağıdaki hallerden birinin varlığı
                              durumunda kişisel verileri Yeterli Korumaya Sahip
                              veya Yeterli Korumayı Taahhüt Eden Veri
                              Sorumlusunun Bulunduğu Yabancı Ülkelere
                              aktarabilecektir:
                            </p>
                            <ul className="list-text list-style-tick legal-text">
                              <li>
                                Kanunlarda kişisel verinin aktarılacağına
                                ilişkin açık bir düzenleme var ise,
                              </li>
                              <li>
                                Kişisel verisi işlenen ilgili kişinin veya
                                başkasının hayatı veya beden bütünlüğünün
                                korunması için zorunlu ise ve kişisel verisi
                                işlenen ilgili kişi fiili imkânsızlık nedeniyle
                                rızasını açıklayamayacak durumda ise veya
                                rızasına hukuki geçerlilik tanınmıyorsa;
                              </li>
                              <li>
                                Bir sözleşmenin kurulması veya ifasıyla doğrudan
                                doğruya ilgili olmak kaydıyla sözleşmenin
                                taraflarına ait kişisel verinin aktarılması
                                gerekli ise,
                              </li>
                              <li>
                                Şirketimizin hukuki yükümlülüğünü yerine
                                getirmesi için kişisel veri aktarımı zorunlu
                                ise,
                              </li>
                              <li>
                                Kişisel veriler, ilgili kişinin kendisi
                                tarafından alenileştirilmiş ise,
                              </li>
                              <li>
                                Kişisel veri aktarımı bir hakkın tesisi,
                                kullanılması veya korunması için zorunlu ise,
                              </li>
                              <li>
                                Kişisel İlgili Kişinin temel hak ve
                                özgürlüklerine zarar vermemek kaydıyla,
                                Şirketimizin meşru menfaatleri için kişisel veri
                                aktarımı zorunlu ise.
                              </li>
                            </ul>
                            <li className="legal-heading-2">
                              Şirketimiz Tarafından Kişisel Verilerin Aktarılma
                              Amaçları ve Aktarma Yapılan Kişi Kategorileri
                              <ol type="A">
                                <li className="legal-heading-2">
                                  Veri Aktarım Amaçları
                                </li>
                                <p className="legal-text align-justify">
                                  Şirket’imizin faaliyet ve kuruluş amaçlarının
                                  yerine getirilmesini sağlamak, Şirket’imizin
                                  tedarikçiden dış kaynaklı olarak temin ettiği
                                  ve Şirket’imizin ticari faaliyetlerini yerine
                                  getirmek için gerekli hizmetlerin Şirket’imize
                                  sunulmasını sağlamak, Şirket’imizin insan
                                  kaynakları ve istihdam politikalarının
                                  yürütülmesini sağlamak, Şirket’imizin iş
                                  sağlığı ve güvenliği çerçevesinde
                                  yükümlülüklerinin yerine getirilmesi ve
                                  gerekli tedbirlerin alınması gibi amaçlarla
                                  veri aktarımı gerçekleştirilmektedir.
                                </p>
                                <li className="legal-heading-2">
                                  Verilerin Aktarıldığı Kişiler
                                </li>
                                <p className="legal-text align-justify">
                                  Şirketimiz Kanun’un 8. ve 9. maddelerine uygun
                                  olarak kişisel verileri aşağıda belirtilen
                                  kişi kategorilerine aktarılabilir:
                                </p>
                                <table className="table-1">
                                  <tbody>
                                    <tr>
                                      <td>YETKİLİ KAMU KURULUŞLARI</td>
                                      <td>
                                        Şirketimizden bilgi ve belge almaya
                                        yetkili kamu kurum ve kuruluşları.
                                      </td>
                                      <td>
                                        İlgili mevzuat hükümlerine göre veri
                                        paylaşımı yapılmaktadır.
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>YETKİLİ ÖZEL HUKUK KİŞİLERİ</td>
                                      <td>
                                        Şirketimizden bilgi ve belge almaya
                                        yetkili özel hukuk kişileri.
                                      </td>
                                      <td>
                                        İlgili özel hukuk kişilerinin hukuki
                                        yetkisi dahilinde talep ettiği amaçla
                                        sınırlı olarak veri paylaşımı
                                        yapılmaktadır.
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>HİSSEDAR</td>
                                      <td>Şirketimizin hissedarları</td>
                                      <td>
                                        Şirketimizin ticari faaliyetlerine
                                        ilişkin stratejilerin tasarlanması ve
                                        denetim amaçlarıyla sınırlı olarak veri
                                        paylaşımı yapılmaktadır.
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>İŞ ORTAKLARI</td>
                                      <td>
                                        Şirketimizin ticari faaliyetlerini
                                        yürütürken şirketimizin ürün ve
                                        hizmetlerinin satışı, tanıtımı ve
                                        pazarlanması, satış sonrası desteği,
                                        ortak müşteri bağlılığı programlarının
                                        yürütülmesi gibi amaçlarla iş ortaklığı
                                        kurduğu taraflar.
                                      </td>
                                      <td>
                                        İş ortaklığının kurulma amaçlarının
                                        yerine getirilmesini temin etmek
                                        amacıyla sınırlı olarak veri paylaşımı
                                        yapılmaktadır.
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>TEDARİKÇİ</td>
                                      <td>
                                        Şirketimizin ticari faaliyetlerini
                                        yürütürken Şirketimize hizmet sunan
                                        taraflar.
                                      </td>
                                      <td>
                                        Şirketimizin tedarikçiden dış kaynaklı
                                        olarak temin ettiği ve Şirketimizin
                                        ticari faaliyetlerini yerine getirmek
                                        için gerekli hizmetlerin Şirketimize
                                        sunulmasını sağlamak amacıyla sınırlı
                                        olarak veri paylaşımı yapılmaktadır.
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                                <br />
                              </ol>
                            </li>
                          </ol>
                        </li>
                        <li className="legal-heading-2">
                          KİŞİSEL VERİ KATEGORİZASYONLARI
                        </li>
                        <p className="legal-text align-justify">
                          Şirketimizde verisi işlenen kişiler ve bu kapsamda
                          işlenen veriler aşağıdaki şekilde kategorize
                          edilmektedir;
                        </p>
                        <p className="legal-heading-2 underline">
                          KİŞİ KATEGORİZASYONU
                        </p>
                        <table className="table-1">
                          <tbody>
                            <tr>
                              <td>ÇALIŞAN ADAYI</td>
                              <td>
                                Şirketimize herhangi bir yolla iş başvurusunda
                                bulunmuş ya da özgeçmiş ve ilgili bilgilerini
                                şirketimizin incelemesine açmış olan gerçek
                                kişiler
                              </td>
                            </tr>
                            <tr>
                              <td>ÇALIŞAN</td>
                              <td>Şirketimizde çalışan gerçek kişiler</td>
                            </tr>
                            <tr>
                              <td>HİSSEDAR/ORTAK</td>
                              <td>
                                Şirketimizin hissedarı ve ortağı gerçek kişiler.
                              </td>
                            </tr>
                            <tr>
                              <td>POTANSİYEL MÜŞTERİ/MÜŞTERİ</td>
                              <td>
                                Ürün ve hizmetlerimize kullanma talebinde veya
                                ilgisinde bulunmuş veya bu ilgiye sahip
                                olabileceği ticari teamül ve dürüstlük
                                kurallarına uygun olarak değerlendirilmiş gerçek
                                kişiler.
                              </td>
                            </tr>
                            <tr>
                              <td>STAJYER/ÖĞRENCİ</td>
                              <td>
                                Şirketimizde staj yapan kişiler, ÇMEK’na tabi
                                olarak staj/eğitim gören öğrenciler.
                              </td>
                            </tr>
                            <tr>
                              <td>TEDARİKÇİ ÇALIŞANI</td>
                              <td>
                                Şirketimizin her türlü iş ilişkisi içerisinde
                                bulunduğu kurumlarda (iş ortağı, tedarikçi gibi,
                                ancak bunlarla sınırlı olmaksızın) çalışan
                                gerçek kişiler.
                              </td>
                            </tr>
                            <tr>
                              <td>TEDARİKÇİ YETKİLİSİ</td>
                              <td>
                                Şirketimizin iş ilişkisi içerisinde bulunduğu
                                kurumların hissedarları ve yetkilileri gerçek
                                kişiler
                              </td>
                            </tr>
                            <tr>
                              <td>
                                ÜRÜN HİZMET ALAN/ ÜRÜN VE HİZMET ALAN YETKİLİSİ
                              </td>
                              <td>
                                Şirketimizle herhangi bir sözleşmesel ilişkisi
                                olup olmadığına bakılmaksızın Şirketimizin
                                sunmuş olduğu ürün ve hizmetleri kullanan veya
                                kullanmış olan gerçek kişiler (Ürün veya hizmet
                                alan), veya bunları kullanan/kullanmış olan
                                Tüzel Kişi Yetkilileri.
                              </td>
                            </tr>
                            <tr>
                              <td>ZİYARETÇİ</td>
                              <td>
                                Şirketimizin sahip olduğu fiziksel yerleşkelere
                                çeşitli amaçlarla girmiş olan veya internet
                                sitemiz ya da mobil aplikasyonumuzu üye olmadan
                                ziyaret eden gerçek kişiler.
                              </td>
                            </tr>
                            <tr>
                              <td>DİĞER</td>
                              <td>
                                Şirketimizin İnsan Kaynakları ve hizmet
                                süreçlerinin yürütülmesinde verilerini işlediği
                                kişi kategorileri ve veri işleme süreçleri ile
                                ilgili olan üçüncü taraf gerçek kişiler (Örn.
                                Aile Bireyleri ve Yakınlar, Çalışan
                                Referansları)
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <br />
                        <p className="legal-heading-2 underline">
                          VERİ KATEGORİZASYONU
                        </p>
                        <table className="table-1">
                          <tbody>
                            <tr>
                              <td>KİMLİK BİLGİSİ</td>
                              <td>
                                Kimliği belirli veya belirlenebilir bir gerçek
                                kişiye ait olduğu açık olan; kısmen veya tamamen
                                otomatik şekilde veya veri kayıt sisteminin bir
                                parçası olarak otomatik olmayan şekilde işlenen;
                                Ehliyet, Nüfus Cüzdanı, İkametgâh, Pasaport,
                                Avukatlık Kimliği, Evlilik Cüzdanı gibi
                                dokümanlarda yer alan bilgiler.
                              </td>
                            </tr>
                            <tr>
                              <td>İLETİŞİM BİLGİSİ</td>
                              <td>
                                Kimliği belirli veya belirlenebilir bir gerçek
                                kişiye ait olduğu açık olan; kısmen veya tamamen
                                otomatik şekilde veya veri kayıt sisteminin bir
                                parçası olarak otomatik olmayan şekilde işlenen;
                                telefon numarası, adres, e-posta adresi gibi
                                bilgiler.
                              </td>
                            </tr>
                            <tr>
                              <td>ÖZLÜK BİLGİSİ</td>
                              <td>
                                Kimliği belirli veya belirlenebilir bir gerçek
                                kişiye ait olduğu açık olan, kısmen veya tamamen
                                otomatik şekilde veya veri kayıt sisteminin bir
                                parçası olarak otomatik olmayan şekilde işlenen;
                                çalışanlarımızın veya Şirketimizle çalışma
                                ilişkisi içerisinde olan gerçek kişilerin özlük
                                haklarının oluşmasına temel olacak bilgilerin
                                elde edilmesine yönelik işlenen her türlü
                                kişisel veri.
                              </td>
                            </tr>
                            <tr>
                              <td>HUKUKİ İŞLEM VE UYUM BİLGİSİ</td>
                              <td>
                                Kimliği belirli veya belirlenebilir bir gerçek
                                kişiye ait olduğu açık olan, kısmen veya tamamen
                                otomatik şekilde veya veri kayıt sisteminin bir
                                parçası olarak otomatik olmayan şekilde işlenen;
                                hukuki alacak ve haklarımızın tespiti, takibi ve
                                borçlarımızın ifası ile kanuni yükümlülüklerimiz
                                ve şirketimizin politikalarına uyum kapsamında
                                işlenen kişisel verileriniz.
                              </td>
                            </tr>
                            <tr>
                              <td>MÜŞTERİ İŞLEM BİLGİSİ</td>
                              <td>
                                Kimliği belirli veya belirlenebilir bir gerçek
                                kişiye ait olduğu açık olan ve veri kayıt
                                sistemi içerisinde yer alan; ürün ve
                                hizmetlerimizin kullanımına yönelik kayıtlar ile
                                müşterinin ürün ve hizmetleri kullanımı için
                                gerekli olan talimatları ve talepleri gibi
                                bilgiler.
                              </td>
                            </tr>
                            <tr>
                              <td>FİZİKSEL MEKAN GÜVENLİK BİLGİSİ</td>
                              <td>
                                Kimliği belirli veya belirlenebilir bir gerçek
                                kişiye ait olduğu açık olan ve veri kayıt
                                sistemi içerisinde yer alan; fiziksel mekâna
                                girişte, fiziksel mekanıniçerisinde kalış
                                sırasında alınan kayıtlar ve belgelere ilişkin
                                kişisel veriler.
                              </td>
                            </tr>
                            <tr>
                              <td>İŞLEM GÜVENLİĞİ BİLGİSİ</td>
                              <td>
                                Kimliği belirli veya belirlenebilir bir gerçek
                                kişiye ait olduğu açık olan ve veri kayıt
                                sistemi içerisinde yer alan; faaliyetler
                                yürütülürken teknik, idari, hukuki ve ticari
                                güvenliğin sağlaması için işlenen kişisel
                                veriler.
                              </td>
                            </tr>
                            <tr>
                              <td>RİSK YÖNETİMİ BİLGİSİ</td>
                              <td>
                                Kimliği belirli veya belirlenebilir bir gerçek
                                kişiye ait olduğu açık olan ve veri kayıt
                                sistemi içerisinde yer alan; ticari, teknik ve
                                idari risklerimizi yönetebilmemiz için bu
                                alanlarda genel kabul görmüş hukuki, ticari
                                teamül ve dürüstlük kuralına uygun olarak
                                kullanılan yöntemler vasıtasıyla işlenen kişisel
                                veriler.
                              </td>
                            </tr>
                            <tr>
                              <td>FİNANSAL BİLGİ</td>
                              <td>
                                Kimliği belirli veya belirlenebilir bir gerçek
                                kişiye ait olduğu açık olan, kısmen veya tamamen
                                otomatik şekilde veya veri kayıt sisteminin bir
                                parçası olarak otomatik olmayan şekilde işlenen;
                                şirketimizin ilgili kişi ile kurmuş olduğu
                                hukuki ilişkinin tipine göre yaratılan her türlü
                                finansal sonucu gösteren bilgi, belge ve
                                kayıtlara ilişkin işlenen kişisel veriler.
                              </td>
                            </tr>
                            <tr>
                              <td>
                                PERFORMANS VE KARİYER GELİŞİM BİLGİSİ (MESLEKİ
                                DENEYİM BİLGİSİ)
                              </td>
                              <td>
                                Kimliği belirli veya belirlenebilir bir gerçek
                                kişiye ait olduğu açık olan, kısmen veya tamamen
                                otomatik şekilde veya veri kayıt sisteminin bir
                                parçası olarak otomatik olmayan şekilde işlenen;
                                çalışanlarımızın veya Şirketimizle çalışma
                                ilişkisi içerisinde olan gerçek kişilerin
                                performanslarının ölçülmesi ile kariyer
                                gelişimlerinin şirketimizin insan kaynakları
                                politikası kapsamında planlanması ve yürütülmesi
                                amacıyla işlenen kişisel veriler
                              </td>
                            </tr>
                            <tr>
                              <td>İŞLEM GÜVENLİĞİ</td>
                              <td>
                                Şirketimiz İnternet yoluyla işlenen suçlarla
                                mücadele kapsamında; uyması gereken 5651 sayılı
                                İnternet Ortamında Yapılan Yayınların
                                Düzenlenmesi ve Bu Yayınlar Yoluyla İşlenen
                                Suçlarla Mücadele Edilmesi Hakkında Kanun ve
                                kanunun ikincil düzenlemelerinden doğan
                                yükümlülüklerimizi yerine getirmek amacıyla
                                işlenen kişisel veriler
                              </td>
                            </tr>
                            <tr>
                              <td>PAZARLAMA BİLGİSİ</td>
                              <td>
                                Kimliği belirli veya belirlenebilir bir gerçek
                                kişiye ait olduğu açık olan, kısmen veya tamamen
                                otomatik şekilde veya veri kayıt sisteminin bir
                                parçası olarak otomatik olmayan şekilde işlenen;
                                ürün ve hizmetlerimizin ilgili kişinin kullanım
                                alışkanlıkları, beğenisi ve ihtiyaçları
                                doğrultusunda özelleştirilerek (yeni ürün/hizmet
                                tanıtımı, promosyon vb.) pazarlamasının
                                yapılmasına yönelik işlenen kişisel veriler ve
                                bu işleme sonuçları neticesinde yaratılan rapor
                                ve değerlendirmeler
                              </td>
                            </tr>
                            <tr>
                              <td>GÖRSEL/İŞİTSEL BİLGİ</td>
                              <td>
                                Kimliği belirli veya belirlenebilir bir gerçek
                                kişiye ait olduğu açık olan; kısmen veya tamamen
                                otomatik bir şekilde veya veri kayıt sisteminin
                                bir parçası olarak otomatik olmayan şekilde
                                işlenen kişisel veridir; Örn: fotoğraf ve kamera
                                kayıtları (Fiziksel MekanGüvenlik Bilgisi
                                kapsamında giren kayıtlar hariç), ses kayıtları
                                ile kişisel veri içeren belgelerin kopyası
                                niteliğindeki belgelerde yer alan veriler.
                              </td>
                            </tr>
                            <tr>
                              <td>ÇALIŞAN YAKINI VE AİLE BİLGİSİ</td>
                              <td>
                                Çalışanların yakın ve aile üyelerine ilişkin
                                veriler.
                              </td>
                            </tr>
                            <tr>
                              <td>REFERANS BİLGİSİ</td>
                              <td>
                                Çalışan ve adaylarına referans olan kişilere
                                ilişkin veriler.
                              </td>
                            </tr>
                            <tr>
                              <td>
                                ÖZEL NİTELİKLİ KİŞİSEL VERİLER (SAĞLIK/CİNSEL
                                HAYAT)
                              </td>
                              <td>Sağlık ve cinsel hayata ilişkin veriler.</td>
                            </tr>
                            <tr>
                              <td>ÖZEL NİTELİKLİ KİŞİSEL VERİLER II</td>
                              <td>
                                Irk, etnik köken, siyasi düşünce, felsefi inanç,
                                din, mezhep veya diğer inançlar, kılık ve
                                kıyafet, dernek, vakıf ya da sendika üyeliği,
                                ceza mahkûmiyeti ve güvenlik tedbirleriyle
                                ilgili veriler ile biyometrik ve genetik veriler
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <br />
                      </ol>
                    </li>
                    <li className="legal-heading-2">
                      BÖLÜM: KİŞİSEL VERİLERİN İŞLENMESİNİN HUKUKİ DAYANAKLARI
                      VE AMAÇLARI
                      <ol type="I">
                        <li className="legal-heading-2">
                          KİŞİSEL VERİLERİN İŞLENMESİNİN HUKUKİ DAYANAKLARI
                          <ol>
                            <li className="legal-heading-2">Genel İlkeler</li>
                            <p className="legal-text align-justify mb-0">
                              Şirket’imiz tarafından kişisel verilerin
                              işlenmesine yönelik hukuki dayanaklar farklılık
                              gösterse de her türlü kişisel veri işleme
                              faaliyetinde Kanun’un 4.maddesinde genel ilkelere
                              uygun olarak hareket edilmektedir. Buna göre; her
                              türlü veri işlemesinde
                            </p>
                            <ol type="a" className="list-text legal-text mb-32">
                              <li>
                                Hukuka ve dürüstlük kurallarına uygun olma,
                              </li>
                              <li>Doğru ve gerektiğinde güncel olma,</li>
                              <li>
                                Belirli, açık ve meşru amaçlar için işlenme,
                              </li>
                              <li>
                                İşlendikleri amaçla bağlantılı, sınırlı ve
                                ölçülü olma,
                              </li>
                              <li>
                                İlgili mevzuatta öngörülen veya işlendikleri
                                amaç için gerekli olan süre kadar muhafaza
                                edilme genel ilkeleri göz önünde tutulmaktadır.
                              </li>
                            </ol>
                            <li className="legal-heading-2">
                              Hukuka Uygunluk Sebepleri
                              <ol type="A">
                                <li className="legal-heading-2">
                                  Kişisel İlgili Kişinin Açık Rızasının
                                  Bulunması
                                </li>
                                <p className="legal-text align-justify">
                                  Kişisel verilerin işlenme şartlarından biri
                                  sahibinin açık rızasıdır. İlgili kişinin açık
                                  rızası belirli bir konuya ilişkin,
                                  bilgilendirilmeye dayalı olarak ve özgür
                                  iradeyle açıklanmalıdır.
                                </p>
                                <li className="legal-heading-2">
                                  Kanunlarda Açıkça Öngörülmesi
                                </li>
                                <p className="legal-text align-justify">
                                  İlgili kişinin kişisel verileri, kanunda
                                  açıkça öngörülmesi halinde hukuka uygun olarak
                                  işlenebilecektir.{' '}
                                  <i>
                                    Örneğin, Kimlik Bildirme Mevzuatı uyarınca
                                    Çalışanlarımızın kimliklerinin yetkili
                                    mercilere bildirilmesi.
                                  </i>
                                </p>
                                <li className="legal-heading-2">
                                  Fiili İmkânsızlık Sebebiyle İlgilinin Açık
                                  Rızasının Alınamaması
                                </li>
                                <p className="legal-text align-justify">
                                  Fiili imkânsızlık nedeniyle rızasını
                                  açıklayamayacak durumda olan veya rızasına
                                  geçerlilik tanınamayacak olan kişinin
                                  kendisinin ya da başka bir kişinin hayatı veya
                                  beden bütünlüğünü korumak için kişisel
                                  verisinin işlenmesinin zorunlu olması halinde
                                  ilgili kişinin kişisel verileri
                                  işlenebilecektir.{' '}
                                  <i>
                                    Örneğin, baygınlık geçiren çalışanın kan
                                    grubu bilgisinin hekim ile paylaşılması.
                                  </i>
                                </p>
                                <li className="legal-heading-2">
                                  Sözleşmenin Kurulması veya İfasıyla Doğrudan
                                  İlgi Olması
                                </li>
                                <p className="legal-text align-justify">
                                  Bir sözleşmenin kurulması veya ifasıyla
                                  doğrudan doğruya ilgili olması kaydıyla,
                                  sözleşmenin taraflarına ait kişisel verilerin
                                  işlenmesinin gerekli olması halinde kişisel
                                  verilerin işlenmesi mümkündür.{' '}
                                  <i>
                                    Örneğin, iş sözleşmesinin kurulması için
                                    adaydan CV alınması, sözleşme kapsamında
                                    tebligat yapılabilmesi için adres alınması.
                                  </i>
                                </p>
                                <li className="legal-heading-2">
                                  Şirketin Hukuki Yükümlülüğünü Yerine Getirmesi
                                </li>
                                <p className="legal-text align-justify">
                                  Şirket’imizin veri sorumlusu olarak hukuki
                                  yükümlülüklerini yerine getirmesi için
                                  işlemenin zorunlu olması halinde ilgili
                                  kişinin kişisel verileri işlenebilecektir.{' '}
                                  <i>
                                    Örneğin, Asgari Geçim İndiriminden Çalışanı
                                    yararlandırmak için, aile bilgisinin
                                    işlenmesi.
                                  </i>
                                </p>
                                <li className="legal-heading-2">
                                  İlgili Kişinin Kişisel Verisini
                                  Alenileştirmesi
                                </li>
                                <p className="legal-text align-justify">
                                  İlgili kişinin, kişisel verisini kendisi
                                  tarafından alenileştirilmiş olması halinde
                                  kişisel veriler işlenebilecektir.{' '}
                                  <i>
                                    Örneğin, Şirketimiz müşterilerinin internet
                                    üzerinde herkese açık bir platformda
                                    şikâyet, talep veya önerilerini sunması
                                    halinde bu müşteriler ilgili bilgilerini
                                    alenileştirmiş olur. Bu durumda Şirketimiz
                                    yetkilisi tarafından, şikâyet, talep veya
                                    önerilere cevap verme amacıyla sınırlı olmak
                                    kaydıyla verilerin işlenmesi mümkündür.
                                  </i>
                                </p>
                                <li className="legal-heading-2">
                                  Bir Hakkın Tesisi veya Korunması için Veri
                                  İşlemenin Zorunlu Olması
                                </li>
                                <p className="legal-text align-justify">
                                  Bir hakkın tesisi, kullanılması veya korunması
                                  için veri işlemenin zorunlu olması halinde
                                  ilgili kişinin kişisel verileri
                                  işlenebilecektir.{' '}
                                  <b>
                                    Örneğin, ispat niteliği olan verilerin
                                    (satış sözleşmesinin, faturanın) saklanması
                                    ve gerekli olduğu anda kullanılması.
                                  </b>
                                </p>
                                <li className="legal-heading-2">
                                  Şirketimizin Meşru Menfaati için Veri
                                  İşlemenin Zorunlu Olması
                                </li>
                                <p className="legal-text align-justify">
                                  İlgili kişinin temel hak ve özgürlüklerine
                                  zarar vermemek kaydıyla Şirket’imizin meşru
                                  menfaatleri için veri işlemesinin zorunlu
                                  olması halinde ilgili kişinin kişisel verileri
                                  işlenebilecektir.{' '}
                                  <b>
                                    Örneğin, Şirketin güvenlik kamerası ile
                                    hırsızlığa karşı veya iş güvenliği amacıyla
                                    kritik noktalarının izlenmesi.
                                  </b>
                                </p>
                              </ol>
                            </li>
                            <li className="legal-heading-2">
                              Özel Nitelikli Kişisel Verilerin İşlenmesi ve
                              Hukuka Uygunluk Sebepleri
                            </li>
                            <p className="legal-text align-justify">
                              Şirket’imiz tarafından özel nitelikli kişisel
                              veriler ilgili kişinin açık rızası yok ise ancak,
                              Kurul tarafından belirlenecek olan yeterli
                              önlemlerin alınması kaydıyla ancak kanunlarda
                              öngörülen hallerde işlenebilir. İlgili kişinin
                              sağlığına ve cinsel hayatına ilişkin özel
                              nitelikli kişisel verileri ise ancak kamu
                              sağlığının korunması, koruyucu hekimlik, tıbbi
                              teşhis, tedavi ve bakım hizmetlerinin yürütülmesi,
                              sağlık hizmetleri ile finansmanının planlanması ve
                              yönetimi amacıyla, sır saklama yükümlülüğü altında
                              bulunan kişiler veya yetkili kurum ve kuruluşlar
                              tarafından işlenebilir. Hangi nedene dayanırsa
                              dayansın, işleme süreçlerinde daima genel veri
                              işleme ilkeleri dikkate alınmakta ve bu ilkelere
                              uygunluk sağlanmaktadır (Kanun m. 4; bkz. yukarıda
                              2. Bölüm, I, 1).
                            </p>
                          </ol>
                        </li>
                        <li className="legal-heading-2">
                          KİŞİSEL VERİLERİN İŞLENME AMAÇLARI
                        </li>
                        <p className="legal-text align-justify mb-0">
                          Şirket’imiz Kanun’un 5. maddesinin 2. fıkrasında ve 6.
                          maddenin 3. fıkrasında belirtilen kişisel veri işleme
                          şartları içerisindeki amaçlarla ve koşullarla sınırlı
                          olarak kişisel veriler işlemektedir. Veri işleme
                          sürecinde yukarıda belirtilen hukuki dayanaklar
                          dikkate alınmakta, diğer hukuka uygunluk sebepleri
                          bulunmuyor ise ilgilinin rızası talep edilmektedir.
                          Burada da 4. madde kapsamında genel ilkeler denetimi
                          yapılmakta, her şeyden önce veri işleme faaliyetinin
                          genel olarak hukuka uygunluk ilkelerine uyumlu olması
                          aranmaktadır. İlgilinin rızası ise &quot;açık,
                          bilgilendirmeye ve özgür iradeye dayalı biçimde&quot;
                          alınmaktadır
                          <br />
                          Şirketimiz birimlerinde kişisel veriler özellikle
                          aşağıdaki amaçlarla işlenmektedir;
                        </p>
                        <ul className="list-text list-style-circle legal-text">
                          <li>
                            İşveren olarak iş sözleşmesinden doğan karşılıklı
                            yükümlülüklerin yerine getirebilmesi için{' '}
                            <b>ÇALIŞANLARIN, ÖĞRENCİ VE STAJYERLERİN</b> (geniş
                            anlamda çalışanların) ve bunların{' '}
                            <b>VELİ/VASİ TEMSİLCİLERİ</b>’nin kişisel
                            verilerinin işlenmesi gerekmektedir. Çalışanların
                            kişisel verileri; hukuka ve dürüstlük kurallarına
                            uygun, doğru ve gerektiğinde güncel; belirli, açık
                            ve meşru amaçlar doğrultusunda; amaçla bağlantılı,
                            sınırlı ve ölçülü bir biçimde işlenmekte ve
                            saklanmaktadır. Bu kapsamda, çalışanların kanunlara
                            uygun olarak çalıştırılabilmesi için gerekli olan
                            amaçlar doğrultusunda, iş sözleşmesinin kurulması,
                            ifası ve sona ermesi süreçlerinin hukuka uygun
                            şekilde yürütülmesi, temel hak ve özgürlüklere
                            aykırı olmamak koşuluyla Şirket’in meşru
                            menfaatleri, kanunda açık olarak öngörülen durumlar,
                            çalışan istihdamına bağlı hukuki yükümlülüklerin
                            yerine getirilmesi, yasal takip durumlarında hakkın
                            tesisi, kullanılması ve korunması için veri
                            işlemenin zorunlu olması durumları ve bunların
                            dışında kalan durumlarda çalışanlardan talep
                            edilecek açık, bilgilendirmeye dayanan ve
                            çalışanların özgür iradesi ile açıklayacağı rızası,
                            kişisel veri işlemesinin hukuki dayanaklarını
                            oluşturmaktadır.
                          </li>
                          <li className="mt-16">
                            Şirket’in iştigal konusunun gerektirdiği faaliyetler
                            kapsamında, işverenin meşru menfaatleri çalışanların
                            kişisel verilerinin işlenmesini gerekli kılmaktadır.
                            Nitekim, suistimallerinönlenmesi, hırsızlığın
                            engellenmesi, genel güvenlik veya iş sağlığı ve
                            güvenliğinin sağlanması gibi nedenlerle çalışanların
                            kişisel verilerini işleme faaliyeti
                            yapılabilmektedir. Ancak, bu durumda da çalışanların
                            temel hak ve özgürlüklerine zarar verilmemesine
                            büyük bir özen gösterilmektedir.
                          </li>
                          <li className="mt-16">
                            “Çalışan” statüsüne geçildikten sonra (çalışan
                            adaylığı kategorisinde talep edilmemektedir)
                            çalışanın sendikaya üye olması durumunda sendika
                            üyeliği de yasal mevzuatın gerekliliklerinin
                            yapılabilmesi için kanunun açık hükümleri gereği
                            işlenebilmektedir. Bunun dışında çalışanların, ırk,
                            etnik köken, siyasi düşünce, felsefi inanç, din,
                            mezhep veya diğer inançlar, kılık ve kıyafet kural
                            olarak kanunda açıkça öngörülmüş olmadığı sürece,
                            işlenen kişisel veriler arasında yer almamakta,
                            istisnai bir uygulamaya gidilecek ise, kişisel
                            veriler işlenmeden önce gereklilikler özenle
                            değerlendirilmekte ve ilgiliden açık rıza
                            alınmaktadır. Şirket’imizin bilgi iletişim araçları
                            (telefon, mobil telefonlar, bilgisayarlar ve
                            internet) üzerinde denetim ve gözetimler söz
                            konusudur. 5651 sayılı Kanun, ilgili mevzuatlar ve
                            Şirket’imizin meşru menfaatleri söz konusu
                            uygulamaların hukuki dayanaklarını oluşturmaktadır.
                          </li>
                          <li className="mt-16">
                            Çalışanlarımızın yanı sıra, ziyaretçilerimiz ile
                            Şirket’imiz sisteminden internete bağlanan diğer
                            kişi kategorilerimiz de söz konusu işleme kapsamında
                            yer alabilmektedir. Şirket araçlarında
                            &quot;güvenlik, araçların ve personelin daha etkili
                            bir biçimde yönetimi&quot; gerekçeleriyle araç takip
                            sistemi uygulanabilmektedir. Söz konusu faaliyet de
                            Şirket’imizin meşru menfaatlerine dayanmakla
                            birlikte çalışanların temel hak ve özgürlüklerine
                            zarar vermemek kaydıyla gerçekleştirilmektedir.
                          </li>
                          <li className="mt-16">
                            <b>ÇALIŞAN YAKIN VE AİLE ÜYELERİ</b>’nin kişisel
                            verileri de acil durum süreçlerinde irtibatın ve
                            iletişimin sağlanması (örneğin bir kaza durumunda
                            haber verilmesi), asgari geçim indirimi gibi hukuki
                            yükümlülüklerin yerine getirilmesi ve çalışan
                            ilişkileri süreçleri ile bağlantılı olarak
                            işlenmektedir.
                          </li>
                          <li className="mt-16">
                            4857 sayılı İş Kanunu, 6098 sayılı Borçlar Kanunu m.
                            419, Kişisel Verilerin Korunması Hakkındaki 6698
                            sayılı Kanun ve ilgili mevzuata göre Şirket’imiz, iş
                            başvurusunda bulunan <b>ÇALIŞAN ADAYLARI</b>
                            ’nınisim, adres, doğum tarihi, e-posta adresi,
                            telefon numarası ve diğer iletişim bilgileri,
                            özgeçmiş, ön yazı, geçmiş veya ilgili iş tecrübesi
                            veya diğer tecrübe, eğitim durumu, transkript, dil
                            test sonuçları veya iş başvurusuna ilişkin
                            destekleyici veya açıklayıcı belgeler, video
                            konferans, telefon gibi araçlarla veya yüz yüze
                            mülakat yapılması durumunda mülakat sırasında elde
                            edilen bilgilerin kayıtları, iş başvurusunda
                            belirtmiş olduğunuz referanslar gibi kişisel
                            bilgileri işleyebilmektedir. Çalışan adaylığı
                            bağlamında, adayın yetkinlikleri ile doğrudan ilgili
                            olmayan herhangi bir kişisel bilgiyi ve özellikle
                            özel (hassas) nitelikli verileri (kişilerin ırkı,
                            etnik kökeni, siyasi düşüncesi, felsefi inancı,
                            dini, mezhebi veya diğer inançları, kılık ve
                            kıyafeti, dernek, vakıf ya da sendika üyeliği,
                            sağlığı, cinsel hayatı, ceza mahkûmiyeti ve güvenlik
                            tedbirleri, biyometrik ve genetik verileri)
                            belirtmekten kaçınması, yazılı olarak verilen CV ve
                            belgelerde ilgili kısımlar varsa karartılarak
                            Şirkete teslimi önemle tavsiye edilmektedir. Ayrıca,
                            adayın özgeçmişinde yer alan ve bu süreçte verisi
                            işlenen{' '}
                            <b>
                              REFERANS KİŞİ/KİŞİLERE AİT KİŞİSEL VERİLER
                            </b>{' '}
                            (isim, soy isim, telefon, e-posta vb.) için, söz
                            konusu kişinin bilgilendirilmesi ve açık
                            rızası(ları)nın alınmasının adayın sorumluluğunda
                            olduğunu bilerek Şirket’e ulaştırılması
                            gerekmektedir.
                          </li>
                          <li className="mt-16">
                            <b>TEDARİKÇİ/ALT İŞVEREN YETKİLİ VE ÇALIŞANLARI</b>
                            ’nın kişisel verileri de Şirket’imizce
                            işlenebilmektedir. Nitekim 6331 sayılı Kanunda asıl
                            işverene iş sağlığı ve güvenliği ile ilgili olarak
                            başka işyerinden gelen çalışanlar ile ilgili olarak
                            kontrol edilmesi gereken belgeler ve bilgiler
                            belirtilmiş bulunmaktadır. Aynı şekilde 4857 sayılı
                            İş Kanunu’nda ve 5510 sayılı Sosyal Sigortalar ve
                            Genel Sağlık Sigortası Kanunu’nda da alt işveren
                            işçileri ve geçici işçiler ile ilgili asıl işverene
                            yükümlülükler getirilmiş ve bu kapsamda kontrol
                            edilmesi gereken hususlar belirtilmiştir. Buna göre
                            tedarikçi ve başka işverene bağlı olarak işyerinde
                            çalışan işçilerin kişisel verilerinin işlenmesi
                            başta söz konusu yasal düzenlemelerin gerekleri ve
                            hukuki yükümlülüklerin yerine getirilmesi olmak
                            üzere Şirket’imizin meşru menfaatlerine
                            dayanmaktadır.{' '}
                            <b>
                              ÜRÜN/HİZMET ALAN KİŞİLER VEYA BUNLARIN
                              YETKİLİLERİNİN
                            </b>{' '}
                            verileri de ilgili kişi ile söz konusu olan ticari
                            faaliyet ve hukuki ilişki kapsamında işlenmektedir.
                          </li>
                          <li className="mt-16">
                            İnternet Sitemizi ziyaret eden ve Mobil Uygulamamızı
                            kullanan kullanıcıların kişisel verileri çerezler
                            aracılığıyla da işlenmektedir. İnternet sitemizde ve
                            Mobil Uygulamamızda kullanılan <b>(i)</b> zorunlu
                            çerezler, internet sitemizi ve mobil uygulamamızı
                            ziyaret ederek talep etmiş olduğunuz bilgi toplumu
                            hizmetinin yerine getirilebilmesi amacıyla
                            kullanılmaktadır. Bu çerezler ile işlenen kişisel
                            verileriniz; Kanun’un md. 5/2.(c) hükmü sözleşmenin
                            ifası için gerekli olmasıveya Kanun’un md. 5/2.(f)
                            hükmü veri sorumlusunun meşru menfaatleri kapsamında
                            işlenmektedir. <b>(ii)</b> performans ve analiz için
                            gerekli olan çerezler, internet sitemizin ve mobil
                            uygulamamızın geliştirilmesine yardımcı olmak
                            amacıyla kullanılmaktadır. Bu çerezler ile işlenen
                            kişisel verileriniz; <b>(iii)</b> işlevsellik için
                            gerekli olan çerezler, internet sitemizin ve mobil
                            uygulamamızın işlevselliğini artırmak ve gizlilik
                            tercihi dışındaki ayarlarınızın kişiselleştirmesi
                            amacıyla kullanılmaktadır. Bu çerezler ile işlenen
                            kişisel verileriniz; <b>(iv)</b> hedefleme ve reklam
                            çerezleri, üçüncü taraf çerez hizmet sağlayıcıları
                            tarafından ziyaretçilerin ilgi alanlarına göre
                            profilinin çıkarılması ve ziyaretçilere kendileri
                            ile ilgili reklamlar gösterilmesi amacıyla
                            kullanılmaktadır. Bu (ii), (iii) ve (iv)
                            kapsamındaki çerezler ile işlenen kişisel
                            verileriniz; Kanun’un md. 5/1 hükmü kapsamında, açık
                            rızanız alınarak işlenmektedir.
                          </li>
                          <li className="mt-16">
                            Kişisel veriler, ayrıca:
                            <div className="align-justify">
                              <ol type="1" className="list-text-2">
                                <li>
                                  Acil Durum Yönetimi Süreçlerinin Yürütülmesi
                                </li>
                                <li>
                                  Bilgi Güvenliği Süreçlerinin Yürütülmesi
                                </li>
                                <li>
                                  Çalışan Adayı / Stajyer / Öğrenci Seçme ve
                                  Yerleştirme Süreçlerinin Yürütülmesi
                                </li>
                                <li>
                                  Çalışan Adaylarının Başvuru Süreçlerinin
                                  Yürütülmesi
                                </li>
                                <li>
                                  Çalışan Memnuniyeti ve Bağlılığı Süreçlerinin
                                  Yürütülmesi
                                </li>
                                <li>
                                  Çalışanlar İçin İş Akdi ve Mevzuattan Kaynaklı
                                  Yükümlülüklerin Yerine Getirilmesi
                                </li>
                                <li>
                                  Çalışanlar İçin Yan Haklar ve Menfaatleri
                                  Süreçlerinin Yürütülmesi
                                </li>
                                <li>
                                  Denetim/Etik Faaliyetlerinin Yürütülmesi
                                </li>
                                <li>Eğitim Faaliyetlerinin Yürütülmesi</li>
                                <li>Erişim Yetkilerinin Yürütülmesi</li>
                                <li>
                                  Faaliyetlerin Mevzuata Uygun Yürütülmesi
                                </li>
                                <li>
                                  Finans ve Muhasebe İşlerinin Yürütülmesi
                                </li>
                                <li>
                                  Firma/Ürün/Hizmetlere Bağlılık Süreçlerinin
                                  Yürütülmesi
                                </li>
                                <li>Fiziksel Mekan Güvenliğinin Temini</li>
                                <li>Görevlendirme Süreçlerinin Yürütülmesi</li>
                                <li>Hukuk İşlerinin Takibi ve Yürütülmesi</li>
                                <li>
                                  İç Denetim/Soruşturma/İstihbarat
                                  Faaliyetlerinin Yürütülmesi
                                </li>
                                <li>İletişim Faaliyetlerinin Yürütülmesi</li>
                                <li>
                                  İnsan Kaynakları Süreçlerinin Planlanması
                                </li>
                                <li>
                                  İş Faaliyetlerinin Yürütülmesi / Denetimi
                                </li>
                                <li>
                                  İş Sağlığı / Güvenliği Faaliyetlerinin
                                  Yürütülmesi
                                </li>
                                <li>
                                  İş Süreçlerinin İyileştirilmesine Yönelik
                                  Önerilerin Alınması ve Değerlendirilmesi
                                </li>
                                <li>
                                  İş Sürekliliğinin Sağlanması Faaliyetlerinin
                                  Yürütülmesi
                                </li>
                                <li>
                                  Mal / Hizmet Satın Alım Süreçlerinin
                                  Yürütülmesi
                                </li>
                                <li>
                                  Mal / Hizmet Satış Sonrası Destek
                                  Hizmetlerinin Yürütülmesi
                                </li>
                                <li>
                                  Mal / Hizmet Satış Süreçlerinin Yürütülmesi
                                </li>
                                <li>
                                  Mal/Hizmet/Üretim ve Operasyon Süreçlerinin
                                  Yürütülmesi
                                </li>
                                <li>
                                  Müşteri İlişkileri Süreçlerinin Yürütülmesi
                                </li>
                                <li>
                                  Müşteri Memnuniyetine Yönelik Aktivitelerin
                                  Yürütülmesi
                                </li>
                                <li>Organizasyon ve Etkinlik Yönetimi</li>
                                <li>
                                  Pazarlama Analiz Çalışmalarının Yürütülmesi
                                </li>
                                <li>
                                  Performans Değerlendirme Süreçlerinin
                                  Yürütülmesi
                                </li>
                                <li>
                                  Reklam/Kampanya/Promosyon Süreçlerinin
                                  Yürütülmesi
                                </li>
                                <li>Risk Yönetimi Süreçlerinin Yürütülmesi</li>
                                <li>
                                  Saklama ve Arşiv Faaliyetlerinin Yürütülmesi
                                </li>
                                <li>
                                  Sosyal Sorumluluk ve Sivil Toplum
                                  Aktivitelerinin Yürütülmesi
                                </li>
                                <li>Sözleşme Süreçlerinin Yürütülmesi</li>
                                <li>Sponsorluk Faaliyetlerinin Yürütülmesi</li>
                                <li>
                                  Stratejik Planlama Faaliyetlerinin Yürütülmesi
                                </li>
                                <li>Talep / Şikayetlerin Takibi</li>
                                <li>
                                  Taşınır Mal ve Kaynakların Güvenliğinin Temini
                                </li>
                                <li>Ücret Politikasının Yürütülmesi</li>
                                <li>
                                  Ürün/Hizmetlerin Pazarlama Süreçlerinin
                                  Yürütülmesi
                                </li>
                                <li>
                                  Veri Sorumlusu Operasyonlarının Güvenliğinin
                                  Temini
                                </li>
                                <li>Yatırım Süreçlerinin Yürütülmesi</li>
                                <li>
                                  Yetenek / Kariyer Gelişimi Faaliyetlerinin
                                  Yürütülmesi
                                </li>
                                <li>
                                  Yetkili Kişi, Kurum ve Kuruluşlara Bilgi
                                  Verilmesi
                                </li>
                                <li>Yönetim Faaliyetlerinin Yürütülmesi</li>
                                <li>
                                  Ziyaretçi Kayıtlarının Oluşturulması ve Takibi
                                </li>
                              </ol>
                              Amaçlarıyla ilgili birimlerimizde işlenmektedir.
                            </div>
                          </li>
                          <li className="mt-16">
                            İşyerlerimizde iş sağlığı ve güvenliği, genel
                            güvenlik, ürün güvenliği amaçlarıyla işyerinde
                            kamera ile izleme faaliyeti ve Ziyaretçi Kayıtları,
                            Şirketimizin meşru menfaatleri dikkate alınarak,
                            ziyaretçilerimizin, bu kapsamda verisi işlenen
                            kişilerin ve özellikle çalışanların temel hak ve
                            özgürlüklerine zarar vermemek kaydıyla
                            gerçekleştirilmektedir
                          </li>
                        </ul>
                      </ol>
                    </li>
                    <li className="legal-heading-2">
                      BÖLÜM: KİŞİSEL VERİLERİN SAKLANMASI, SİLİNMESİ, YOK
                      EDİLMESİ VE ANONİMLEŞTİRİLMESİ
                      <p className="legal-text align-justify ">
                        Şirket’imiz, Türk Ceza Kanunu’nun 138. maddesinde ve
                        Kanun’un 7. maddesinde düzenlendiği üzere ilgili kanun
                        hükümlerine uygun olarak işlenmiş olmasına rağmen,
                        işlenmesini gerektiren sebeplerin ortadan kalkması
                        hâlinde Şirket’imizin kendi kararına istinaden veya
                        ilgili kişinin talebi üzerine kişisel veriler silinir,
                        yok edilir veya anonim hâle getirilmektedir.
                      </p>
                      <ol type="I">
                        <li className="legal-heading-2">
                          KİŞİSEL VERİLERİN SAKLANMASI VE SAKLAMA SÜRELERİ
                        </li>
                        <p className="legal-text align-justify">
                          Şirket’imiz, öncelikle ilgili kanunlarda ve mevzuatta
                          öngörülmesi durumunda kişisel verileri ilgili
                          mevzuatta belirtilen süre boyunca saklanmaktadır.
                          Kişisel verilerin ne kadar süre boyunca saklanması
                          gerektiğine ilişkin mevzuatta bir süre
                          düzenlenmemişse, ilgili kişi ile bağlantılı hukuki
                          ilişki kapsamında söz konusu olabilecek zamanaşımı/hak
                          düşürücü süreler dikkate alınarak saklama süreleri
                          belirlenmektedir. Kişisel Verilerin Saklanması ile
                          ilgili olarak{' '}
                          <b>
                            “Kişisel Verilerin Saklanması ve İmhası Prosedürü”
                          </b>{' '}
                          yürürlüğe sokulmuş ve detaylı saklama süreleri
                          belirtilmiş bulunmaktadır.
                        </p>
                        <li className="legal-heading-2">
                          KİŞİSEL VERİLERİN SİLİNMESİ, YOK EDİLMESİ VE
                          ANONİMLEŞTİRİLMESİ
                          <p className="legal-text align-justify ">
                            Türk Ceza Kanunu’nun 138. maddesinde ve Kanun’un 7.
                            maddesinde düzenlendiği üzere ilgili kanun
                            hükümlerine uygun olarak işlenmiş olmasına rağmen,
                            işlenmesini gerektiren sebeplerin ortadan kalkması
                            hâlinde Şirket’imizin kendi kararına istinaden veya
                            ilgili kişinin talebi üzerine kişisel veriler
                            silinmekte, yok edilmekte veya anonim hâle
                            getirilmektedir. Bu kapsamda Şirket’imiz ilgili
                            yükümlülüğünü bu bölümde açıklanan yöntemlerle
                            yerine getirmektedir.
                          </p>
                          <ol>
                            <li className="legal-heading-2">
                              Kişisel Verilerin Silinmesi
                              <ol type="A">
                                <li className="legal-heading-2">
                                  Kişisel Verilerin Silinmesi İşlemi
                                </li>
                                <p className="legal-text align-justify">
                                  Şirket’imiz ilgili kanun hükümlerine uygun
                                  olarak işlenmiş olmasına rağmen, işlenmesini
                                  gerektiren sebeplerin ortadan kalkması hâlinde
                                  kendi kararına istinaden veya ilgili kişinin
                                  talebi üzerine kişisel verileri silinebilir.
                                  Kişisel verilerin silinmesi, kişisel verilerin
                                  ilgili kullanıcılar içinhiçbir şekilde
                                  erişilemez ve tekrar kullanılamaz hale
                                  getirilmesi işlemidir. Şirketimizce, silinen
                                  kişisel verilerin ilgili kullanıcılar için
                                  erişilemez ve tekrar kullanılamaz olması için
                                  gerekli her türlü teknik ve idari tedbirler
                                  alınmaktadır.
                                </p>
                                <li className="legal-heading-2">
                                  Kişisel Verilerin Silinmesi Süreci
                                </li>
                                <p className="legal-text align-justify mb-0">
                                  Kişisel verilerin silinmesi işleminde
                                  izlenmesi gereken süreç aşağıdaki gibidir:
                                </p>
                                <ul className="list-text list-style-circle legal-text mb-32">
                                  <li>
                                    Silme işlemine konu teşkil edecek kişisel
                                    verilerin belirlenmesi.
                                  </li>
                                  <li>
                                    Erişim yetki ve kontrol matrisi ya da benzer
                                    bir sistem kullanarak her bir kişisel veri
                                    için ilgili kullanıcıların tespit edilmesi.
                                  </li>
                                  <li>
                                    İlgili kullanıcıların erişim, geri getirme,
                                    tekrar kullanma gibi yetkilerinin ve
                                    yöntemlerinin tespit edilmesi.
                                  </li>
                                  <li>
                                    İlgili kullanıcıların kişisel veriler
                                    kapsamındaki erişim, geri getirme, tekrar
                                    kullanma yetki ve yöntemlerinin kapatılması
                                    ve ortadan kaldırılması.
                                  </li>
                                </ul>
                                <li className="legal-heading-2">
                                  Kişisel Verilerin Silinmesi Yöntemleri
                                </li>
                                <p className="legal-text align-justify">
                                  Kişisel veriler çeşitli kayıt ortamlarında
                                  saklanabildiklerinden kayıt ortamlarına uygun
                                  yöntemlerle silinmektedir.
                                </p>
                              </ol>
                            </li>
                            <li className="legal-heading-2">
                              Kişisel Verilerin Yok Edilmesi
                              <ol type="A">
                                <li className="legal-heading-2">
                                  Kişisel Verilerin Yok Edilmesi İşlemi
                                </li>
                                <p className="legal-text align-justify">
                                  Şirket’imiz ilgili kanun hükümlerine uygun
                                  olarak işlenmiş olmasına rağmen, işlenmesini
                                  gerektiren sebeplerin ortadan kalkması hâlinde
                                  kendi kararına istinaden veya ilgili kişinin
                                  talebi üzerine kişisel verileri yok edebilir.
                                  Kişisel verilerin yok edilmesi,{' '}
                                  <b>
                                    kişisel verilerin hiç kimse tarafından
                                    hiçbir şekilde erişilemez, geri getirilemez
                                    ve tekrar kullanılamaz hale getirilmesi
                                    işlemidir
                                  </b>
                                  . Şirketimiz, kişisel verilerin yok
                                  edilmesiyle ilgili gerekli her türlü teknik ve
                                  idari tedbirleri almaktadır.
                                </p>
                                <li className="legal-heading-2">
                                  Kişisel Verilerin Yok Edilmesi Yöntemleri
                                </li>
                                <p className="legal-text align-justify">
                                  Kişisel verilerin yok edilmesi için, verilerin
                                  bulunduğu tüm kopyalar tespit edilir ve
                                  verilerin bulunduğu sistemler tek tek yok
                                  edilir.
                                </p>
                              </ol>
                            </li>
                            <li className="legal-heading-2">
                              Kişisel Verilerin Anonim Hale Getirilmesi
                              <ol type="A">
                                <li className="legal-heading-2">
                                  Kişisel Verilerin Anonimleştirilmesi İşlemi
                                </li>
                                <p className="legal-text align-justify">
                                  Kişisel verilerin anonimleştirilmesi, kişisel
                                  verilerin başka verilerle eşleştirilerek dahi
                                  hiçbir surette kimliği belirli veya
                                  belirlenebilir bir gerçek kişiyle
                                  ilişkilendirilemeyecek hâle getirilmesidir.
                                  Şirketimiz, hukuka uygun olarak işlenen
                                  kişisel verilerin işlenmesini gerektiren
                                  sebepler ortadan kalktığında kişisel verileri
                                  anonimleştirebilmektedir. Şirketimiz, kişisel
                                  verilerin anonim hale getirilmesi için gerekli
                                  her türlü teknik ve idari tedbirleri
                                  almaktadır.
                                  <br />
                                  Kanun’un 28. maddesine uygun olarak anonim
                                  hale getirilmiş olan kişisel veriler
                                  araştırma, planlama ve istatistik gibi
                                  amaçlarla işlenebilir. Bu tür işlemeler
                                  Kanun’un kapsamı dışında olup, ilgili kişinin
                                  açık rızası aranmayacaktır.
                                </p>
                                <li className="legal-heading-2">
                                  Kişisel Verilerin Anonimleştirilmesi
                                  Yöntemleri
                                </li>
                                <p className="legal-text align-justify">
                                  Anonim hale getirme, bir veri kümesindeki tüm
                                  doğrudan ve/veya dolaylı tanımlayıcıların
                                  çıkartılarak ya da değiştirilerek, ilgili
                                  kişinin kimliğinin saptanabilmesinin
                                  engellenmesi veya bir grup veya kalabalık
                                  içinde ayırt edilebilir olma özelliğini, bir
                                  gerçek kişiyle ilişkilendirilemeyecek şekilde
                                  kaybetmesidir. Bu özelliklerin engellenmesi
                                  veya kaybedilmesi sonucunda belli bir kişiye
                                  işaret etmeyen veriler, anonim hale getirilmiş
                                  veri sayılır. Anonim hale getirmedeki amaç,
                                  veri ile bu verinin tanımladığı kişi
                                  arasındaki bağın kopartılmasıdır. Kişisel
                                  verinin tutulduğu veri kayıt sistemindeki
                                  kayıtlara uygulanan otomatik olan veya olmayan
                                  gruplama, maskeleme, türetme, genelleştirme,
                                  rastgele hale getirme gibi yöntemlerle
                                  yürütülen bağ koparma işlemlerinin hepsine
                                  anonim hale getirme yöntemleri adı verilir. Bu
                                  yöntemlerin uygulanması sonucunda elde edilen
                                  verilerin belirli bir kişiyi tanımlayamaz
                                  olması gerekmektedir.
                                </p>
                              </ol>
                            </li>
                          </ol>
                        </li>
                      </ol>
                    </li>
                    <li className="legal-heading-2">
                      BÖLÜM: İLGİLİ KİŞİLERİN HAKLARI
                      <ol type="I">
                        <li className="legal-heading-2">
                          İLGİLİ KİŞİLERİN HAKLARININ KAPSAMI VE BU HAKLARIN
                          KULLANILMASI
                          <ol>
                            <li className="legal-heading-2">
                              İlgili Kişilerin Hakları
                            </li>
                            <p className="legal-text align-justify mb-0">
                              Şirketimizce kişisel verisi işlenen kişiler
                              aşağıda yer alan haklara sahiptir:
                            </p>
                            <ul className="list-text list-style-tick legal-text mb-32">
                              <li>
                                Kişisel veri işlenip işlenmediğini öğrenme,
                              </li>
                              <li>
                                Kişisel verileri işlenmişse buna ilişkin bilgi
                                talep etme,
                              </li>
                              <li>
                                Kişisel verilerin işlenme amacını ve bunların
                                amacına uygun kullanılıp kullanılmadığını
                                öğrenme,
                              </li>
                              <li>
                                Yurt içinde veya yurt dışında kişisel verilerin
                                aktarıldığı üçüncü kişileri bilme,
                              </li>
                              <li>
                                Kişisel verilerin eksik veya yanlış işlenmiş
                                olması hâlinde bunların düzeltilmesini isteme ve
                                bu kapsamda yapılan işlemin kişisel verilerin
                                aktarıldığı üçüncü kişilere bildirilmesini
                                isteme,
                              </li>
                              <li>
                                Kanun ve ilgili diğer kanun hükümlerine uygun
                                olarak işlenmiş olmasına rağmen, işlenmesini
                                gerektiren sebeplerin ortadan kalkması hâlinde
                                kişisel verilerin silinmesini veya yok
                                edilmesini isteme ve bu kapsamda yapılan işlemin
                                kişisel verilerin aktarıldığı üçüncü kişilere
                                bildirilmesini isteme,
                              </li>
                              <li>
                                İşlenen verilerin münhasıran otomatik sistemler
                                vasıtasıyla analiz edilmesi suretiyle kişinin
                                kendisi aleyhine bir sonucun ortaya çıkmasına
                                itiraz etme,
                              </li>
                              <li>
                                Kişisel verilerin kanuna aykırı olarak işlenmesi
                                sebebiyle zarara uğraması hâlinde zararın
                                giderilmesini talep etme.
                              </li>
                            </ul>
                            <li className="legal-heading-2">
                              İlgili Kişilerin Haklarını Kullanması
                            </li>
                            <p className="legal-text align-justify">
                              İlgili Kişilerin Kanun’un 13. maddesinin 1.
                              fıkrası gereğince yukarıda belirtilen haklarını
                              kullanmakla ilgili taleplerini, aşağıdaki
                              yöntemlerden ve adreslerden birisini tercih ederek
                              Şirket’imize iletmesi gerekli ve yeterlidir;
                            </p>
                            <table className="table-2">
                              <tbody>
                                <tr>
                                  <td>Başvuru Yöntemi</td>
                                  <td>
                                    <b>Başvurunun Yapılacağı Adres</b>
                                  </td>
                                  <td>
                                    <b>
                                      Başvuru Gönderiminde Belirtilecek Bilgi
                                    </b>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <b>Şahsen başvuru</b> (Başvuru sahibinin
                                    bizzat gelerek kimliğini tevsik edici belge
                                    ile başvurması)
                                  </td>
                                  <td>
                                    Ünalan Mah. Libadiye Cad. Emaar Residence
                                    No: 82E İç Kapı No: 120 Üsküdar/İstanbul
                                  </td>
                                  <td>
                                    Zarfın üzerine “Kişisel Verilerin Korunması
                                    Kanunu Kapsamında Bilgi Talebi” yazılacaktır
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <b>Noter vasıtasıyla tebligat</b>
                                  </td>
                                  <td>
                                    Ünalan Mah. Libadiye Cad. Emaar Residence
                                    No: 82E İç Kapı No: 120 Üsküdar/İstanbul
                                  </td>
                                  <td>
                                    Tebligat zarfına “Kişisel Verilerin
                                    Korunması Kanunu Kapsamında Bilgi Talebi”
                                    yazılacaktır
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <b>“Güvenli elektronik imza”</b> ile
                                    imzalanarak Kayıtlı Elektronik Posta (KEP)
                                    yoluyla
                                  </td>
                                  <td>
                                    <a
                                      href="mailto:nemateknoloji@hs01.kep.tr"
                                      className="font-size-8"
                                    >
                                      nemateknoloji@hs01.kep.tr
                                    </a>
                                  </td>
                                  <td>
                                    E-postanın konu kısmına “Kişisel Verilerin
                                    Korunması Kanunu Bilgi Talebi” yazılacaktır
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                            <br />
                            <p className="legal-text align-justify">
                              <br />
                              Başvuruda;
                              <br />
                              <br />
                              Ad, soyadı ve başvuru yazılı ise imza, T.C.
                              vatandaşları için T.C. Kimlik Numarası, yabancılar
                              için uyruğu, pasaport numarası veya varsa kimlik
                              numarası, tebligata esas yerleşim yeri veya iş
                              yeri adresi, varsa bildirime esas elektronik posta
                              adresi, telefon ve faks numarası, talep konusu,
                              bulunması zorunludur. Konuya ilişkin bilgi ve
                              belgeler de başvuruya eklenir.
                              <br />
                              <br />
                              İlgili kişinin kendisi dışında bir kişinin talepte
                              bulunması için ilgili kişi tarafından başvuruda
                              bulunacak kişi adına düzenlenmiş vekâletname
                              bulunmalıdır. İlgili kişi olarak kullanmayı talep
                              edilen hakka ilişkin açıklamaları içeren
                              başvuruda; talep edilen hususun açık ve anlaşılır
                              olması, talep edilen konunun ilgili kişiye ilişkin
                              olması veya başkası adına hareket ediliyor ise
                              yetkili olunması ve yetkinin belgelendirilmesi,
                              başvurunun kimlik ve adres bilgilerini içermesi ve
                              başvuruya kimliğinizi tevsik edici belgelerin
                              eklenmesi gerekmektedir.
                              <br />
                              <br />
                              İlgili Kişi Başvuru Formu, Şirketimizin web
                              sitesinde{' '}
                              <a href="/yasal-bilgiler">
                                www.getmundi.app/yasal-bilgiler
                              </a>{' '}
                              adresinde ve mobil aplikasyonun Ayarlar – Yasal
                              Bilgiler bölümünde mevcut bulunmaktadır.
                            </p>
                            <li className="legal-heading-2">
                              Başvurulara Cevap Verilmesi
                            </li>
                            <p className="legal-text align-justify">
                              İlgili kişinin, talebini öngörülen usule uygun
                              olarak Şirket’imize iletmesi durumunda,
                              Şirket’imiz talebin niteliğine göre en kısa sürede
                              ve en geç otuz gün içinde ilgili talebi ücretsiz
                              olarak sonuçlandıracaktır. Ancak, işlemin ayrıca
                              bir maliyeti gerektirmesi hâlinde, Şirketimiz
                              tarafından başvuru sahibinden Kurul tarafından
                              belirlenen tarifedeki ücret alınacaktır.
                              Şirketimiz, başvuruda bulunan kişinin ilgili kişi
                              olup olmadığını tespit etmek adına ilgili kişiden
                              bilgi talep edebilir. Şirketimiz, ilgili kişinin
                              başvurusunda yer alan hususları netleştirmek
                              adına, ilgili kişine başvurusu ile ilgili soru
                              yöneltebilir. Başvurular Şirket’imizin “İlgili
                              Kişi Başvuru Prosedürü”ne göre Şirket’imiz içinde
                              yönetilmektedir.
                            </p>
                          </ol>
                        </li>
                      </ol>
                    </li>
                    <li className="legal-heading-2">
                      BÖLÜM: KİŞİSEL VERİLERİN GÜVENLİĞİNİN SAĞLANMASI
                      <ol type="I">
                        <li className="legal-heading-2">
                          KİŞİSEL VERİLERİN HUKUKA UYGUN İŞLENMESİNİ SAĞLAMAK
                          İÇİN ALINAN TEKNİK VE İDARİ TEDBİRLER
                        </li>
                        <p className="legal-text align-justify mb-0">
                          Şirket’imiz, kişisel verilerin hukuka uygun
                          işlenmesini sağlamak için gerekli tüm teknik ve idari
                          tedbirler almaktadır. Bu kapsamda,
                        </p>
                        <ul className="list-text list-style-tick legal-text mb-32">
                          <li>
                            Şirket’imizin ilgili kişilere ait aydınlatma
                            yükümlülüğünün eksiksiz olarak ve doğru biçimde
                            yerine getirilebilmesi için{' '}
                            <b>
                              “Kişisel Verilerin İşlenmesinde Aydınlatma
                              Esasları Prosedürü”
                            </b>{' '}
                            yürürlüğe konulmuş bulunmaktadır.
                          </li>
                          <li>
                            Çalışanlar, kişisel verilerin korunması mevzuatı
                            konusunda bilgilendirilmekte ve eğitilmektedir.
                          </li>
                          <li>
                            Şirket’imizin yürütmüş olduğu tüm faaliyetler
                            detaylı olarak tüm iş birimleri özelinde analiz
                            edilerek, bu analiz neticesinde ilgili iş
                            birimlerinin gerçekleştirmiş olduğu faaliyetler
                            özelinde kişisel veri işleme süreçlerinde mevzuata
                            uygunluk sağlanmaktadır.
                          </li>
                          <li>
                            Şirket’imizin iş birimlerinin yürütmüş olduğu
                            kişisel veri işleme faaliyetleri; bu faaliyetlerin
                            6698 Sayılı Kanun’un aradığı kişisel veri işleme
                            şartlarına uygunluğun sağlanması için yerine
                            getirilecek olan gereklilikler her bir iş birimi ve
                            yürütmüş olduğu detay faaliyet özelinde
                            belirlenmektedir.
                          </li>
                          <li>
                            Şirket’imiz ile çalışanlar arasındaki hukuki
                            ilişkiyi yöneten sözleşme ve belgelere, Şirket’in
                            talimatları ve kanunla getirilen istisnalar dışında,
                            kişisel verileri işlememe, ifşa etmeme ve kullanmama
                            yükümlülüğü getiren kayıtlar konulmakta ve bu konuda
                            çalışanların farkındalığı yaratılmakta ve denetimler
                            yürütülmektedir.
                          </li>
                          <li>
                            Şirket’imiz ile Şirket’imizin sorumlu olduğu
                            verileri işleyen üçüncü taraflar arasındaki hukuki
                            ilişkiyi yöneten sözleşme ve belgelere, Şirket’in
                            talimatları ve kanunla getirilen istisnalar dışında,
                            kişisel verileri işlememe, ifşa etmeme ve kullanmama
                            yükümlülüğü getiren kayıtlar konulmakta ve bu konuda{' '}
                            <b>
                              “Üçüncü Taraflarla Gizlilik ve Kişisel Verilerin
                              Korunması Prosedürü”
                            </b>{' '}
                            yürürlüğe konulmuş bulunulmaktadır.
                          </li>
                        </ul>
                        <li className="legal-heading-2">
                          ÖZEL NİTELİKLİ KİŞİSEL VERİLERİN İŞLENMESİNDE ALINAN
                          TEKNİK VE İDARİ TEDBİRLER
                        </li>
                        <p className="legal-text align-justify mb-0">
                          Kanun ile birtakım kişisel verilere, hukuka aykırı
                          olarak işlendiğinde kişilerin mağduriyetine veya
                          ayrımcılığa sebep olma riski nedeniyle özel önem
                          atfedilmiştir. Bu veriler; ırk, etnik köken, siyasi
                          düşünce, felsefi inanç, din, mezhep veya diğer
                          inançlar, kılık ve kıyafet, dernek, vakıf ya da
                          sendika üyeliği, sağlık, cinsel hayat, ceza
                          mahkûmiyeti ve güvenlik tedbirleriyle ilgili veriler
                          ile biyometrikve genetik verilerdir. Şirket’imiz
                          tarafından, Kanun ile “özel nitelikli” olarak
                          belirlenen ve hukuka uygun olarak işlenen özel
                          nitelikli kişisel verilerin korunmasında hassasiyetle
                          davranılmaktadır. Bu kapsamda, Şirket’imiz tarafından,
                          kişisel verilerin korunması için alınan teknik ve
                          idari tedbirler, özel nitelikli kişisel veriler
                          bakımından özenle uygulanmakta ve gerekli denetimler
                          sağlanmaktadır.
                          <br />
                          Bu kapsamda;
                        </p>
                        <ul className="list-text list-style-tick legal-text mb-32">
                          <li>
                            Özel nitelikli kişisel verilerin güvenliğine ve
                            işlenme esaslarına ilişkin olarak{' '}
                            <b>
                              “Özel Nitelikli Kişisel Verilerin İşlenmesi
                              Prosedürü”
                            </b>{' '}
                            uygulamaya konulmuştur.
                          </li>
                          <li>
                            Özel nitelikli kişisel verilerin işlenmesi
                            süreçlerinde yer alan çalışanlara yönelik, Kanun ve
                            buna bağlı yönetmelikler ile özel nitelikli kişisel
                            veri güvenliği konularında düzenli olarak eğitimler
                            verilmekte, gizlilik sözleşmeleri yapılmakta,
                            verilere erişim yetkisine sahip kullanıcıların,
                            yetki kapsamları ve süreleri net olarak
                            tanımlanmakta, yetki kontrolleri
                            gerçekleştirilmekte, görev değişikliği olan ya da
                            işten ayrılan çalışanların bu alandaki yetkilerinin
                            derhal kaldırılmakta ve bu kapsamda, veri sorumlusu
                            tarafından kendisine tahsis edilen envanter iade
                            alınmaktadır.
                          </li>
                          <li>
                            Özel nitelikli kişisel verilerin işlendiği, muhafaza
                            edildiği ve/veya erişildiği ortamlar, elektronik
                            ortam ise veriler kriptografik yöntemler
                            kullanılarak muhafaza edilmektedir. Kriptografik
                            anahtarlar güvenli ve farklı ortamlarda tutulmakta
                            veriler üzerinde gerçekleştirilen tüm hareketlerin
                            işlem kayıtları güvenli olarak loglanmakta,
                            verilerin bulunduğu ortamlara ait güvenlik
                            güncellemeleri takip edilmekte ve gerekli güvenlik
                            testleri yapılmakta, test sonuçları kayıt altına
                            alınmaktadır.
                          </li>
                          <li>
                            Verilere bir yazılım aracılığı ile erişilmesi
                            durumunda bu yazılıma ait kullanıcı
                            yetkilendirmeleri yapılmakta, bu yazılımların
                            güvenlik testleri düzenli olarak yapılmakta, test
                            sonuçları kayıt altına alınmaktadır. Verilere
                            uzaktan erişim gerekiyorsa en az iki kademeli kimlik
                            doğrulama sistemi sağlanmaktadır.
                          </li>
                          <li>
                            Özel nitelikli kişisel verilerin işlendiği, muhafaza
                            edildiği ve/veya erişildiği ortamlar, fiziksel ortam
                            ise, özel nitelikli kişisel verilerin bulunduğu
                            ortamın niteliğine göre yeterli güvenlik önlemleri
                            (elektrik kaçağı, yangın, su baskını, hırsızlık vb.
                            durumlara karşı) alınmakta, bu ortamların fiziksel
                            güvenliği sağlanarak yetkisiz giriş çıkışlar
                            engellenmektedir.
                          </li>
                          <li>
                            Özel nitelikli kişisel veriler aktarılacaksa,
                            verilerin e-posta yoluyla aktarılması gerekiyorsa
                            şifreli olarak kurumsal e-posta adresiyle veya
                            Kayıtlı Elektronik Posta (KEP) hesabı kullanılarak
                            aktarılması sağlanmaktadır.
                          </li>
                          <li>
                            Özel nitelikli kişisel veriler, Bellek, CD, DVD gibi
                            ortamlar yoluyla aktarılması gerekiyorsa
                            kriptografikyöntemlerle şifrelenmekte ve
                            kriptografik anahtar farklı ortamda tutulmaktadır.
                          </li>
                          <li>
                            Özel nitelikli kişisel veriler, farklı fiziksel
                            ortamlardaki sunucular arasında aktarma
                            gerçekleştiriliyorsa, sunucular arasında VPN
                            kurularak veya sFTP yöntemiyle veri aktarımı
                            gerçekleştirilmektedir. Özel verilerin kâğıt ortamı
                            yoluyla aktarımı gerekiyorsa evrakın çalınması,
                            kaybolması ya da yetkisiz kişiler tarafından
                            görülmesi gibi risklere karşı gerekli önlemler
                            alınmakta ve evrak{' '}
                            <b>“gizlilik dereceli belgeler”</b> formatında
                            gönderilmektedir.
                          </li>
                          <li>
                            Yukarıda belirtilen önlemlerin yanı sıra Kişisel
                            Verileri Koruma Kurumunun internet sitesinde
                            yayımlanan Kişisel Veri Güvenliği Rehberinde
                            belirtilen uygun güvenlik düzeyini temin etmeye
                            yönelik teknik ve idari tedbirler de dikkate
                            alınmaktadır.
                          </li>
                        </ul>

                        <li className="legal-heading-2">
                          KİŞİSEL VERİLERİN HUKUKA AYKIRI ERİŞİMİNİ ENGELLEMEK
                          İÇİN ALINAN TEKNİK VE İDARİ TEDBİRLER
                          <ol>
                            <li className="legal-heading-2">
                              Kişisel Verilerin Hukuka Aykırı Erişimini
                              Engellemek için Alınan Teknik Tedbirler
                              <p className="legal-text align-justify ">
                                Şirketimiz, kişisel verilerin tedbirsizlikle
                                veya yetkisiz olarak açıklanmasını, erişimini,
                                aktarılmasını veya başka şekillerdeki tüm hukuka
                                aykırı erişimi önlemek için teknik ve idari
                                tedbirler almaktadır.
                              </p>
                              <ol type="A">
                                <li className="legal-heading-2">
                                  Siber Güvenliğin Sağlanması
                                </li>
                                <p className="legal-text align-justify">
                                  Kişisel veri güvenliğinin sağlanması için
                                  öncelikle siber güvenlik ürünleri
                                  kullanılmakta ancak tedbirler bununla sınırlı
                                  bırakılmamaktadır. Güvenlik duvarı ve ağ
                                  geçidi gibi tedbirler alınmaktadır.
                                  Kullanılmayan yazılım ve servisler cihazlardan
                                  kaldırılmaktadır.
                                </p>
                                <li className="legal-heading-2">
                                  Yazılım güncellemeleri
                                </li>
                                <p className="legal-text align-justify">
                                  Yama yönetimi ve yazılım güncellemeleri ile
                                  yazılım ve donanımların düzgün bir şekilde
                                  çalışması ve sistemler için alınan güvenlik
                                  tedbirlerinin yeterli olup olmadığının düzenli
                                  olarak kontrol edilmesi sağlanmaktadır.
                                </p>
                                <li className="legal-heading-2">
                                  Erişim Sınırlamaları
                                </li>
                                <p className="legal-text align-justify">
                                  Kişisel veri içeren sistemlere erişim de
                                  sınırlandırılmaktadır. Bu kapsamda
                                  çalışanlara, yapmakta oldukları iş ve görevler
                                  ile yetki ve sorumlulukları için gerekli
                                  olduğu ölçüde erişim yetkisi tanınmakta ve
                                  kullanıcı adı ve şifre kullanılmak suretiyle
                                  ilgili sistemlere erişim sağlanmaktadır. Söz
                                  konusu şifre ve parolalar oluşturulurken,
                                  kişisel bilgilerle ilişkili ve kolay tahmin
                                  edilecek rakam ya da harf dizileri yerine
                                  büyük küçük harf, rakam ve sembollerden
                                  oluşacak kombinasyonların tercih edilmesi
                                  sağlanmaktadır. Buna bağlı olarak, erişim
                                  yetki ve kontrol matrisi oluşturulmaktadır.
                                </p>
                                <li className="legal-heading-2">Şifreleme</li>
                                <p className="legal-text align-justify">
                                  Güçlü şifre ve parola kullanımının yanı sıra,
                                  şifre girişi deneme sayısının
                                  sınırlandırılması, düzenli aralıklarla şifre
                                  ve parolaların değiştirilmesinin sağlanması,
                                  yönetici hesabı ve admin yetkisinin sadece
                                  ihtiyaç olduğu durumlarda kullanılması için
                                  açılması ve veri sorumlusuyla ilişikleri
                                  kesilen çalışanlar için zaman kaybetmeksizin
                                  hesabın silinmesi ya da girişlerin kapatılması
                                  gibi yöntemlerle erişimin sınırlandırılması
                                  yapılmaktadır.
                                </p>
                                <li className="legal-heading-2">
                                  Anti Virüs Yazılımları
                                </li>
                                <p className="legal-text align-justify">
                                  Kötü amaçlı yazılımlardan korunmak için
                                  ayrıca, bilgi sistem ağını düzenli olarak
                                  tarayan ve tehlikeleri tespit eden antivirüs,
                                  antispamgibi ürünler kullanılmakta, ayrıca
                                  bunlar güncel tutularak gereken dosyalar
                                  düzenli olarak taranmaktadır. Farklı internet
                                  siteleri ve/veya mobil uygulama kanallarından
                                  kişisel veri temin edilecekse, bağlantıların
                                  SSL ya da daha güvenli bir yol ile
                                  gerçekleştirilmesi sağlanmaktadır.
                                </p>
                                <li className="legal-heading-2 mb-0">
                                  Kişisel Veri Güvenliğinin Takibi
                                </li>
                                <ul className="list-text list-style-circle legal-text">
                                  <li>
                                    Bilişim ağlarında hangi yazılım ve
                                    servislerin çalıştığının kontrol edilmesi,
                                  </li>
                                  <li>
                                    Bilişim ağlarında sızma veya olmaması
                                    gereken bir hareket olup olmadığının
                                    belirlenmesi,
                                  </li>
                                  <li>
                                    Tüm kullanıcıların işlem hareketleri
                                    kaydının düzenli olarak tutulması (log
                                    kayıtları gibi),
                                  </li>
                                  <li>
                                    Güvenlik sorunlarının mümkün olduğunca hızlı
                                    bir şekilde raporlanması,
                                  </li>
                                </ul>
                                <p className="legal-text align-justify">
                                  Gerçekleştirilmekte, çalışanların sistem ve
                                  servislerdeki güvenlik zafiyetlerini ya da
                                  bunları kullanan tehditleri bildirmesi için
                                  resmi bir raporlama prosedürü
                                  oluşturulmaktadır.
                                  <br />
                                  Bilişim sisteminin çökmesi, kötü niyetli
                                  yazılım, servis dışı bırakma saldırısı, eksik
                                  veya hatalı veri girişi, gizlilik ve bütünlüğü
                                  bozan ihlaller, bilişim sisteminin kötüye
                                  kullanılması gibi istenmeyen olaylarda
                                  deliller toplanmakta ve güvenli bir şekilde
                                  saklanmaktadır.
                                </p>
                                <li className="legal-heading-2">
                                  Kişisel Veri İçeren Ortamların Güvenliğinin
                                  Sağlanması
                                </li>
                                <p className="legal-text align-justify">
                                  Kişisel veriler, veri sorumlularının
                                  yerleşkelerinde yer alan cihazlarda ya da
                                  kâğıt ortamında saklanıyor ise, bu cihazların
                                  ve kağıtların çalınması veya kaybolması gibi
                                  tehditlere karşı fiziksel güvenlik önlemleri
                                  alınmaktadır. Kişisel verilerin yer aldığı
                                  fiziksel ortamların dış risklere (yangın, sel
                                  vb.) karşı uygun yöntemlerle korunmakta ve bu
                                  ortamlara giriş / çıkışlar kontrol altına
                                  alınmaktadır.
                                  <br />
                                  <br />
                                  Kişisel veriler elektronik ortamda ise,
                                  kişisel veri güvenliği ihlalini önlemek için
                                  ağ bileşenleri arasında erişim
                                  sınırlandırılabilmekte veya bileşenlerin
                                  ayrılması sağlanmaktadır.
                                  <br />
                                  <br />
                                  Aynı seviyedeki önlemler Şirket yerleşkesi
                                  dışında yer alan ve Şirket’e ait kişisel veri
                                  içeren kâğıt ortamları, elektronik ortam ve
                                  cihazlar (dizüstü bilgisayar, cep telefonu,
                                  flaş bellekler) için de alınmaktadır.
                                  Elektronik posta ya da posta ile aktarılacak
                                  kişisel verilerin de dikkatli bir şekilde ve
                                  yeterli tedbirler alınarak gönderilmektedir.
                                  <br />
                                  <br />
                                  Çalışanların şahsi elektronik cihazları ile
                                  bilgi sistem ağına erişim sağlaması durumunda
                                  bunlar için de yeterli güvenlik tedbirleri
                                  alınmaktadır.
                                  <br />
                                  <br />
                                  Kişisel veri içeren cihazların kaybolması veya
                                  çalınması gibi durumlara karşı erişim kontrol
                                  yetkilendirmesi ve/veya şifreleme
                                  yöntemlerinin kullanılması yöntemi
                                  uygulanmaktadır. Bu kapsamda şifre anahtarı,
                                  sadece yetkili kişilerin erişebileceği ortamda
                                  saklanmakta ve yetkisiz erişim önlenmektedir.
                                  <br />
                                  <br />
                                  Kişisel veri içeren kâğıt ortamındaki evraklar
                                  da kilitli bir şekilde ve sadece yetkili
                                  kişilerin erişebileceği ortamlarda
                                  saklanmakta, söz konusu evraklara yetkisiz
                                  erişim önlenmektedir.
                                </p>
                                <li className="legal-heading-2">
                                  Kişisel Verilerin Bulutta Depolanması
                                </li>
                                <p className="legal-text align-justify">
                                  Kişisel verilerin bulutta depolanması
                                  uygulamalarına da gerektiğinde
                                  başvurulabilmektedir. Bu durumda, bulut
                                  depolama hizmeti sağlayıcısı tarafından alınan
                                  güvenlik önlemlerinin de yeterli ve uygun olup
                                  olmadığının Şirket tarafından
                                  değerlendirilmesi gerekmektedir. Bu kapsamda,
                                  Kurul’un rehber ve tavsiyelerinde belirtilen
                                  önlemler dikkate alınmaktadır.
                                </p>
                                <li className="legal-heading-2">
                                  Bilgi Teknolojileri Sistemleri Tedariki,
                                  Geliştirme ve Bakımı
                                </li>
                                <p className="legal-text align-justify">
                                  Şirket tarafından yeni sistemlerin tedariki,
                                  geliştirilmesi veya mevcut sistemlerin
                                  iyileştirilmesi ile ilgili ihtiyaçlar
                                  belirlenirken güvenlik gereksinimleri göz
                                  önüne alınmaktadır.
                                </p>
                                <li className="legal-heading-2">
                                  Kişisel Verilerin Yedeklenmesi
                                </li>
                                <p className="legal-text align-justify">
                                  Kişisel verilerin herhangi bir sebeple zarar
                                  görmesi, yok olması, çalınması veya kaybolması
                                  gibi hallerde Şirket yedeklenen verileri
                                  kullanarak en kısa sürede faaliyete geçmeyi
                                  sağlamaktadır. Yedeklenen kişisel veriler
                                  sadece sistem yöneticisi tarafından
                                  erişilebilir olup, veri seti yedekleri ağ
                                  dışında tutulmaktadır.
                                </p>
                              </ol>
                            </li>
                            <li className="legal-heading-2">
                              Kişisel Verilerin Hukuka Aykırı Erişimini
                              Engellemek için Alınan İdari Tedbirler
                            </li>
                            <p className="legal-text align-justify mb-0">
                              Şirket’imiz tarafından kişisel verilerin hukuka
                              aykırı erişimini engellemek için alınan başlıca
                              idari tedbirler aşağıda sıralanmaktadır:
                            </p>
                            <ul className="list-text list-style-tick legal-text mb-32">
                              <li>
                                Çalışanlar, kişisel verilere hukuka aykırı
                                erişimi engellemek için alınacak teknik
                                tedbirler konusunda bilgilendirilmekte ve
                                eğitilmektedir.
                              </li>
                              <li>
                                Çalışanlar, öğrendikleri kişisel verileri Kanun
                                hükümlerine aykırı olarak başkasına
                                açıklayamayacağı ve işleme amacı dışında
                                kullanamayacağı ve bu yükümlülüğün görevden
                                ayrılmalarından sonra da devam edeceği konusunda
                                bilgilendirilmekte ve bu doğrultuda
                                kendilerinden gerekli taahhütler alınmaktadır.
                              </li>
                              <li>
                                Kişisel Veri Güvenliği Politikalarının ve
                                Prosedürler belirlenmekte, politika ve
                                prosedürler kapsamında; düzenli olarak
                                kontroller yapılmakta, yapılan kontroller
                                belgelenmekte, geliştirilmesi gereken hususlar
                                belirlenmektedir. Yine, her kişisel veri
                                kategorisi için ortaya çıkabilecek riskler ile
                                güvenlik ihlallerinin nasıl yönetileceği de
                                açıkça belirlenmektedir.
                              </li>
                              <li>
                                Kişisel Verilerin Mümkün Olduğunca Azaltılması:
                                Kişisel veriler, doğru ve güncel olmalı, ilgili
                                mevzuatta öngörülen veya işlendikleri amaç için
                                gerekli olan süre kadar muhafaza edilmelidir.
                                Ancak, doğru olmayan, güncelliğini yitirmiş ve
                                herhangi bir amaca hizmet etmeyen verilere hala
                                ihtiyaç olup olmadığının değerlendirilmekte ve
                                ihtiyaç duyulmayan kişisel veriler ise kişisel
                                veri saklama ve imha politikası ile silinmekte,
                                yok edilmekte veya anonim hale getirilmektedir.
                              </li>
                              <li>
                                Veri İşleyenler ile İlişkilerin Yönetimi: Şirket
                                Bilgi Teknolojileri ihtiyacını karşılamak için
                                veri işleyenlerden hizmet aldığı zaman, hizmet
                                alırken söz konusu veri işleyenlerin kişisel
                                veriler konusunda en az kendileri tarafından
                                sağlanan güvenlik seviyesini sağlandığından emin
                                olarak işlem yapılmaktadır. Bu kapsamda, veri
                                işleyen ile imzalanan sözleşmelere kişisel
                                verilerin korunması ile ilgili koruyucu
                                düzenlemeler getirilmektedir.
                              </li>
                            </ul>
                          </ol>
                        </li>
                        <li className="legal-heading-2">
                          KİŞİSEL VERİLERİN GÜVENLİ ORTAMLARDA SAKLANMASI
                          <p className="legal-text align-justify ">
                            Şirket’imiz, kişisel verilerin güvenli ortamlarda
                            saklanması ve hukuka aykırı amaçlarla yok
                            edilmesini, kaybolmasını veya değiştirilmesini
                            önlemek için teknolojik imkânlar ve uygulama
                            maliyetine göre gerekli teknik ve idari tedbirleri
                            almaktadır.
                          </p>
                          <ol>
                            <li className="legal-heading-2">
                              Kişisel Verilerin Güvenli Ortamlarda Saklanması
                              için Alınan Teknik Tedbirler
                            </li>
                            <p className="legal-text align-justify mb-0">
                              Şirket’imiz tarafından kişisel verilerin güvenli
                              ortamlarda saklanması için alınan başlıca teknik
                              tedbirler aşağıda sıralanmaktadır:
                            </p>
                            <ul className="list-text list-style-tick legal-text mb-32">
                              <li>
                                Kişisel verilerin güvenli ortamlarda saklanması
                                için teknolojik gelişmelere uygun sistemler
                                kullanılmaktadır.
                              </li>
                              <li>
                                Saklanma alanlarına yönelik teknik güvenlik
                                sistemleri kurulmakta, alınan teknik önlemler
                                periyodik olarak Şirket’imizce belirlenen
                                denetim mekanizması tarafından denetlenmekte,
                                risk teşkil eden hususlar yeniden
                                değerlendirilerek gerekli teknolojik çözüm
                                üretilmektedir.
                              </li>
                              <li>
                                Kişisel verilerin güvenli bir biçimde
                                saklanmasını sağlamak için hukuka uygun bir
                                biçimde tüm gerekli altyapılar kullanılmaktadır.
                              </li>
                            </ul>
                            <li className="legal-heading-2">
                              Kişisel Verilerin Güvenli Ortamlarda Saklanması
                              için Alınan İdari Tedbirler
                            </li>
                            <p className="legal-text align-justify mb-0">
                              Şirket’imiz tarafından kişisel verilerin güvenli
                              ortamlarda saklanması için alınan başlıca idari
                              tedbirler aşağıda sıralanmaktadır:
                            </p>
                            <ul className="list-text list-style-tick legal-text mb-32">
                              <li>
                                Çalışanlar, kişisel verilerin güvenli bir
                                biçimde saklanmasını sağlamak konusunda
                                bilgilendirilmektedirler.
                              </li>
                              <li>
                                Şirket’imiz tarafından kişisel verilerin
                                saklanması konusunda teknik gereklilikler
                                sebebiyle dışarıdan bir hizmet alınması
                                durumunda, kişisel verilerin hukuka uygun olarak
                                aktarıldığı ilgili firmalar ile akdedilen
                                sözleşmelere; kişisel verilerin aktarıldığı
                                kişilerin, kişisel verilerin korunması amacıyla
                                gerekli güvenlik tedbirlerini alacağına ve kendi
                                kuruluşlarında bu tedbirlere uyulmasını
                                sağlanacağına ilişkin hükümlere yer verilmekte
                                ve bu konuda Şirketin{' '}
                                <b>
                                  Üçüncü Taraflarla Gizlilik ve Kişisel
                                  Verilerin Korunması Prosedür
                                </b>
                                ’ünde yer alan hükümlere göre hareket
                                edilmektedir.
                              </li>
                            </ul>
                          </ol>
                        </li>
                        <li className="legal-heading-2 mb-0">EĞİTİM</li>
                        <ul className="list-text list-style-tick legal-text mb-32">
                          <li>
                            Şirket’imiz, Kişisel Verilerin korunması konusunda
                            çalışanlarına Politika ve Kişisel Verilerin
                            Korunması Prosedürleri ile Kişisel Verilerin
                            Korunması Düzenlemeleri kapsamında gerekli
                            eğitimleri verilmektedir.
                          </li>
                          <li>
                            Eğitimlerde Özel Nitelikli Kişisel Verilerin
                            tanımlarına ve korunmasına yönelik uygulamalara
                            özellikle değinilmektedir.
                          </li>
                          <li>
                            Şirket’imiz çalışanı Kişisel Verilere fiziksel
                            olarak veya bilgisayar ortamında erişiyorsa,
                            Şirketimiz ilgili çalışanına bu erişimler özelinde
                            (örneğin erişilen bilgisayar programı) eğitim
                            verilmektedir.
                          </li>
                        </ul>
                        <li className="legal-heading-2">
                          DENETİM
                          <ol>
                            <li className="legal-heading-2">
                              İş Birimlerinin Kişisel Verilerin Korunması ve
                              İşlenmesi Konusunda Farkındalıklarının
                              Arttırılması ve Denetimi
                            </li>
                            <p className="legal-text align-justify">
                              Şirketimiz, kişisel verilerin hukuka aykırı olarak
                              işlenmesini, verilere hukuka aykırı olarak
                              erişilmesini önlemeye ve verilerin muhafazasını
                              sağlamaya yönelik farkındalığın artırılması için
                              iş birimlerine gerekli bildirimlerin yapılmasını
                              sağlamaktadır.
                            </p>
                            <li className="legal-heading-2">
                              İş Ortakları ve Tedarikçilerin Kişisel Verilerin
                              Korunması ve İşlenmesi Konusundaki
                              Farkındalıklarının Arttırılması ve Denetimi
                            </li>
                            <p className="legal-text align-justify">
                              Şirketimiz kişisel verilerin hukuka aykırı olarak
                              işlenmesinin önlenmesi, verilere hukuka aykırı
                              olarak erişilmesinin önlenmesi ve verilerin
                              muhafazasını sağlamaya yönelik farkındalığın
                              artırılması için iş ortaklarına gerekli
                              bilgilendirmeler yapmaktadır.
                            </p>
                            <li className="legal-heading-2">
                              Kişisel Verilerin Korunması Konusunda Alınan
                              Tedbirlerin Denetimi
                            </li>
                            <p className="legal-text align-justify">
                              Şirket’imiz, işbu Politika ve Kişisel Verilerin
                              Korunması düzenlemelerine Şirketi’n tüm
                              çalışanları, departmanları ve yüklenicilerinin
                              uygun hareket ettiğini düzenli olarak hiçbir ön
                              bildirimde bulunmaksızın her zaman ve
                              re’sendenetleme hakkını haizdir ve bu kapsamda
                              gerekli rutin denetimleri yapmakta veya
                              yaptırmaktadır. Bu denetim sonuçları Şirket’in iç
                              işleyişi kapsamında değerlendirilir ve alınan
                              tedbirlerin iyileştirilmesi için gerekli
                              faaliyetler yürütülmektedir.
                            </p>
                            <li className="legal-heading-2">
                              Kişisel Verilerin Yetkisiz Bir Şekilde İfşası
                              Durumunda Alınacak Tedbirler
                            </li>
                            <p className="legal-text align-justify">
                              Şirket’imiz, Kanun’un 12. maddesine uygun olarak
                              işlenen kişisel verilerin kanuni olmayan yollarla
                              başkaları tarafından elde edilmesi halinde bu
                              durumu en kısa sürede ilgili kişisine ve Kurul’a
                              bildirilmesini sağlayan sistemi yürütmektedir.
                            </p>
                          </ol>
                        </li>
                      </ol>
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

import React from 'react'

export const Faq = () => {
  const handleClick = (event) => {
    event.preventDefault()

    const accordions = Array.from(document.querySelectorAll('.collapse'))
    accordions.map((accordion) => {
      accordion.classList.remove('show')
    })

    const allWithClass = Array.from(document.querySelectorAll('.option-button'))
    const allTabWithClass = Array.from(document.querySelectorAll('.tab-pane'))

    allWithClass.map((item) => {
      item.classList.remove('active')
    })

    allTabWithClass.map((item) => {
      item.classList.remove('active', 'show')
    })

    const selected = document.getElementById('select' + event.target.value)
    selected?.classList.add('active')
    const selectedtab = document.getElementById(
      'selecttab' + event.target.value
    )
    selectedtab?.classList.add('show', 'active')
  }

  return (
    <section>
      <div className="container">
        <div className="row">
          <div className="col">
            <h2 className="faq-title mb-0">Sıkça Sorulan Sorular</h2>

            <div className="row">
              <div className="d-sm-flex mt-30">
                <div
                  className="nav nav-tabs flex-column nav-pills nav-pills-width faq-dropdown-collapse-margin"
                  id="v-pills-tab"
                  role="tablist"
                  aria-orientation="vertical"
                >
                  <div className="d-none d-sm-none d-md-block">
                    <button
                      onClick={handleClick}
                      className="nav-link active faq-button me-3"
                      id="v-pills-home-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#v-pills-home"
                      type="button"
                      role="tab"
                      aria-controls="v-pills-home"
                      aria-selected="true"
                      value={1}
                    >
                      Genel
                    </button>
                    <button
                      onClick={handleClick}
                      className="nav-link faq-button me-3"
                      id="v-pills-profile-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#v-pills-profile"
                      type="button"
                      role="tab"
                      aria-controls="v-pills-profile"
                      aria-selected="false"
                      value={2}
                    >
                      İş Ortakları ve Yatırımcılar
                    </button>
                    <button
                      onClick={handleClick}
                      className="nav-link faq-button me-3"
                      id="v-pills-messages-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#v-pills-messages"
                      type="button"
                      role="tab"
                      aria-controls="v-pills-messages"
                      aria-selected="false"
                      value={3}
                    >
                      Para Transferleri
                    </button>
                    <button
                      onClick={handleClick}
                      className="nav-link faq-button me-3"
                      id="v-pills-messages-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#v-pills-messages"
                      type="button"
                      role="tab"
                      aria-controls="v-pills-messages"
                      aria-selected="false"
                      value={4}
                    >
                      Hesaplar
                    </button>
                    <button
                      onClick={handleClick}
                      className="nav-link faq-button me-3"
                      id="v-pills-messages-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#v-pills-messages"
                      type="button"
                      role="tab"
                      aria-controls="v-pills-messages"
                      aria-selected="false"
                      value={6}
                    >
                      Hesap İşlemleri ve Güvenlik
                    </button>
                    <button
                      onClick={handleClick}
                      className="nav-link faq-button me-3"
                      id="v-pills-messages-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#v-pills-messages"
                      type="button"
                      role="tab"
                      aria-controls="v-pills-messages"
                      aria-selected="false"
                      value={7}
                    >
                      Mundi Hakkında
                    </button>
                    <button
                      onClick={handleClick}
                      className="nav-link faq-button me-3"
                      id="v-pills-messages-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#v-pills-messages"
                      type="button"
                      role="tab"
                      aria-controls="v-pills-messages"
                      aria-selected="false"
                      value={8}
                    >
                      Şirketin için Mundi
                    </button>
                  </div>

                  <div className="col-sm-12 d-md-none mx-auto ">
                    <select
                      className="form-select d-md-none text-center"
                      aria-label="Default select example"
                      onChange={handleClick}
                    >
                      <option value="1">
                        {' '}
                        <button
                          className="nav-link active faq-button option-button me-3"
                          id="select1"
                          data-bs-toggle="pill"
                          data-bs-target="#v-pills-messages"
                          type="button"
                          role="tab"
                          aria-controls="v-pills-messages"
                          aria-selected="false"
                        >
                          Genel
                        </button>
                      </option>
                      <option value="2">
                        <button
                          className="nav-link faq-button option-button me-3"
                          id="select2"
                          data-bs-toggle="pill"
                          data-bs-target="#v-pills-profile"
                          type="button"
                          role="tab"
                          aria-controls="v-pills-profile"
                          aria-selected="false"
                        >
                          İş Ortakları ve Yatırımcılar
                        </button>
                      </option>
                      <option value="3">
                        <button
                          className="nav-link faq-button option-button me-3"
                          id="select3"
                          data-bs-toggle="pill"
                          data-bs-target="#v-pills-messages"
                          type="button"
                          role="tab"
                          aria-controls="v-pills-messages"
                          aria-selected="false"
                        >
                          Para Transferleri
                        </button>
                      </option>
                      <option value="4">
                        <button
                          className="nav-link faq-button option-button me-3"
                          id="select3"
                          data-bs-toggle="pill"
                          data-bs-target="#v-pills-messages"
                          type="button"
                          role="tab"
                          aria-controls="v-pills-messages"
                          aria-selected="false"
                        >
                          Hesaplar
                        </button>
                      </option>
                      <option value="6">
                        <button
                          className="nav-link faq-button option-button me-3"
                          id="select3"
                          data-bs-toggle="pill"
                          data-bs-target="#v-pills-messages"
                          type="button"
                          role="tab"
                          aria-controls="v-pills-messages"
                          aria-selected="false"
                        >
                          Hesap İşlemleri ve Güvenlik
                        </button>
                      </option>
                      <option value="7">
                        <button
                          className="nav-link faq-button option-button me-3"
                          id="select3"
                          data-bs-toggle="pill"
                          data-bs-target="#v-pills-messages"
                          type="button"
                          role="tab"
                          aria-controls="v-pills-messages"
                          aria-selected="false"
                        >
                          Mundi Hakkında
                        </button>
                      </option>
                      <option value="8">
                        <button
                          className="nav-link faq-button option-button me-3"
                          id="select3"
                          data-bs-toggle="pill"
                          data-bs-target="#v-pills-messages"
                          type="button"
                          role="tab"
                          aria-controls="v-pills-messages"
                          aria-selected="false"
                        >
                          Şirketin için Mundi
                        </button>
                      </option>
                    </select>
                  </div>
                </div>

                <div className="col-sm-8 tab-content" id="v-pills-tabContent">
                  <div
                    className="tab-pane fade show active"
                    id="selecttab1"
                    role="tabpanel"
                    aria-labelledby="v-pills-home-tab"
                  >
                    <div
                      className="accordion mt-50-collapse-30 mb-60"
                      id="accordionExample"
                    >
                      <div className="faq-collapse-body">
                        <span id="headingOne">
                          <button
                            className="accordion-button collapsed bg1"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseOne"
                            aria-expanded="false"
                            aria-controls="collapseOne"
                          >
                            <strong className="faq-message-title">
                              Mundi nedir?
                            </strong>
                          </button>
                        </span>
                        <div
                          id="collapseOne"
                          className="collapse bg3"
                          aria-labelledby="headingOne"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="faq-message-text">
                            <p>
                              Mundi, TL ve USD birikimlerini sermaye piyasası
                              ürünlerinde değerlendirmeni ve avantajlı oranlarla
                              nemalandırmanı sağlar.
                            </p>
                            <p>
                              Çok yakında Türkiye Cumhuriyeti Hazinesi ve
                              bankaların Eurobondlarına Mundi üzerinden yatırım
                              yapabileceksin!
                            </p>
                            <p>
                              Mundi kullanıcıları, paylar, VİOP kontratları,
                              yatırım fonu ve varant gibi sermaye piyasası
                              ürünlerine mevcut kullanıcı bilgileri ile Piapiri
                              uygulamasına giriş yaparak kolayca yatırım
                              yapabilirler.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="faq-collapse-body">
                        <span id="headingTwo">
                          <button
                            className="accordion-button collapsed bg1"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseTwo"
                            aria-expanded="false"
                            aria-controls="collapseTwo"
                          >
                            <strong className="faq-message-title">
                              Mundi nasıl çalışır?
                            </strong>
                          </button>
                        </span>
                        <div
                          id="collapseTwo"
                          className="accordion-collapse collapse"
                          aria-labelledby="headingTwo"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="faq-message-text">
                            <p>
                              Üye olduktan sonra Mundi uygulaması üzerinden ÜNLÜ
                              Menkul Değerler A.Ş. nezdinde yatırım hesabı
                              açarsın. Banka hesaplarından dilediğin tutarı
                              yatırım hesabına aktarırsın.
                            </p>
                            <p>
                              Mundi üzerinden gerçekleştirdiğin bütün yatırım
                              işlemleri ÜNLÜ Menkul Değerler A.Ş. bünyesinde
                              gerçekleşmektedir. ÜNLÜ Menkul Değerler A.Ş.
                              Sermaye Piyasası Kurulu (SPK) ve Mali Suçları
                              Araştırma Kurulu (MASAK) denetimine tabi bir aracı
                              kurumdur.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="faq-collapse-body">
                        <span id="headingThree">
                          <button
                            className="accordion-button collapsed bg1"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseThree"
                            aria-expanded="false"
                            aria-controls="collapseThree"
                          >
                            <strong className="faq-message-title">
                              Mundi neden güvenlidir?
                            </strong>
                          </button>
                        </span>
                        <div
                          id="collapseThree"
                          className="accordion-collapse collapse"
                          aria-labelledby="headingThree"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="faq-message-text">
                            <p>
                              Mundi’ye üye olduktan sonra açtığın tüm yatırım
                              hesapları Sermaye Piyasası Kurulu (SPK) ve Mali
                              Suçları Araştırma Kurulu (MASAK) denetimine tabi
                              bir aracı kurum bünyesinde açılmaktadır.
                            </p>
                            <p>
                              Ayrıca yatırım hesabındaki sermaye piyasası
                              yatırımların Yatırımcı Tazmin Merkezi (YTM)
                              sayesinde devlet güvencesi altındadır. Yatırımcı
                              Tazmin Merkezi (YTM) güvencesiyle 2024 yılı için
                              birikimin 1.143.379 TL’ye kadar tazmin
                              kapsamındadır. Bu tutar her yıl ilan edilen
                              yeniden değerleme katsayısı oranında artırılır.
                            </p>
                            <p>
                              Mundi Kart altyapısı 6493 sayılı kanun ve ilgili
                              mevzuata uygun şekilde Sipay Elektronik Para ve
                              Ödeme Hizmetleri A.Ş. tarafından sağlanmaktadır.
                            </p>
                            <p>
                              Sipay Elektronik Para ve Ödeme Hizmetleri A.Ş.,
                              Türkiye Cumhuriyet Merkez Bankası (TCMB) lisanslı,
                              6493 sayılı kanun çerçevesinde hizmet veren bir
                              elektronik para ve ödeme kuruluşudur.
                            </p>
                            <p>
                              Mundi, senden aldığı bilgileri resmi kurumlar ve
                              iş ortakları haricinde üçüncü şahıs ve kurumlarla
                              asla paylaşmaz.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="faq-collapse-body">
                        <span id="headingFour">
                          <button
                            className="accordion-button collapsed bg1"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseFour"
                            aria-expanded="false"
                            aria-controls="collapseFour"
                          >
                            <strong className="faq-message-title">
                              Mundi{"'"}ye nasıl üye olabilirim?
                            </strong>
                          </button>
                        </span>
                        <div
                          id="collapseFour"
                          className="accordion-collapse collapse"
                          aria-labelledby="headingFour"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="faq-message-text">
                            <p>
                              Birkaç adımda Mundi hesabını oluşturup
                              birikimlerini değerlendirmeye başlayabilirsin.
                              <br />
                              1.{' '}
                              <a
                                href="https://apps.apple.com/tr/app/mudi-app/id6443530236"
                                target="_blank"
                                rel="noreferrer"
                              >
                                App Store
                              </a>{' '}
                              veya{' '}
                              <a
                                href="https://play.google.com/store/apps/details?id=com.mudi.android"
                                target="_blank"
                                rel="noreferrer"
                              >
                                Google Play
                              </a>
                              ’den mobil uygulamayı indir.
                              <br />
                              2. Üyelik ve hesap açma işlemlerin için
                              yönlendirmeleri takip ederek gerekli bilgileri
                              tamamla.
                            </p>
                            <p>
                              Mundi üzerinden aracı kurumda yatırım hesabı açmak
                              istersen uygulamadan ilgili adımları takip
                              edebilirsin.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="faq-collapse-body">
                        <span id="headingFive">
                          <button
                            className="accordion-button collapsed bg1"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseFive"
                            aria-expanded="false"
                            aria-controls="collapseFive"
                          >
                            <strong className="faq-message-title">
                              Mundi’yi kimler kullanabilir?
                            </strong>
                          </button>
                        </span>
                        <div
                          id="collapseFive"
                          className="accordion-collapse collapse"
                          aria-labelledby="headingFive"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="faq-message-text">
                            <p>
                              Türkiye’de ikamet eden, 18 yaşından büyük Türkiye
                              Cumhuriyeti vatandaşları, Mavi Kart sahipleri ve
                              çok vatandaşlığa sahip Türk vatandaşları Mundi’yi
                              kullanabilirler.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="faq-collapse-body">
                        <span id="headingTen">
                          <button
                            className="accordion-button collapsed bg1"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseTen"
                            aria-expanded="false"
                            aria-controls="collapseTen"
                          >
                            <strong className="faq-message-title">
                              Bilgim dışında herhangi bir işlem gerçekleşir mi?
                            </strong>
                          </button>
                        </span>
                        <div
                          id="collapseTen"
                          className="accordion-collapse collapse"
                          aria-labelledby="headingTen"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="faq-message-text">
                            <p>
                              Yatırım hesabında talimatın olmadan hiçbir işlem
                              yapılmaz. Yetkili kurumlar nezdinde saklanan
                              birikimlerinin tüm kontrolü her zaman sendedir.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="selecttab2"
                    role="tabpanel"
                    aria-labelledby="v-pills-profile-tab"
                  >
                    <div
                      className="accordion mt-50-collapse-30 mb-60"
                      id="accordionExampleTwo"
                    >
                      <div className="faq-collapse-body">
                        <span id="headingOne">
                          <button
                            className="accordion-button collapsed bg1"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseOne"
                            aria-expanded="false"
                            aria-controls="collapseOne"
                          >
                            <strong className="faq-message-title">
                              ÜNLÜ Menkul nedir? Yatırım hesabım hangi aracı
                              kurumda açılacak?
                            </strong>
                          </button>
                        </span>
                        <div
                          id="collapseOne"
                          className="collapse bg3"
                          aria-labelledby="headingOne"
                          data-bs-parent="#accordionExampleTwo"
                        >
                          <div className="faq-message-text">
                            <p>
                              ÜNLÜ Menkul Değerler A.Ş, Sermaye Piyasası Kurulu
                              (SPK) ve Mali Suçları Araştırma Kurulu (MASAK)
                              denetimine tabi bir aracı kurumdur. Mundi
                              üzerinden kullandığın yatırım hesapların
                              partnerimiz olan ÜNLÜ Menkul bünyesinde açılır.
                            </p>
                            <p>
                              1996 yılında kurulan ÜNLÜ & Co Grubu, 27 yıldır
                              sürdürdüğü faaliyetleri kapsamında Yatırım
                              Danışmanlığı ve Aracılık Hizmetleri, Kurumsal
                              Finansman Danışmanlığı, Sermaye Piyasaları
                              Danışmanlığı, Alternatif Yatırımlar, Fon ve
                              Portföy Yönetimi alanlarında yatırımcılara hizmet
                              vermektedir.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="faq-collapse-body">
                        <span id="headingTwo">
                          <button
                            className="accordion-button collapsed bg1"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseTwo"
                            aria-expanded="false"
                            aria-controls="collapseTwo"
                          >
                            <strong className="faq-message-title">
                              Aracı kurum ne demek?
                            </strong>
                          </button>
                        </span>
                        <div
                          id="collapseTwo"
                          className="accordion-collapse collapse"
                          aria-labelledby="headingTwo"
                          data-bs-parent="#accordionExampleTwo"
                        >
                          <div className="faq-message-text">
                            <p>
                              Aracı kurum, sermaye piyasası faaliyetlerinde
                              bulunmak üzere Sermaye Piyasası Kurulu (SPK)
                              tarafından yetkilendirilmiş finansal şirketlerdir.
                            </p>
                            <p>
                              Mundi’deki tüm yatırım hesapları ÜNLÜ Menkul
                              Değerler A.Ş. bünyesinde açılmaktadır. ÜNLÜ Menkul
                              Değerler A.Ş., Sermaye Piyasası Kurulu (SPK) ve
                              Mali Suçları Araştırma Kurulu (MASAK) denetimine
                              tabi bir aracı kurumdur.
                            </p>
                            <p>
                              Yatırım hesabındaki sermaye piyasası yatırımların
                              Yatırımcı Tazmin Merkezi (YTM) ile devlet
                              güvencesi altındadır. Yatırımcı Tazmin Merkezi
                              (YTM) güvencesiyle 2024 yılı için birikimin
                              1.143.379 TL’ye kadar tazmin kapsamındadır. Bu
                              tutar her yıl ilan edilen yeniden değerleme
                              katsayısı oranında artırılır.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="faq-collapse-body">
                        <span id="headingThree">
                          <button
                            className="accordion-button collapsed bg1"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseThree"
                            aria-expanded="false"
                            aria-controls="collapseThree"
                          >
                            <strong className="faq-message-title">
                              Sipay nedir? Mundi Kart altyapısını kim sağlıyor?
                            </strong>
                          </button>
                        </span>
                        <div
                          id="collapseThree"
                          className="accordion-collapse collapse"
                          aria-labelledby="headingThree"
                          data-bs-parent="#accordionExampleTwo"
                        >
                          <div className="faq-message-text">
                            <p>
                              Sipay Elektronik Para ve Ödeme Hizmetleri A.Ş.,
                              Türkiye Cumhuriyet Merkez Bankası (TCMB) lisanslı,
                              6493 sayılı kanun çerçevesinde hizmet veren bir
                              elektronik para ve ödeme kuruluşudur.
                            </p>
                            <p>
                              Mundi Kart altyapısı 6493 sayılı kanun ve ilgili
                              mevzuata uygun şekilde Mundi’nin kart ve ödeme
                              çözüm ortağı Sipay tarafından sağlanmaktadır.
                            </p>
                            <p>
                              Mundi Kart, Mastercard güvencesiyle kullanımına
                              sunulan bir ön ödemeli karttır.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="faq-collapse-body">
                        <span id="headingFour">
                          <button
                            className="accordion-button collapsed bg1"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseFour"
                            aria-expanded="false"
                            aria-controls="collapseFour"
                          >
                            <strong className="faq-message-title">
                              Mundi’nin arkasındaki yatırımcılar kimler?
                            </strong>
                          </button>
                        </span>
                        <div
                          id="collapseFour"
                          className="accordion-collapse collapse"
                          aria-labelledby="headingFour"
                          data-bs-parent="#accordionExampleTwo"
                        >
                          <div className="faq-message-text">
                            <p>
                              Mundi’nin vizyonuna inanan, deneyimleriyle
                              Mundi’ye değer katan birçok yatırımcımız var ve bu
                              durum bizi çok sevindiriyor.
                            </p>
                            <p>
                              Yatırımcılarımız arasında Türkiye’nin çeşitli
                              bankalarında genel müdürlük yapmış, Türkiye’ye
                              birçok bankacılık ürününü ilk defa getirmiş ve
                              finansal teknoloji alanında dünya çapında
                              yatırımları olan isimler var.
                            </p>
                            <p>
                              Yatırımcılarımızı daha yakından tanımak için{' '}
                              <a href="/hakkimizda">Hakkımızda</a> sayfasına göz
                              atabilirsin.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="selecttab3"
                    role="tabpanel"
                    aria-labelledby="v-pills-messages-tab"
                  >
                    <div
                      className="accordion mt-50-collapse-30 mb-60"
                      id="accordionExampleThree"
                    >
                      <div>
                        <p className="faq-message-head-title">
                          <strong>Para Yatırma İşlemleri</strong>
                        </p>
                      </div>

                      <div className="faq-collapse-body">
                        <span id="headingOne">
                          <button
                            className="accordion-button collapsed bg1"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseOne"
                            aria-expanded="false"
                            aria-controls="collapseOne"
                          >
                            <strong className="faq-message-title">
                              Hesabıma nasıl para yatırabilirim?
                            </strong>
                          </button>
                        </span>
                        <div
                          id="collapseOne"
                          className="collapse bg3"
                          aria-labelledby="headingOne"
                          data-bs-parent="#accordionExampleThree"
                        >
                          <div className="faq-message-text">
                            <ul>
                              <li>
                                Uygulamada oturum aç ve “Para Transferi”
                                menüsünde “Para Yatır” seçeneğine dokun.
                              </li>
                              <li>
                                Banka listesinden hesabının bulunduğu bankayı
                                seç.
                              </li>
                              <li>
                                Seçtiğin bankadaki TL ve USD hesap bilgilerini
                                görüntüle.
                              </li>
                              <li>
                                Para göndereceğin hesap bilgilerini kopyalayarak
                                kendi banka uygulamandan para transferini
                                tamamla.
                              </li>
                              <li>
                                Açıklama alanına sırasıyla Mundi numaranı, adını
                                ve soyadını yazmayı unutma!
                              </li>
                            </ul>
                            <p>Paran kısa sürede hesabında olacak.</p>
                          </div>
                        </div>
                      </div>
                      <div className="faq-collapse-body">
                        <span id="headingTwo">
                          <button
                            className="accordion-button collapsed bg1"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseTwo"
                            aria-expanded="false"
                            aria-controls="collapseTwo"
                          >
                            <strong className="faq-message-title">
                              7/24 para yatırabilir miyim?
                            </strong>
                          </button>
                        </span>
                        <div
                          id="collapseTwo"
                          className="accordion-collapse collapse"
                          aria-labelledby="headingTwo"
                          data-bs-parent="#accordionExampleThree"
                        >
                          <div className="faq-message-text">
                            <p>
                              Evet, hesabına 7/24 para yatırabilirsin.
                              Yatırdığın tutar otomatik olarak değerlenmeye
                              başlar. Saat 15:00’ten sonra yatırdığın tutar bir
                              sonraki iş günü değerlenmeye başlar.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="faq-collapse-body">
                        <span id="headingFive">
                          <button
                            className="accordion-button collapsed bg1"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseFive"
                            aria-expanded="false"
                            aria-controls="collapseFive"
                          >
                            <strong className="faq-message-title">
                              Paramın hesabıma geçmesi ne kadar sürer?
                            </strong>
                          </button>
                        </span>
                        <div
                          id="collapseFive"
                          className="accordion-collapse collapse"
                          aria-labelledby="headingFive"
                          data-bs-parent="#accordionExampleThree"
                        >
                          <div className="faq-message-text">
                            <p>
                              Para transferi adımlarını eksiksiz tamamladıktan
                              sonra paran kısa süre içinde hesabında olur.
                            </p>
                            <p>
                              Banka transferi yaparken açıklama alanına
                              sırasıyla Mundi numaranı, adını ve soyadını
                              yazmayı unutma lütfen. Açıklama alanında eksik
                              bilgi bulunması halinde paran banka hesabına iade
                              edilir.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="faq-collapse-body">
                        <span id="headingSix">
                          <button
                            className="accordion-button collapsed bg1"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseSix"
                            aria-expanded="false"
                            aria-controls="collapseSix"
                          >
                            <strong className="faq-message-title">
                              Yatırdığım para hesabıma geçmedi. Ne yapmalıyım?
                            </strong>
                          </button>
                        </span>
                        <div
                          id="collapseSix"
                          className="accordion-collapse collapse"
                          aria-labelledby="headingSix"
                          data-bs-parent="#accordionExampleThree"
                        >
                          <div className="faq-message-text">
                            <p>
                              Para yatırma adımlarını eksiksiz tamamladığın
                              halde paran hesabında görünmüyorsa bizimle
                              iletişime geçebilirsin.
                            </p>
                            <p>
                              Her iş günü 09:00-18:00 arasında{' '}
                              <a href="tel:0850 242 24 24">0850 242 24 24</a>{' '}
                              numaralı telefondan veya dilediğin zaman{' '}
                              <a href="mailto:destek@getmundi.app">
                                destek@getmundi.app
                              </a>{' '}
                              e-posta adresinden Mundi Destek Merkezi’ne
                              ulaşabilirsin.
                            </p>
                            <p>
                              Para yatırma adımlarına uygun olarak
                              gerçekleştirilmeyen para transferlerinin hesaba
                              yansıması daha uzun sürer. Herhangi bir sebeple
                              para transferin gerçekleşmezse ilgili tutar banka
                              hesabına iade edilir.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="faq-collapse-body">
                        <span id="headingSeven">
                          <button
                            className="accordion-button collapsed bg1"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseSeven"
                            aria-expanded="false"
                            aria-controls="collapseSeven"
                          >
                            <strong className="faq-message-title">
                              İstediğim hesaptan para yatırabilir miyim?
                            </strong>
                          </button>
                        </span>
                        <div
                          id="collapseSeven"
                          className="accordion-collapse collapse"
                          aria-labelledby="headingSeven"
                          data-bs-parent="#accordionExampleThree"
                        >
                          <div className="faq-message-text">
                            <p>
                              Evet, istediğin Türk Lirası veya Amerikan Doları
                              banka hesabından para yatırabilirsin.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="faq-collapse-body">
                        <span id="headingNine">
                          <button
                            className="accordion-button collapsed bg1"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseNine"
                            aria-expanded="false"
                            aria-controls="collapseNine"
                          >
                            <strong className="faq-message-title">
                              Para yatırma limiti var mı?
                            </strong>
                          </button>
                        </span>
                        <div
                          id="collapseNine"
                          className="accordion-collapse collapse"
                          aria-labelledby="headingNine"
                          data-bs-parent="#accordionExampleThree"
                        >
                          <div className="faq-message-text">
                            <p>
                              Dilediğin tutarda para yatırabilirsin. Herhangi
                              bir alt veya üst limit bulunmuyor.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="faq-collapse-body">
                        <span id="headingTen">
                          <button
                            className="accordion-button collapsed bg1"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseTen"
                            aria-expanded="false"
                            aria-controls="collapseTen"
                          >
                            <strong className="faq-message-title">
                              Neden para yatırmak için ÜNLÜ Menkul’ün banka
                              hesabına havale yapmam gerekiyor?
                            </strong>
                          </button>
                        </span>
                        <div
                          id="collapseTen"
                          className="accordion-collapse collapse"
                          aria-labelledby="headingTen"
                          data-bs-parent="#accordionExampleThree"
                        >
                          <div className="faq-message-text">
                            <p>
                              Mundi’deki yatırım hesabın lisanslı aracı kurum
                              partnerimiz ÜNLÜ Menkul Değerler A.Ş. bünyesinde
                              açılır. Bu nedenle hesabına para yatırmak
                              istediğinde ÜNLÜ Menkul’ün bankandaki TL veya USD
                              hesabına havale yapman ve açıklama alanına hesap
                              numaranı, adını ve soyadını yazman gerekmektedir.
                            </p>
                            <p>
                              ÜNLÜ Menkul düzenli olarak denetlenen lisanslı bir
                              aracı kurumdur. Bu nedenle endişelenmene gerek
                              kalmaz. Ayrıca yatırım hesabındaki bakiyen kanunen
                              belirli bir tutara kadar Yatırımcı Tazmin Merkezi
                              (YTM) ile devlet güvencesi altındadır.
                            </p>
                            <p>
                              Yatırımcı Tazmin Merkezi (YTM) güvencesiyle 2024
                              yılı için sermaye piyasası yatırımların 1.143.379
                              TL’ye kadar tazmin kapsamındadır. Bu tutar her yıl
                              ilan edilen yeniden değerleme katsayısı oranında
                              artırılır.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="faq-collapse-body">
                        <span id="headingEleven">
                          <button
                            className="accordion-button collapsed bg1"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseEleven"
                            aria-expanded="false"
                            aria-controls="collapseEleven"
                          >
                            <strong className="faq-message-title">
                              Para yatırmak için havale yaparken açıklama
                              alanına hesap numaramı yanlış yazdım / yazmayı
                              unuttum. Ne yapmalıyım?
                            </strong>
                          </button>
                        </span>
                        <div
                          id="collapseEleven"
                          className="accordion-collapse collapse"
                          aria-labelledby="headingEleven"
                          data-bs-parent="#accordionExampleThree"
                        >
                          <div className="faq-message-text">
                            <p>
                              Endişelenmene hiç gerek yok. Para yatırma
                              adımlarına uygun olarak gerçekleştirilmeyen para
                              transferlerinin hesaba yansıması daha uzun sürer.
                              Herhangi bir sebeple para transferin
                              gerçekleşmezse ilgili tutar banka hesabına iade
                              edilir.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="mt-75 mb-60">
                        <p className="faq-message-head-title">
                          <strong>Para Çekme İşlemleri</strong>
                        </p>
                      </div>
                      <div className="faq-collapse-body">
                        <span id="headingTwelve">
                          <button
                            className="accordion-button collapsed bg1"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseTwelve"
                            aria-expanded="false"
                            aria-controls="collapseTwelve"
                          >
                            <strong className="faq-message-title">
                              Paramı banka hesabıma nasıl gönderirim?
                            </strong>
                          </button>
                        </span>
                        <div
                          id="collapseTwelve"
                          className="accordion-collapse collapse"
                          aria-labelledby="headingTwelve"
                          data-bs-parent="#accordionExampleThree"
                        >
                          <div className="faq-message-text">
                            <ul>
                              <li>
                                Uygulamada oturum aç ve “Para Transferi”
                                menüsünde “Para Çek” seçeneğine dokun.
                              </li>
                              <li>
                                Görüntülediğin ekranda para transferini
                                yapacağın hesabı ve alıcı banka hesabını seç.
                              </li>
                              <li>
                                Onayla ve kimliğini doğrulayarak işlemi tamamla.
                              </li>
                            </ul>
                            <p>
                              Banka hesabına para transferi için Mundi’ye banka
                              hesap bilgilerini kaydetmen gerekiyor. Banka
                              hesaplarını dilediğin zaman güncelleyebilirsin.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="faq-collapse-body">
                        <span id="headingThirteen">
                          <button
                            className="accordion-button collapsed bg1"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseThirteen"
                            aria-expanded="false"
                            aria-controls="collapseThirteen"
                          >
                            <strong className="faq-message-title">
                              İstediğim banka hesabına para gönderebilir miyim?
                            </strong>
                          </button>
                        </span>
                        <div
                          id="collapseThirteen"
                          className="accordion-collapse collapse"
                          aria-labelledby="headingThirteen"
                          data-bs-parent="#accordionExampleThree"
                        >
                          <div className="faq-message-text">
                            <p>
                              Banka hesabının senin adına olması gerekiyor.
                              Dilediğin banka hesabını seçebilirsin.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="faq-collapse-body">
                        <span id="headingFourteen">
                          <button
                            className="accordion-button collapsed bg1"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseFourteen"
                            aria-expanded="false"
                            aria-controls="collapseFourteen"
                          >
                            <strong className="faq-message-title">
                              Paramı banka hesabıma göndermek ücretli mi?
                            </strong>
                          </button>
                        </span>
                        <div
                          id="collapseFourteen"
                          className="accordion-collapse collapse"
                          aria-labelledby="headingFourteen"
                          data-bs-parent="#accordionExampleThree"
                        >
                          <div className="faq-message-text">
                            <p>
                              Mundi’deki Türk Lirası birikimini banka hesabına
                              gönderirken Mundi’de hiçbir ücret alınmaz.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="faq-collapse-body">
                        <span id="headingFiveteen">
                          <button
                            className="accordion-button collapsed bg1"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseFiveteen"
                            aria-expanded="false"
                            aria-controls="collapseFiveteen"
                          >
                            <strong className="faq-message-title">
                              Banka hesabımı Mundi’ye nasıl eklerim?
                            </strong>
                          </button>
                        </span>
                        <div
                          id="collapseFiveteen"
                          className="accordion-collapse collapse"
                          aria-labelledby="headingFiveteen"
                          data-bs-parent="#accordionExampleThree"
                        >
                          <div className="faq-message-text">
                            <ul>
                              <li>
                                Uygulamada oturum aç ve “Profil” menüsünde
                                “Banka Hesapları” seçeneğine dokun.
                              </li>
                              <li>
                                Görüntülediğin ekranda eklemek istediğin hesaba
                                göre Türk Lirası veya Amerikan Doları sekmesine
                                git.
                              </li>
                              <li>
                                “Yeni Banka Hesabı Ekle” butonuna dokun ve hesap
                                detaylarını gir.
                              </li>
                            </ul>
                            <p>
                              Banka hesabını kullanmaya hemen başlayabilirsin.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="faq-collapse-body">
                        <span id="headingSixteen">
                          <button
                            className="accordion-button collapsed bg1"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseSixteen"
                            aria-expanded="false"
                            aria-controls="collapseSixteen"
                          >
                            <strong className="faq-message-title">
                              Banka hesabıma para gönderdim. Neden gönderici
                              olarak ÜNLÜ Menkul Değerler A.Ş. görünüyor?
                            </strong>
                          </button>
                        </span>
                        <div
                          id="collapseSixteen"
                          className="accordion-collapse collapse"
                          aria-labelledby="headingSixteen"
                          data-bs-parent="#accordionExampleThree"
                        >
                          <div className="faq-message-text">
                            <p>
                              Mundi’de yatırım hesabın aracı kurum partnerimiz
                              ÜNLÜ Menkul bünyesinde açılır.
                            </p>
                            <p>
                              Yatırım hesabından banka hesabına para
                              gönderirsen, bu tutar ÜNLÜ Menkul tarafından
                              gönderdildiği için gönderici olarak ÜNLÜ Menkul
                              Değerler A.Ş.’yi görürsün.
                            </p>
                            <p>
                              ÜNLÜ Menkul Değerler A.Ş, Sermaye Piyasası Kurulu
                              (SPK) ve Mali Suçları Araştırma Kurulu (MASAK)
                              denetimine tabi, geniş kapsamlı bir aracı
                              kurumdur.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="faq-collapse-body">
                        <span id="headingSeventeen">
                          <button
                            className="accordion-button collapsed bg1"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseSeventeen"
                            aria-expanded="false"
                            aria-controls="collapseSeventeen"
                          >
                            <strong className="faq-message-title">
                              Gecelik vadeli hesabımdan paramı saat 09:00-15:00
                              arası çekebiliyorum. Neden daha geç saatlerde bu
                              işlemi yapamıyorum?
                            </strong>
                          </button>
                        </span>
                        <div
                          id="collapseSeventeen"
                          className="accordion-collapse collapse"
                          aria-labelledby="headingSeventeen"
                          data-bs-parent="#accordionExampleThree"
                        >
                          <div className="faq-message-text">
                            <p>
                              Gecelik vadeli hesaplardaki paran değerlenmek
                              üzere saat 15:00’te tekrar bağlanır. Hesabındaki
                              bakiyeni getirisiyle birlikte sabah 09:00’da
                              çekebilirsin.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="faq-collapse-body">
                        <span id="headingEightteen">
                          <button
                            className="accordion-button collapsed bg1"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseEightteen"
                            aria-expanded="false"
                            aria-controls="collapseEightteen"
                          >
                            <strong className="faq-message-title">
                              Mundi’deki vadesiz hesaplarımdan* banka hesabıma
                              para gönderdim. Neden gönderici olarak Sipay
                              Elektronik Para ve Ödeme Hizmetleri A.Ş.
                              görünüyor?
                            </strong>
                          </button>
                        </span>
                        <div
                          id="collapseEightteen"
                          className="accordion-collapse collapse"
                          aria-labelledby="headingEightteen"
                          data-bs-parent="#accordionExampleThree"
                        >
                          <div className="faq-message-text">
                            <p>
                              Mundi’deki vadesiz hesapların* ve vadesiz
                              hesaplarına* bağlı kartların altyapısı Sipay
                              tarafından sağlanmaktadır. Bu nedenle vadesiz
                              hesaplarından* 7/24 para transferi yaptığında
                              gönderici olarak Sipay Elektronik Para ve Ödeme
                              Hizmetleri A.Ş.’yi görürsün.
                            </p>
                            <p>
                              Sipay Elektronik Para ve Ödeme Hizmetleri A.Ş.,
                              Türkiye Cumhuriyet Merkez Bankası (TCMB) lisanslı,
                              6493 sayılı kanun çerçevesinde hizmet veren bir
                              elektronik para ve ödeme kuruluşudur.
                            </p>
                            <p className="information-text">
                              *Mundi Kart ve dijital cüzdan altyapısı 6493
                              sayılı kanun ve ilgili mevzuata uygun şekilde
                              Sipay Elektronik Para ve Ödeme Hizmetleri A.Ş.
                              tarafından sağlanmaktadır.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="faq-collapse-body">
                        <span id="headingNineteen">
                          <button
                            className="accordion-button collapsed bg1"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseNineteen"
                            aria-expanded="false"
                            aria-controls="collapseNineteen"
                          >
                            <strong className="faq-message-title">
                              Para çekme limiti var mı?
                            </strong>
                          </button>
                        </span>
                        <div
                          id="collapseNineteen"
                          className="accordion-collapse collapse"
                          aria-labelledby="headingNineteen"
                          data-bs-parent="#accordionExampleThree"
                        >
                          <div className="faq-message-text">
                            <p>
                              Gecelik TL hesabından para çekmek için alt limitin
                              5 TL, gecelik USD hesabından ise 10 USD{"'"}dir.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="mt-75 mb-60">
                        <p className="faq-message-head-title">
                          <strong>
                            Mundi{"'"}deki hesaplarım arasında para transferi
                          </strong>
                        </p>
                      </div>
                      <div className="faq-collapse-body">
                        <span id="headingTwenty">
                          <button
                            className="accordion-button collapsed bg1"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseTwenty"
                            aria-expanded="false"
                            aria-controls="collapseTwenty"
                          >
                            <strong className="faq-message-title">
                              Vadesiz hesabımdan* gecelik vadeli hesabıma hangi
                              saatlerde para transferi yapabilirim?
                            </strong>
                          </button>
                        </span>
                        <div
                          id="collapseTwenty"
                          className="accordion-collapse collapse"
                          aria-labelledby="headingTwenty"
                          data-bs-parent="#accordionExampleThree"
                        >
                          <div className="faq-message-text">
                            <p>
                              Mundi’de vadesiz hesabından* gecelik vadeli
                              hesabına dilediğin zaman para aktarabilirsin. Her
                              iş günü 09:00-15:00 arasında gerçekleştirdiğin
                              transferler aynı gün içinde, bu saatler dışında
                              gerçekleştirdiğin transferler ise ertesi gün
                              değerlenmeye başlar.
                            </p>
                            <p>
                              Vadesiz hesabındaki* bakiyeyi daha uzun vadelerde
                              değerlendirmek istersen her iş günü 09:00-15:00
                              arasında yeni vadeli hesap açabilirsin.
                            </p>
                            <p className="information-text">
                              *Mundi Kart ve dijital cüzdan altyapısı 6493
                              sayılı kanun ve ilgili mevzuata uygun şekilde
                              Sipay Elektronik Para ve Ödeme Hizmetleri A.Ş.
                              tarafından sağlanmaktadır.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="faq-collapse-body">
                        <span id="headingTwentyOne">
                          <button
                            className="accordion-button collapsed bg1"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseTwentyOne"
                            aria-expanded="false"
                            aria-controls="collapseTwentyOne"
                          >
                            <strong className="faq-message-title">
                              Gecelik vadeli hesabımdan vadesiz hesabıma* hangi
                              saatlerde para transferi yapabilirim?
                            </strong>
                          </button>
                        </span>
                        <div
                          id="collapseTwentyOne"
                          className="accordion-collapse collapse"
                          aria-labelledby="headingTwentyOne"
                          data-bs-parent="#accordionExampleThree"
                        >
                          <div className="faq-message-text">
                            <p>
                              Gecelik vadeli hesaplarından her iş günü
                              09:00-15:00 arasında para transferi
                              gerçekleştirebilirsin.
                            </p>
                            <p className="information-text">
                              *Mundi Kart ve dijital cüzdan altyapısı 6493
                              sayılı kanun ve ilgili mevzuata uygun şekilde
                              Sipay Elektronik Para ve Ödeme Hizmetleri A.Ş.
                              tarafından sağlanmaktadır.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="selecttab4"
                    role="tabpanel"
                    aria-labelledby="v-pills-messages-tab"
                  >
                    <div
                      className="accordion mt-50-collapse-30 mb-60"
                      id="accordionExampleFour"
                    >
                      <p className="faq-message-head-title">
                        <strong>Vadeli Hesaplar</strong>
                      </p>

                      <div className="faq-collapse-body">
                        <span id="headingOne">
                          <button
                            className="accordion-button collapsed bg1"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseOne"
                            aria-expanded="false"
                            aria-controls="collapseOne"
                          >
                            <strong className="faq-message-title">
                              Mundi üzerinden nasıl vadeli hesap açarım?
                            </strong>
                          </button>
                        </span>
                        <div
                          id="collapseOne"
                          className="collapse bg3"
                          aria-labelledby="headingOne"
                          data-bs-parent="#accordionExampleFour"
                        >
                          <div className="faq-message-text">
                            <ul>
                              <li>
                                Uygulamada oturum aç ve “Yeni Hesap” menüsüne
                                git.
                              </li>
                              <li>Ekrandaki adımları sırasıyla takip et.</li>
                            </ul>
                            <p>
                              Mundi’de her iş günü 09:00-15:00 arasında vadeli
                              hesap açabilirsin.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="faq-collapse-body">
                        <span id="headingTwo">
                          <button
                            className="accordion-button collapsed bg1"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseTwo"
                            aria-expanded="false"
                            aria-controls="collapseTwo"
                          >
                            <strong className="faq-message-title">
                              Ne zaman vadeli hesap açabilirim?
                            </strong>
                          </button>
                        </span>
                        <div
                          id="collapseTwo"
                          className="accordion-collapse collapse"
                          aria-labelledby="headingTwo"
                          data-bs-parent="#accordionExampleFour"
                        >
                          <div className="faq-message-text">
                            <p>
                              Mundi’de her iş günü 09:00-15:00 arasında vadeli
                              hesap açabilirsin.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="faq-collapse-body">
                        <span id="headingFive">
                          <button
                            className="accordion-button collapsed bg1"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseFive"
                            aria-expanded="false"
                            aria-controls="collapseFive"
                          >
                            <strong className="faq-message-title">
                              Mundi üzerinden değerlendirdiğim birikimim güvence
                              altında mı?
                            </strong>
                          </button>
                        </span>
                        <div
                          id="collapseFive"
                          className="accordion-collapse collapse"
                          aria-labelledby="headingFive"
                          data-bs-parent="#accordionExampleFour"
                        >
                          <div className="faq-message-text">
                            <p>
                              Mundi’deki tüm yatırım hesapları ÜNLÜ Menkul
                              Değerler A.Ş. bünyesinde açılmaktadır. ÜNLÜ Menkul
                              Değerler A.Ş., Sermaye Piyasası Kurulu (SPK) ve
                              Mali Suçları Araştırma Kurulu (MASAK) denetimine
                              tabi bir aracı kurumdur.
                            </p>
                            <p>
                              Yatırım hesabındaki bakiyen Yatırımcı Tazmin
                              Merkezi (YTM) ile devlet güvencesi altındadır.
                              Yatırımcı Tazmin Merkezi (YTM) güvencesiyle 2024
                              yılı için birikimin 1.143.379 TL’ye kadar tazmin
                              kapsamındadır. Bu tutar her yıl ilan edilen
                              yeniden değerleme katsayısı oranında artırılır.
                            </p>
                            <p>
                              Mundi, senden aldığı bilgileri resmi kurumlar ve
                              iş ortakları haricinde üçüncü şahıs ve kurumlarla
                              asla paylaşmaz.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="mt-75 mb-60">
                        <p className="faq-message-head-title">
                          <strong>Amerikan Doları Değerlendirme</strong>
                        </p>
                      </div>
                      <div className="faq-collapse-body">
                        <span id="headingNine">
                          <button
                            className="accordion-button collapsed bg1"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseNine"
                            aria-expanded="false"
                            aria-controls="collapseNine"
                          >
                            <strong className="faq-message-title">
                              Amerikan Doları cinsinden birikimimi Mundi
                              üzerinden değerlendirebilir miyim?
                            </strong>
                          </button>
                        </span>
                        <div
                          id="collapseNine"
                          className="accordion-collapse collapse"
                          aria-labelledby="headingNine"
                          data-bs-parent="#accordionExampleFour"
                        >
                          <div className="faq-message-text">
                            <p>
                              Mundi’de Amerikan Doları birikimlerini
                              değerlendirebilirsin.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="faq-collapse-body">
                        <span id="headingTen">
                          <button
                            className="accordion-button collapsed bg1"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseTen"
                            aria-expanded="false"
                            aria-controls="collapseTen"
                          >
                            <strong className="faq-message-title">
                              Amerikan Doları birikimlerimi değerlendirmem için
                              bir alt limit var mı?
                            </strong>
                          </button>
                        </span>
                        <div
                          id="collapseTen"
                          className="accordion-collapse collapse"
                          aria-labelledby="headingTen"
                          data-bs-parent="#accordionExampleFour"
                        >
                          <div className="faq-message-text">
                            <p>
                              Amerikan Doları birikimlerini değerlendirmen için
                              herhangi bir alt limit yoktur.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="selecttab6"
                    role="tabpanel"
                    aria-labelledby="v-pills-messages-tab"
                  >
                    <div
                      className="accordion mt-50-collapse-30 mb-60"
                      id="accordionExampleSix"
                    >
                      <div className="faq-collapse-body">
                        <span id="headingOne">
                          <button
                            className="accordion-button collapsed bg1"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseOne"
                            aria-expanded="false"
                            aria-controls="collapseOne"
                          >
                            <strong className="faq-message-title">
                              Hesap ve kart hareketlerimi nereden görebilirim?
                            </strong>
                          </button>
                        </span>
                        <div
                          id="collapseOne"
                          className="collapse bg3"
                          aria-labelledby="headingOne"
                          data-bs-parent="#accordionExampleSix"
                        >
                          <div className="faq-message-text">
                            <p>
                              Hesap hareketlerini &quot;Profil&quot; menüsünde
                              &quot;Hesap Hareketleri&quot; seçeneğine dokunarak
                              görebilirsin.
                            </p>
                            <p>
                              Kart hareketlerini ise kartın bağlı olduğu vadesiz
                              hesabın* detay ekranında görebilirsin.
                            </p>
                            <p className="information-text">
                              *Mundi Kart ve dijital cüzdan altyapısı 6493
                              sayılı kanun ve ilgili mevzuata uygun şekilde
                              Sipay Elektronik Para ve Ödeme Hizmetleri A.Ş.
                              tarafından sağlanmaktadır.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="faq-collapse-body">
                        <span id="headingThree">
                          <button
                            className="accordion-button collapsed bg1"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseThree"
                            aria-expanded="false"
                            aria-controls="collapseThree"
                          >
                            <strong className="faq-message-title">
                              Hesabımı nasıl kapatabilirim?
                            </strong>
                          </button>
                        </span>
                        <div
                          id="collapseThree"
                          className="accordion-collapse collapse"
                          aria-labelledby="headingThree"
                          data-bs-parent="#accordionExampleSix"
                        >
                          <div className="faq-message-text">
                            <ul>
                              <li>
                                &quot;Profil&quot; menüsünde &quot;Yardım
                                Merkezi&quot; seçeneğine dokun.
                              </li>
                              <li>
                                Görüntülediğin ekranda &quot;Destek Talebi&quot;
                                seçeneğine dokunduktan sonra &quot;Konu&quot;
                                listesinden &quot;Hesap Kapatma&quot; seçerek
                                talebini oluşturabilirsin.
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="faq-collapse-body">
                        <span id="headingFour">
                          <button
                            className="accordion-button collapsed bg1"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseFour"
                            aria-expanded="false"
                            aria-controls="collapseFour"
                          >
                            <strong className="faq-message-title">
                              Kayıtlı adresimi nasıl değiştirebilirim?
                            </strong>
                          </button>
                        </span>
                        <div
                          id="collapseFour"
                          className="accordion-collapse collapse"
                          aria-labelledby="headingFour"
                          data-bs-parent="#accordionExampleSix"
                        >
                          <div className="faq-message-text">
                            <ul>
                              <li>
                                &quot;Profil&quot; -{'>'} &quot;Ayarlar&quot; -
                                {'>'} “Adres Değiştir” adımlarını takip ederek
                                kayıtlı adresini değiştirebilirsin.
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="faq-collapse-body">
                        <span id="headingFive">
                          <button
                            className="accordion-button collapsed bg1"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseFive"
                            aria-expanded="false"
                            aria-controls="collapseFive"
                          >
                            <strong className="faq-message-title">
                              Kayıtlı e-posta adresimi nasıl değiştirebilirim?
                            </strong>
                          </button>
                        </span>
                        <div
                          id="collapseFive"
                          className="accordion-collapse collapse"
                          aria-labelledby="headingFive"
                          data-bs-parent="#accordionExampleSix"
                        >
                          <div className="faq-message-text">
                            <ul>
                              <li>
                                &quot;Profil&quot; -{'>'} &quot;Ayarlar&quot; -
                                {'>'} “E-posta Değiştir” adımlarını takip ederek
                                kayıtlı e-posta adresini değiştirebilirsin.
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="faq-collapse-body">
                        <span id="headingSix">
                          <button
                            className="accordion-button collapsed bg1"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseSix"
                            aria-expanded="false"
                            aria-controls="collapseSix"
                          >
                            <strong className="faq-message-title">
                              Kayıtlı telefon numaramı nasıl değiştirebilirim?
                            </strong>
                          </button>
                        </span>
                        <div
                          id="collapseSix"
                          className="accordion-collapse collapse"
                          aria-labelledby="headingSix"
                          data-bs-parent="#accordionExampleSix"
                        >
                          <div className="faq-message-text">
                            <ul>
                              <li>
                                &quot;Profil&quot; -{'>'} &quot;Ayarlar&quot; -
                                {'>'} “Cep Telefonu Numarası Değiştir”
                                adımlarını takip ederek kayıtlı telefon numaranı
                                değiştirebilirsin.
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="faq-collapse-body">
                        <span id="headingEight">
                          <button
                            className="accordion-button collapsed bg1"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseEight"
                            aria-expanded="false"
                            aria-controls="collapseEight"
                          >
                            <strong className="faq-message-title">
                              Şifremi nasıl değiştirebilirim?
                            </strong>
                          </button>
                        </span>
                        <div
                          id="collapseEight"
                          className="accordion-collapse collapse"
                          aria-labelledby="headingEight"
                          data-bs-parent="#accordionExampleSix"
                        >
                          <div className="faq-message-text">
                            <ul>
                              <li>
                                &quot;Profil&quot; -{'>'} &quot;Ayarlar&quot; -
                                {'>'}&quot;Şifre Değişikliği&quot; adımlarını
                                izleyerek şifreni değiştirebilirsin.
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="faq-collapse-body">
                        <span id="headingNine">
                          <button
                            className="accordion-button collapsed bg1"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseNine"
                            aria-expanded="false"
                            aria-controls="collapseNine"
                          >
                            <strong className="faq-message-title">
                              Şifremi unuttum. Ne yapmalıyım?
                            </strong>
                          </button>
                        </span>
                        <div
                          id="collapseNine"
                          className="accordion-collapse collapse"
                          aria-labelledby="headingNine"
                          data-bs-parent="#accordionExampleSix"
                        >
                          <div className="faq-message-text">
                            <p>
                              Kimliğini doğrulaman gerektiğinde “Kimlik
                              Doğrulaması” başlıklı bir ekran çıkar. Bu ekranda
                              “Şifremi Unuttum” seçeneğine dokunup adımları
                              takip ederek yeni bir şifre belirleyebilirsin.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="selecttab7"
                    role="tabpanel"
                    aria-labelledby="v-pills-messages-tab"
                  >
                    <div
                      className="accordion mt-50-collapse-30 mb-60"
                      id="accordionExampleSeven"
                    >
                      <div className="faq-collapse-body">
                        <span id="headingFour">
                          <button
                            className="accordion-button collapsed bg1"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseFour"
                            aria-expanded="false"
                            aria-controls="collapseFour"
                          >
                            <strong className="faq-message-title">
                              Mundi’ye nasıl ulaşabilirim?
                            </strong>
                          </button>
                        </span>
                        <div
                          id="collapseFour"
                          className="accordion-collapse collapse"
                          aria-labelledby="headingFour"
                          data-bs-parent="#accordionExampleSeven"
                        >
                          <div className="faq-message-text">
                            <p>
                              Mundi Destek Ekibi’ne ulaşabileceğin birden fazla
                              kanal var.
                            </p>
                            <ul>
                              <li>
                                <a href="mailto:destek@getmundi.app">
                                  destek@getmundi.app
                                </a>{' '}
                                adresine e-posta gönderebilirsin.
                              </li>
                              <li>
                                <a href="/destek">www.getmundi.app/destek</a>{' '}
                                sayfamızdaki iletişim formunu doldurabilirsin.
                              </li>
                              <li>
                                Uygulamada “Profil” ekranındaki “Yardım Merkezi”
                                menüsünden destek talebini iletebilirsin.
                              </li>
                            </ul>
                            <p>
                              Her iş günü 09:00-18:00 saatleri arasında{' '}
                              <a href="tel:0850 242 24 24">0850 242 24 24</a>{' '}
                              numaralı telefonu arayabilirsin.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="selecttab8"
                    role="tabpanel"
                    aria-labelledby="v-pills-messages-tab"
                  >
                    <div
                      className="accordion mt-50-collapse-30 mb-60"
                      id="accordionExampleEight"
                    >
                      <div className="faq-collapse-body">
                        <span id="headingOne">
                          <button
                            className="accordion-button collapsed bg1"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseOne"
                            aria-expanded="false"
                            aria-controls="collapseOne"
                          >
                            <strong className="faq-message-title">
                              Şirketim için nasıl hesap açarım?
                            </strong>
                          </button>
                        </span>
                        <div
                          id="collapseOne"
                          className="accordion-collapse collapse"
                          aria-labelledby="headingOne"
                          data-bs-parent="#accordionExampleEight"
                        >
                          <div className="faq-message-text">
                            <p>
                              Kurumsal Başvuru Formu doldurulduktan sonra hesap
                              açılış işlemlerin için kısa süre içinde iletişime
                              geçeriz. Gerekli belgeler ve sözleşme sürecin
                              tamamlandıktan sonra hesap açılışın gerçekleşir.
                            </p>
                            <p>
                              Mundi’den nasıl faydalanabileceğini paylaşmak ve
                              hesap açılış işlemlerini takip etmek için ekibimiz
                              sana her adımda eşlik eder.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="faq-collapse-body">
                        <span id="headingTwo">
                          <button
                            className="accordion-button collapsed bg1"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseTwo"
                            aria-expanded="false"
                            aria-controls="collapseTwo"
                          >
                            <strong className="faq-message-title">
                              Mundi nasıl çalışır?
                            </strong>
                          </button>
                        </span>
                        <div
                          id="collapseTwo"
                          className="accordion-collapse collapse"
                          aria-labelledby="headingTwo"
                          data-bs-parent="#accordionExampleEight"
                        >
                          <div className="faq-message-text">
                            <p>
                              Üye olduktan sonra Mundi uygulaması üzerinden ÜNLÜ
                              Menkul Değerler A.Ş. nezdinde yatırım hesabı
                              açarsın. Banka hesaplarından dilediğin tutarı
                              yatırım hesabına aktarırsın.
                            </p>
                            <p>
                              Mundi üzerinden gerçekleştirdiğin bütün yatırım
                              işlemleri ÜNLÜ Menkul Değerler A.Ş. bünyesinde
                              gerçekleşmektedir. ÜNLÜ Menkul Değerler A.Ş.
                              Sermaye Piyasası Kurulu (SPK) ve Mali Suçları
                              Araştırma Kurulu (MASAK) denetimine tabi bir aracı
                              kurumdur.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="faq-collapse-body">
                        <span id="headingThree">
                          <button
                            className="accordion-button collapsed bg1"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseThree"
                            aria-expanded="false"
                            aria-controls="collapseThree"
                          >
                            <strong className="faq-message-title">
                              Mundi neden güvenlidir?
                            </strong>
                          </button>
                        </span>
                        <div
                          id="collapseThree"
                          className="accordion-collapse collapse"
                          aria-labelledby="headingThree"
                          data-bs-parent="#accordionExampleEight"
                        >
                          <div className="faq-message-text">
                            <p>
                              Mundi’ye üye olduktan sonra açtığın tüm yatırım
                              hesapları Sermaye Piyasası Kurulu (SPK) ve Mali
                              Suçları Araştırma Kurulu (MASAK) denetimine tabi
                              bir aracı kurum bünyesinde açılmaktadır.
                            </p>
                            <p>
                              Ayrıca yatırım hesabındaki sermaye piyasası
                              yatırımların Yatırımcı Tazmin Merkezi (YTM)
                              sayesinde devlet güvencesi altındadır. Yatırımcı
                              Tazmin Merkezi (YTM) güvencesiyle 2024 yılı için
                              birikimin 1.143.379 TL’ye kadar tazmin
                              kapsamındadır. Bu tutar her yıl ilan edilen
                              yeniden değerleme katsayısı oranında artırılır.
                            </p>
                            <p>
                              Mundi Kart altyapısı 6493 sayılı kanun ve ilgili
                              mevzuata uygun şekilde Sipay Elektronik Para ve
                              Ödeme Hizmetleri A.Ş. tarafından sağlanmaktadır.
                            </p>
                            <p>
                              Sipay Elektronik Para ve Ödeme Hizmetleri A.Ş.,
                              Türkiye Cumhuriyet Merkez Bankası (TCMB) lisanslı,
                              6493 sayılı kanun çerçevesinde hizmet veren bir
                              elektronik para ve ödeme kuruluşudur.
                            </p>
                            <p>
                              Mundi, senden aldığı bilgileri resmi kurumlar ve
                              iş ortakları haricinde üçüncü şahıs ve kurumlarla
                              asla paylaşmaz.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="faq-collapse-body">
                        <span id="headingFour">
                          <button
                            className="accordion-button collapsed bg1"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseFour"
                            aria-expanded="false"
                            aria-controls="collapseFour"
                          >
                            <strong className="faq-message-title">
                              {' '}
                              Şirketim adına yaptığım işlemleri nasıl takip
                              ederim?{' '}
                            </strong>
                          </button>
                        </span>
                        <div
                          id="collapseFour"
                          className="accordion-collapse collapse"
                          aria-labelledby="headingFour"
                          data-bs-parent="#accordionExampleEight"
                        >
                          <div className="faq-message-text">
                            <p>
                              Hesap hareketlerini {'"Profil"'} menüsünde{' '}
                              {'"Hesap Hareketleri"'} seçeneğine dokunarak
                              görebilirsin. Ayrıca şirketinin muhasebe işlemleri
                              için gerekli olan raporlar ve hesap ekstreleri
                              dilediğin sıklıkta e-posta ile paylaşılır.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="faq-collapse-body">
                        <span id="headingFive">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseFive"
                            aria-expanded="false"
                            aria-controls="collapseFive"
                          >
                            <strong className="faq-message-title">
                              Hesap işletim ücreti veya işlem masrafı var mı?
                            </strong>
                          </button>
                        </span>
                        <div
                          id="collapseFive"
                          className="accordion-collapse collapse"
                          aria-labelledby="headingFive"
                          data-bs-parent="#accordionExampleEight"
                        >
                          <div className="faq-message-text">
                            <p>
                              Aracı kurum partnerimiz, kullanıcılarımıza
                              sağladığı avantajlı nema oranları üzerinden
                              komisyon alır. Komisyon oranı TL birikimlerin için
                              yıllık %1,50, USD birikimlerin için ise yıllık
                              %0,75 olarak uygulanır. Uygulanan komisyon dışında
                              herhangi bir ücret yansıtılmaz.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

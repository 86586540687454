import React, { useEffect, useState } from 'react'
import Logo1 from '../img/odeal-logo.webp'
import Logo2 from '../img/hesapkurdu-logo.webp'
import Logo3 from '../img/voltlines-logo.webp'
import Logo4 from '../img/epias-logo.webp'
import Logo5 from '../img/maxpotential-logo.webp'
import Logo6 from '../img/sdsprestij-logo.webp'
import Logo7 from '../img/alotech-logo.webp'
import Logo8 from '../img/reos-logo.webp'
import Logo9 from '../img/norma-logo.webp'
import Logo10 from '../img/mia-logo.webp'
import Logo11 from '../img/turkuaz-logo.webp'
import Logo12 from '../img/map-logo.webp'
import Logo13 from '../img/microfon-logo.webp'
import Logo14 from '../img/studiosantral-logo.webp'
import Logo15 from '../img/koalay-logo.webp'
import Logo16 from '../img/bilira-logo.webp'
import Logo17 from '../img/naregitim-logo.webp'

const logos = [
  [
    { src: Logo1, alt: 'odeal' },
    { src: Logo2, alt: 'hesapkurdu' },
    { src: Logo3, alt: 'voltlines' },
    { src: Logo4, alt: 'epias' },
    { src: Logo5, alt: 'maxpotential' },
    { src: Logo6, alt: 'sdsprestij' },
  ],
  [
    { src: Logo7, alt: 'alotech' },
    { src: Logo8, alt: 'reos' },
    { src: Logo9, alt: 'norma' },
    { src: Logo10, alt: 'mia' },
    { src: Logo11, alt: 'turkuaz' },
    { src: Logo12, alt: 'map' },
  ],
  [
    { src: Logo13, alt: 'microfon' },
    { src: Logo14, alt: 'studiosantral' },
    { src: Logo15, alt: 'koalay' },
    { src: Logo16, alt: 'bilira' },
    { src: Logo17, alt: 'naregitim' },
  ],
]

export const CorporateSlider = () => {
  const [currentRowIndex, setCurrentRowIndex] = useState(0)

  useEffect(() => {
    const logoInterval = setInterval(() => {
      setCurrentRowIndex((prevIndex) => (prevIndex + 1) % logos.length)
    }, 10250)
    return () => clearInterval(logoInterval)
  }, [])

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="corporate-logo-container">
            {logos[currentRowIndex].map((logo, index) => (
              <div
                key={index}
                className={`corporate-logo logo-delay-${index} col-lg-2 ${
                  logos[currentRowIndex].length == 4 ? 'col-6' : 'col-4'
                }`}
              >
                <img
                  className="corporate-logo-img"
                  src={logo.src}
                  alt={logo.alt}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  )
}

import React, { useState } from 'react'
import investorCardImage1 from '../img/AliArat.webp'
import investorCardImage2 from '../img/AliFuatErbil.webp'
import investorCardImage3 from '../img/BurhanKaracam.webp'
import investorCardImage5 from '../img/ErgunOzen.webp'
import investorCardImage6 from '../img/BediiCanYucaoglu.webp'
import investorCardImage7 from '../img/MelihMahmutYucaoglu.webp'
import investorCardImage8 from '../img/BirolYucel.webp'
import investorCardImage9 from '../img/OnurAydogan.webp'
import investorCardImage10 from '../img/GoktekinDincerler.webp'
import investorCardImage11 from '../img/AndacTurkmen.webp'
import vectorRight from '../img/vectorright.webp'
import { AboutModal } from './aboutmodal'

export const Investors = () => {
  const investors = [
    {
      id: 1,
      imgName: { investorCardImage1 }.investorCardImage1,
      fullName: 'Mehmet Ali Arat',
      content: `<p>
          Koç Üniversitesi Makine Mühendisliği Bölümü’nden mezun olan Ali Arat,
          Fordham Üniversitesi’nde MBA derecesini almıştır. Intera
          Investments’da Partner ve DOME Group’ta COO olarak görev almaktadır.
        </p>

        <p>
          Finans kariyerine 2011 yılında Finansbank Moscow’da başlayan Ali Arat,
          analist ve uzman olarak Dubai ve İstanbul’da The Abraaj Group’un
          gelişim sermayesi ve kaldıraçlı satın alma yatırımlarını yöneten
          takımında görev aldıktan sonra 2017 - 2018 yılları arasında The Abraaj
          Group’un Londra ofisinde özel krediler alanında çalışmıştır.
        </p>`,
    },
    {
      id: 2,
      imgName: { investorCardImage2 }.investorCardImage2,
      fullName: 'Ali Fuat Erbil',
      content: `<p>
          1990 yılında Orta Doğu Teknik Üniversitesi Bilgisayar Mühendisliği
          Bölümü’nden mezun olan Ali Fuat Erbil, 1992 yılında Bilkent
          Üniversitesi’nde MBA derecesi aldıktan sonra, 1998 yılında İstanbul
          Teknik Üniversitesi İşletme Fakültesi’nde Doktora çalışmalarını
          tamamlayarak Doktor unvanını almıştır.
        </p>

        <p>
          2015 - 2019 yılları arasında 4 yıl boyunca Garanti BBVA’nın Genel
          Müdürü ve Yönetim Kurulu Üyesi olarak görev yapan Ali Fuat Erbil,
          Garanti BBVA Yatırım, Garanti BBVA Leasing, Garanti BBVA Factoring,
          Garanti BBVA Teknoloji, Garanti Ödeme Sistemleri A.Ş., Garanti
          Emeklilik ve Hayat A.Ş., Garanti Bank SA (Romanya) ve GarantiBank
          International NV (Hollanda) şirketlerinde Yönetim Kurulu Başkanlığı
          yapmıştır.
        </p>

        <p>
          ABD merkezli fintech şirketi C2FO’nun Global Danışma Kurulu Üyesi,
          Credit Europe Bank Hollanda Yönetim Kurulu Üyesi, Doğan Holding
          Yönetim Kurulu Üyesi ve TAM Finans Yönetim Kurulu Üyesidir.
        </p>

        <p>
          Bankacılık kariyerine 1992 yılında İnterbank’ta Uzman Yardımcısı
          olarak başlamıştır. 1997 yılının ortasına kadar İnter Yatırım’da
          Araştırma Bölüm Yöneticiliği yaptıktan sonra 23 yıl sürecek olan
          Garanti BBVA kariyerine Alternatif Dağıtım Kanalları Birim Müdürü
          olarak başlamıştır. 1999 yılından itibaren 16 yıl boyunca Garanti
          BBVA’ya Genel Müdür Yardımcısı olarak hizmet vermiştir. Bu süre
          boyunca bireysel bankacılık, kurumsal bankacılık, dijital bankacılık,
          finansal kurumlar, mortgage ve insan kaynakları gibi bir çok alanda
          çalışmış, internet bankacılığı ve Bonus Card gibi bir çok projenin
          hayata geçirilmesine liderlik etmiştir.
        </p>`,
    },
    {
      id: 3,
      imgName: { investorCardImage3 }.investorCardImage3,
      fullName: 'Burhan Karaçam',
      content: `<p>
          Tarsus Amerikan Koleji ve Robert Kolej’den mezun olduktan sonra 1972
          yılında Boğaziçi Üniversitesi İş İdaresi Bölümü’nü tamamladı.
          1972-1981 yılları arasında Arthur Andersen’in Londra ve İstanbul
          ofislerinde görev alan Karaçam, Arthur Andersen İstanbul Ofisi Kurucu
          Ortaklarındandır. 1981 yılında Pamukbank T.A.Ş.’de Genel Müdür
          Yardımcısı olarak bankacılık kariyerine başladı. 1984-1987 yılları
          arasında Egebank Genel Müdürlüğü görevini sürdürdü. 1987 yılı Ağustos
          ayında Yapı Kredi Bankası Genel Müdürü oldu. Bu görevinin yanı sıra
          Çukurova Holding A.Ş. Yönetim Kurulu ve İcra Komitesi Üyeliklerini
          yürüttü. Yapı Kredi Bankası’ndaki 12 yıllık Genel Müdürlüğü boyunca
          ekibi ile birlikte bireysel bankacılık başta olmak üzere Türkiye’de
          bir çok yeniliğe imza atmıştır. Şubat 1999 tarihinde Çukurova
          Grubundaki görevlerinden ve Yapı Kredi’den ayrıldı. Aynı yıl, kendi
          ismi ve başkanlığı altında Stratejik İş Geliştirme ve Değer Kazandırma
          A.Ş.’yi faaliyete geçirdi.
        </p>

        <p>
          2000 yılı başlarında, Koç Grubu ile Koçbank projesine başladı. Burhan
          Karaçam, bu projeyi 2000 ile 2003 yılları arasındaki dönemde
          Koçbank'ın Yönetim Kurulu Başkanı ve CEO'su olarak yürüttü. Yeniden
          yapılandırmanın bir parçası olarak Koç Finansal Hizmetler A.Ş.'yi
          kurdu ve ilk Yönetim Kurulu Başkanı olarak görev yaptı. 2002 yılı
          sonunda, Koç Finansal Hizmetler A.Ş.’nin yeniden yapılandırılması ve
          İtalyan UniCredito arasında gerçekleştirdiği ortaklık ile projesini
          tamamladı.
        </p>

        <p>
          Halen finans, teknoloji, perakende, tekstil alanlarında ticari
          faaliyetlerine ek olarak Türkiye Eğitim Gönüllüleri Vakfı (TEGV),
          Sağlık ve Eğitim Vakfı (SEV), TEMA Vakfı, Boğaziçi Üniversitesi Vakfı,
          Robert Kolej Mezunlar Derneği, Tarsus Amerikan Koleji Mezunlar
          Derneği, Türk Sanayicileri ve İşinsanları Derneği (TÜSİAD), Türk-Yunan
          İş Konseyi, Fenerbahçe Kulübü, 1907 Fenerbahçe Derneği gibi dernek ve
          vakıflarda üyeliklerini sürdürmektedir.
        </p>

        <p>
          “Dönüşüm Yolculuğu” isimli bir kitabı ve belgesel filminin yanı sıra
          “Orası Yapı Kredi, Fark Oradaydı” isimli bir kitabı bulunmaktadır.
        </p>`,
    },
    {
      id: 5,
      imgName: { investorCardImage5 }.investorCardImage5,
      fullName: 'Sait Ergun Özen',
      content: `<p>
          1985 yılında New York State University Ekonomi Bölümü’nden mezun olan
          Sait Ergun Özen, 1997 yılında Harvard Business School’da Üst Düzey
          Yönetici Programı’nı tamamladı. 1 Nisan 2000 - 2 Eylül 2015 tarihleri
          arasında 15 yıl boyunca Garanti BBVA’nın Genel Müdürü olarak görev
          yapan Sait Ergun Özen, 14 Mayıs 2003 tarihinden beri Yönetim Kurulu
          Üyesi olarak görevine devam etmektedir.
        </p>

        <p>
          Garanti BBVA Yönetim Kurulu Üyeliğinin yanı sıra Beymen Group ve
          Boyner Perakende ve Tekstil Yatırımları A.Ş. Yönetim Kurulu Başkanı ve
          Atom Bank ve We Soda UK Yönetim Kurulu Üyesidir.
        </p>

        <p>
          Bankacılık kariyerine 1987 yılında Türkiye İş Bankası’nın Hazine
          bölümünde başladı. 1992 yılında Garanti BBVA’ya katılan Sait Ergun
          Özen, 1995 yılında Hazine ve Yatırım Bankacılığından Sorumlu Genel
          Müdür Yardımcısı oldu. Geçmişte Garanti BBVA Yatırım, Garanti BBVA
          Leasing, Garanti BBVA Factoring, Garanti BBVA Teknoloji, Garanti Ödeme
          Sistemleri A.Ş., Garanti Emeklilik ve Hayat A.Ş. ve Garanti Bank SA
          (Romanya) Yönetim Kurulu Başkanı, GarantiBank Moscow, Türkiye Bankalar
          Birliği, İstanbul Kültür Sanat Vakfı (IKSV), Türkiye Eğitim Vakfı
          (TEV), ve Türk Sanayicileri ve İşinsanları Derneği (TÜSİAD) Yönetim
          Kurulu Üyesi olarak görev aldı.
        </p>`,
    },
    {
      id: 6,
      imgName: { investorCardImage6 }.investorCardImage6,
      fullName: 'Bedii Can Yücaoğlu',
      content: `<p>
        Bedii Can Yücaoğlu, Map Yatırım’da yönetici ortak olarak görev
        yapmaktadır. Halihazırda Burda Bebek, Silahtarağa Gayrimenkul, Turkuaz
        Turizm, Map Elektronik ve Tarımsal Kimya şirketlerinde ortak ve yönetim
        kurulu üyesidir. Map Yatırım’a katılmadan önce Turkven Real Estate’de
        yönetici ve McKinsey & Company’de finans, lojistik ve girişim sermayesi
        sektörlerinde strateji geliştirme alanında danışman olarak görev
        yapmıştır. Harvard Business School’dan MBA ve Northwestern
        Üniversitesi’nden Elektrik Mühendisliği lisans derecelerine sahiptir.
        TÜSİAD Girişimcilik Yuvarlak Masası, Harvard Business School Alumni
        Board, Türk Eğitim Vakfı Varlık Komitesi ve Girişimcilik Vakfı Mütevelli
        Heyeti’nde aktif görev almaktadır. Can Yücaoğlu TÜSİAD Küresel İlişkiler
        ve AB Yuvarlak Masası Başkanlığı görevini sürdürmektedir.
      </p>`,
    },
    {
      id: 7,
      imgName: { investorCardImage7 }.investorCardImage7,
      fullName: 'Melih Murat Yücaoğlu',
      content: `<p>
          Melih Murat Yücaoğlu 2004 senesi Boston University Communications ve
          Business Management mezunu olarak son 13 senedir Turkuaz Turizm’in
          Genel Müdürü olarak görev yapmaktadır. MAP-Turkuaz Yatırım’ın ortağı
          ve Genel Müdürü olarak halihazırda Burda Bebek, Silahtarağa
          Gayrimenkul, Turkuaz Turizm, Map Elektronik ve Tarımsal Kimya
          şirketlerinde ortaklığı ve yönetim kurulu üyeliği bulunmaktadır. MAP
          Yatırım ile birlikte ve şahsi olarak yatırımcısı olduğu şirketler
          arasında N26, Cartesian Therapeutics, Firefly, Garaj Sepeti, Vivense,
          Betbull bulunmaktadır.
        </p>
        <p>
          2004-2006 seneleri arasında New York’ta trading yapmıştır (EDPA).
          2007-2010 arasında ise Romanya Garanti’de Hazine’de FX/MM trader
          görevi, Romanya Finansal Planlama ve analiz departmanının kuruluması
          ve yönetici görevlerinde yer almış ve son olarak Garanti BBVA’da
          Bireysel Bankacılık departmanında çalışmıştır. Geçmişte finans,
          uluslararası pazar geliştirme, strateji geliştirme ve müşteri
          ilişkileri bölümlerinde ve Evgin Menkul Değerler’de yönetici
          pozisyonunda çeşitli görevlerde yer almıştır.
        </p> `,
    },
    {
      id: 8,
      imgName: { investorCardImage8 }.investorCardImage8,
      fullName: 'Birol Yücel',
      content: `<p>
        Birol Yücel, 7 yatırım uzmanıyla özel sermaye platformu olarak otuza
        yakın ulusal ve uluslararası yatırımı yöneten MAP’ın kurucu ortağıdır.
        Daha önce Turkven Real Estate Fonu’nun yönetici direktörlüğünü, Yapı ve
        Kredi Bankası’nın mali işlerden sorumlu genel müdür yardımcılığını ve
        Arthur Andersen mali müşavirlik firmasının denetim ve danışmanlık
        bölümlerinde müdürlüğünü yapmıştır. Kariyeri sırasında 10 milyar doları
        aşan şirket satın alma ve birleştirme işlerinde kapsamlı rol almıştır.
        Birol Yücel bu güne kadar finansal hizmetler, telekom, teknoloji,
        üretim, gayrimenkul, turizm ve sağlık sektörlerinde yönetim kurulu
        düzeyinde çeşitli görevler üstlenmiştir.
      </p>`,
    },
    {
      id: 9,
      imgName: { investorCardImage9 }.investorCardImage9,
      fullName: 'Onur Aydoğan',
      content: `<p>
        Türkiye’nin lider yapılandırılmış ürünler ve varlığa dayalı menkul
        kıymet oluşturma ve dağıtma platformu OMG Capital Advisors’ın kurucu
        ortağıdır. Daha önce Merrill Lynch ve RBS’in Londra ofislerinde yükselen
        pazarlar bölümlerinde yönetici direktörlük, Wellington Management ve
        OneWorld Investment bünyesinde sabit getirili ve türev ürünler portföy
        yöneticiliği yapmıştır. Kariyeri sırasınca yükselen piyasalarda farklı
        varlık sınıflarında AUM’i 1 milyar doların üstünde olan portföy yönetmiş
        ve çeşitli endüstriler ve finansal kurumlar için 3 milyar doların
        üstünde yapılandırılmış borç işlemi tamamlamıştır. University of
        Massachusetts’den MBA ve makine mühendisliği lisans derecelerine
        sahiptir.
      </p>`,
    },
    {
      id: 10,
      imgName: { investorCardImage10 }.investorCardImage10,
      fullName: 'Göktekin Dinçerler',
      content: `<p>
          Bilkent Üniversitesi elektrik ve elektronik mühendisliği bölümünden
          mezunu olan Dinçerler, eğitimini Stanford Üniversitesi elektronik
          mühendisliği yüksek lisans derecesi ve Teksas Üniversitesi-Austin
          işletme yüksek lisans derecesi alarak sürdürdü.
        </p>

        <p>
          İş hayatına 1994 yılında McKinsey & Company ile Houston-Teksas
          ofisinde başladıktan sonra, yaklaşık on yıl süren yönetim danışmanlığı
          kariyerini McKinsey İstanbul ofisinde yönetici ortak olarak tamamladı.
          2004 yılında GSM operatörü Avea’ya (Aycell-Aria) satış, pazarlama ve
          müşteri hizmetlerinden sorumlu genel müdür yardımcısı olarak katıldı.
        </p>

        <p>
          Turkven’e 2006 yılında katılan Dinçerler, yönetici ortak olarak
          yatırım yapılan şirketlerin yönetim kurullarında görev aldı.
        </p>

        <p>
          Göktekin Dinçerler halen Mikro Yazılım grubunda yönetim kurulu ve icra
          kurulu başkanı olarak görev yapıyor. Bunun yanı sıra melek/azınlık
          yatırımcı olarak yer aldığı şirketlerde dönemsel olarak yönetim
          ekibine destek oluyor.
        </p>

        <p>
          Dinçerler ayrıca TOBB Girişim Sermayesi Sektör Meclisi başkanı ve TOBB
          Genç Girişimciler Kurulu başkan yardımcısı olarak girişimcilik
          ekosisteminin desteklenmesi ile ilgili çalışmalarda aktif olarak yer
          alıyor.
        </p>`,
    },
    {
      id: 11,
      imgName: { investorCardImage11 }.investorCardImage11,
      fullName: 'Andaç Türkmen',
      content: `<p>
          Marmara Üniversitesi, İşletme Fakültesi, Enformatik (Almanca)
          bölümünden 2002 yılında mezun oldu. 2006 – 2008 yılları arasında
          Boğaziçi Üniversitesi, Mühendislik ve Teknoloji Yönetimi bölümünde
          yüksek lisans eğitimini tamamladı.
        </p>

        <p>
          İş hayatına 2001 yılında Gordion Yazılım’da yazılımcı unvanı ile
          başladı. 2003 – 2011 yılları arasında Mikro Yazılım’da Takım Lideri ve
          2011 – 2013 yılları arasında Neta Menkul Değerler A.Ş’de yazılımcı
          unvanları ile görev aldı.
        </p>

        <p>
          2013 yılında Paraşüt Yazılım’ın kuruluşunda görev alan Andaç Türkmen,
          Mikrogrup icra kurulu üyesidir.
        </p>`,
    },
  ]

  const [openModal, setOpenModal] = useState(true)
  const [tempData, setTempData] = useState({
    imgName: '',
    fullName: '',
    content: '',
  })

  const handleClick = (event, key) => {
    event.preventDefault()
    setOpenModal(true)

    const investor = investors.filter((item) => item.id == key)
    setTempData(investor[0])
  }
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="mb-120-collapse-16">
            <div className="row m-auto">
              <div className="col-sm">
                <h2 className="about-team-title text-center mb-0">
                  Yatırımcılarımız
                </h2>
                <div className="about-team-message">
                  Mundi’nin vizyonuna inanan ve deneyimleriyle Mundi’ye değer
                  katan yatırımcılarımız var.
                </div>
              </div>
            </div>
            <div className="row mb-120 mt-30 m-auto">
              <div className="row row-cols-1 row-cols-sm-2 g-4 mt-25 mb-120 m-auto">
                <div className="col-sm-4">
                  <div className="card text-center about-card-body ">
                    <div className="text-center">
                      <img
                        className="card-img-top about-card-image text-center"
                        src={investorCardImage1}
                        alt="mundi"
                      ></img>{' '}
                    </div>
                    <div className="card-body about-text-frame">
                      <h5 className="card-title about-card-title">
                        Mehmet Ali Arat
                      </h5>
                      <div className="card-text about-card-text">
                        <a
                          className="nounderline about-link"
                          onClick={(event) => handleClick(event, 1)}
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModal"
                          href="/"
                          role="button"
                        >
                          Detaylı Bilgi
                          <img
                            className="ml-10 vector-right-icon"
                            src={vectorRight}
                            alt="mundi-vector"
                          ></img>
                        </a>{' '}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="card text-center about-card-body ">
                    <div className="text-center">
                      <img
                        className="card-img-top about-card-image text-center"
                        src={investorCardImage9}
                        alt="mundi"
                      ></img>{' '}
                    </div>
                    <div className="card-body about-text-frame">
                      <h5 className="card-title about-card-title">
                        Onur Aydoğan
                      </h5>
                      <div className="card-text about-card-text">
                        <a
                          className="nounderline about-link"
                          onClick={(event) => handleClick(event, 9)}
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModal"
                          href="/"
                          role="button"
                        >
                          Detaylı Bilgi
                          <img
                            className="ml-10 vector-right-icon"
                            src={vectorRight}
                            alt="mundi-vector"
                          ></img>
                        </a>{' '}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="card text-center about-card-body ">
                    <div className="text-center">
                      <img
                        className="card-img-top about-card-image text-center"
                        src={investorCardImage10}
                        alt="mundi"
                      ></img>{' '}
                    </div>
                    <div className="card-body about-text-frame">
                      <h5 className="card-title about-card-title">
                        Göktekin Dinçerler
                      </h5>
                      <div className="card-text about-card-text">
                        <a
                          className="nounderline about-link"
                          onClick={(event) => handleClick(event, 10)}
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModal"
                          href="/"
                          role="button"
                        >
                          Detaylı Bilgi
                          <img
                            className="ml-10 vector-right-icon"
                            src={vectorRight}
                            alt="mundi-vector"
                          ></img>
                        </a>{' '}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="card text-center about-card-body ">
                    <div className="text-center">
                      <img
                        className="card-img-top about-card-image text-center"
                        src={investorCardImage2}
                        alt="mundi"
                      ></img>{' '}
                    </div>
                    <div className="card-body about-text-frame">
                      <h5 className="card-title about-card-title">
                        Ali Fuat Erbil
                      </h5>
                      <p className="card-text about-card-text">
                        <a
                          className="nounderline about-link"
                          onClick={(event) => handleClick(event, 2)}
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModal"
                          href="/"
                          role="button"
                        >
                          Detaylı Bilgi
                          <img
                            className="ml-10 vector-right-icon"
                            src={vectorRight}
                            alt="mundi-vector"
                          ></img>
                        </a>{' '}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="card text-center about-card-body ">
                    <div className="text-center">
                      <img
                        className="card-img-top about-card-image text-center"
                        src={investorCardImage3}
                        alt="mundi"
                      ></img>{' '}
                    </div>
                    <div className="card-body about-text-frame">
                      <h5 className="card-title about-card-title">
                        Burhan Karaçam
                      </h5>
                      <p className="card-text about-card-text">
                        <a
                          className="nounderline about-link"
                          onClick={(event) => handleClick(event, 3)}
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModal"
                          href="/"
                          role="button"
                        >
                          Detaylı Bilgi
                          <img
                            className="ml-10 vector-right-icon"
                            src={vectorRight}
                            alt="mundi-vector"
                          ></img>
                        </a>{' '}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="card text-center about-card-body ">
                    <div className="text-center">
                      <img
                        className="card-img-top about-card-image text-center"
                        src={investorCardImage5}
                        alt="mundi"
                      ></img>{' '}
                    </div>
                    <div className="card-body about-text-frame">
                      <h5 className="card-title about-card-title">
                        Sait Ergun Özen
                      </h5>
                      <p className="card-text about-card-text">
                        <a
                          className="nounderline about-link"
                          onClick={(event) => handleClick(event, 5)}
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModal"
                          href="/"
                          role="button"
                        >
                          Detaylı Bilgi
                          <img
                            className="ml-10 vector-right-icon"
                            src={vectorRight}
                            alt="mundi-vector"
                          ></img>
                        </a>{' '}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="card text-center about-card-body ">
                    <div className="text-center">
                      <img
                        className="card-img-top about-card-image text-center"
                        src={investorCardImage11}
                        alt="mundi"
                      ></img>{' '}
                    </div>
                    <div className="card-body about-text-frame">
                      <h5 className="card-title about-card-title">
                        Andaç Türkmen
                      </h5>
                      <p className="card-text about-card-text">
                        <a
                          className="nounderline about-link"
                          onClick={(event) => handleClick(event, 11)}
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModal"
                          href="/"
                          role="button"
                        >
                          Detaylı Bilgi
                          <img
                            className="ml-10 vector-right-icon"
                            src={vectorRight}
                            alt="mundi-vector"
                          ></img>
                        </a>{' '}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="card text-center about-card-body ">
                    <div className="text-center">
                      <img
                        className="card-img-top about-card-image text-center"
                        src={investorCardImage6}
                        alt="mundi"
                      ></img>{' '}
                    </div>
                    <div className="card-body about-text-frame">
                      <h5 className="card-title about-card-title">
                        Bedii Can Yücaoğlu
                      </h5>
                      <p className="card-text about-card-text">
                        <a
                          className="nounderline about-link"
                          onClick={(event) => handleClick(event, 6)}
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModal"
                          href="/"
                          role="button"
                        >
                          Detaylı Bilgi
                          <img
                            className="ml-10 vector-right-icon"
                            src={vectorRight}
                            alt="mundi-vector"
                          ></img>
                        </a>{' '}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="card text-center about-card-body ">
                    <div className="text-center">
                      <img
                        className="card-img-top about-card-image text-center"
                        src={investorCardImage7}
                        alt="mundi"
                      ></img>{' '}
                    </div>
                    <div className="card-body about-text-frame">
                      <h5 className="card-title about-card-title">
                        Melih Murat Yücaoğlu
                      </h5>
                      <p className="card-text about-card-text">
                        <a
                          className="nounderline about-link"
                          onClick={(event) => handleClick(event, 7)}
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModal"
                          href="/"
                          role="button"
                        >
                          Detaylı Bilgi
                          <img
                            className="ml-10 vector-right-icon"
                            src={vectorRight}
                            alt="mundi-vector"
                          ></img>
                        </a>{' '}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="card text-center about-card-body ">
                    <div className="text-center">
                      <img
                        className="card-img-top about-card-image text-center"
                        src={investorCardImage8}
                        alt="mundi"
                      ></img>{' '}
                    </div>
                    <div className="card-body about-text-frame">
                      <h5 className="card-title about-card-title">
                        Birol Yücel
                      </h5>
                      <p className="card-text about-card-text">
                        <a
                          className="nounderline about-link"
                          onClick={(event) => handleClick(event, 8)}
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModal"
                          href="/"
                          role="button"
                        >
                          Detaylı Bilgi
                          <img
                            className="ml-10 vector-right-icon"
                            src={vectorRight}
                            alt="mundi-vector"
                          ></img>
                        </a>{' '}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AboutModal openModal={openModal} data={tempData} />
    </>
  )
}

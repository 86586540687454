import React, { useEffect } from 'react'
import feature1 from '../img/feature1.webp'
import feature2 from '../img/feature2.webp'
import feature4 from '../img/feature4.webp'

export const Features = () => {
  useEffect(() => {
    const items = document.querySelectorAll('.multi-slider .carousel-item')

    items.forEach((el) => {
      const minPerSlide = 2
      let next = el.nextElementSibling
      for (let i = 1; i < minPerSlide; i++) {
        if (!next) {
          next = items[0]
        }
        const cloneChild = next.cloneNode(true)
        el.appendChild(cloneChild.children[0])
        next = next.nextElementSibling
      }
    })
  }, [])

  return (
    <div className="container">
      <div className="row m-auto">
        <div className="col">
          <h2 className="features-body mb-0">Herkes için Mundi</h2>
          <div className="features-message">
            <p>En çok da senin için.</p>
          </div>
        </div>
      </div>
      <div className="row col-xs-12 col-sm-12 col-md-10 col-lg-8 mx-auto mt-3 px-5 pb-120">
        <div
          className="carousel multi-slider slide px-0"
          id="recipeCarousel"
          data-bs-ride="carousel"
          data-bs-interval="360000000"
        >
          <div className="carousel-inner h-100" role="listbox">
            <div className="carousel-item h-100 active">
              <div className="col-xs-12 col-sm-6">
                <div className="card features-card-body h-100 border-0 mx-2">
                  <div className="text-center">
                    <img
                      className="img-fluid card-img-top text-center card-img-top-size"
                      src={feature1}
                      alt="mundi"
                    />
                  </div>
                  <div className="card-body card-body-margin-bottom">
                    <p className="card-title features-card-title">
                      Riske girmek istemeyenler
                    </p>
                    <p className="card-text features-card-text">
                      Riske girmek istemiyorum ama birikimleri değerlendirmekte
                      çok zorlanıyorum. Birikimlerimi erirken seyretmek gücüme
                      gidiyor.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="carousel-item h-100">
              <div className="col-xs-12 col-sm-6">
                <div className="card features-card-body h-100 border-0 mx-2">
                  <div className="text-center">
                    <img
                      className="img-fluid card-img-top text-center card-img-top-size"
                      src={feature2}
                      alt="mundi"
                    />
                  </div>
                  <div className="card-body card-body-margin-bottom">
                    <div className="card-title features-card-title">
                      Dövizini eritenler
                    </div>
                    <p className="card-text features-card-text">
                      Amerikan Doları birikimlerim var ancak getiri elde
                      edemiyorum. Dolarlarım yattıkça birikimlerim enflasyona
                      karşı eriyor.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="carousel-item h-100">
              <div className="col-xs-12 col-sm-6">
                <div className="card features-card-body h-100 border-0 mx-2">
                  <div className="text-center">
                    <img
                      className="img-fluid card-img-top text-center card-img-top-size"
                      src={feature4}
                      alt="mundi"
                    />
                  </div>
                  <div className="card-body card-body-margin-bottom">
                    <div className="card-title features-card-title">
                      Tek bir orana sıkışmış hissedenler
                    </div>
                    <p className="card-text features-card-text">
                      Daha yüksek getiri fırsatları bulsam bile her kurumda
                      yatırım hesabı açmak istemiyorum. Paramın takibini
                      kaybediyorum.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="carousel-indicators">
            <button
              type="button"
              data-bs-target="#recipeCarousel"
              data-bs-slide-to="0"
              className="active"
              aria-current="true"
              aria-label="Slide 1"
            ></button>
            <button
              type="button"
              data-bs-target="#recipeCarousel"
              data-bs-slide-to="1"
              aria-label="Slide 2"
            ></button>
            <button
              type="button"
              data-bs-target="#recipeCarousel"
              data-bs-slide-to="2"
              aria-label="Slide 3"
            ></button>
          </div>
          <button
            className="carousel-control-prev d-none d-sm-block"
            type="button"
            data-bs-target="#recipeCarousel"
            data-bs-slide="prev"
          >
            <span
              className="carousel-control-prev-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button
            className="carousel-control-next d-none d-sm-block"
            type="button"
            data-bs-target="#recipeCarousel"
            data-bs-slide="next"
          >
            <span
              className="carousel-control-next-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>
      </div>
    </div>
  )
}

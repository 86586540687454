import React from 'react'

export const ExpressConsent = () => {
  return (
    <>
      <section className="legalinformation">
        <div className="container">
          <div className="row mb-60">
            <div className="col-sm-12">
              <div className="mt-50 mb-120">
                <p className="legal-heading text-center ">
                  MUDİ FİNANSAL TEKNOLOJİ HİZMETLERİ A.Ş.
                  <br />
                  MÜŞTERİLERE YÖNELİK KİŞİSEL VERİLERİN İŞLENMESİNE İLİŞKİN
                  <br />
                  AÇIK RIZA METNİ
                </p>
                <p className="legal-text align-justify">
                  Mudi Finansal Teknoloji Hizmetleri A.Ş. (“Şirket”), tarafından
                  Kişisel Verilerin İşlenmesi Hakkında Aydınlatma Metni’nde
                  belirtilen amaçlar doğrultusunda kişisel verilerinizin hukuka
                  uygun işlenebilmesi için aşağıdaki amaçlarla işlenmesini
                  onayınıza sunuyoruz. Kişisel verilerinizin işlenmesine ilişkin
                  açık rızanızı, lütfen aşağıda (E) Evet veya (H) Hayır şeklinde
                  işaretleyerek açıkça belirtiniz.
                </p>
                <table className="table-5">
                  <tbody>
                    <tr>
                      <td>&nbsp;&nbsp;&nbsp;</td>
                      <td>
                        <b>ONAY</b>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Aydınlatma metnini de dikkate alarak kişisel
                        verilerinizin yurtdışındaki iş ortakları, tedarikçiler
                        üçüncü taraf hizmet sağlayıcılarına aktarılmasına onay
                        veriyorsanız işaretleyiniz.
                      </td>
                      <td>&nbsp;</td>
                    </tr>
                  </tbody>
                </table>
                <br />
                <br />
                <table className="table-3">
                  <tbody>
                    <tr>
                      <td>
                        <i>
                          Yukarıda yer alan konularda kişisel verilerimin
                          işlenmesine açık, özgür iradeyle ve bilgilendirmeye
                          dayalı şekilde onay verdiğimi, aydınlatma metnini
                          tebliğ aldığımı, okuduğumu ve anladığımı kabul ederim.
                        </i>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <br />
                        <b>Müşteri</b>
                        <br />
                        <b>(İsim ve İmza):</b>
                        <br />
                        <br />
                      </td>
                    </tr>
                  </tbody>
                </table>
                <br />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

import React from 'react'
import investorCardImage1 from '../img/AliArat.webp'
import investorCardImage2 from '../img/AliFuatErbil.webp'
import investorCardImage3 from '../img/BurhanKaracam.webp'
import investorCardImage5 from '../img/ErgunOzen.webp'
import investorCardImage6 from '../img/BediiCanYucaoglu.webp'
import investorCardImage7 from '../img/MelihMahmutYucaoglu.webp'
import investorCardImage8 from '../img/BirolYucel.webp'
import investorCardImage9 from '../img/OnurAydogan.webp'
import investorCardImage10 from '../img/GoktekinDincerler.webp'
import investorCardImage11 from '../img/AndacTurkmen.webp'
import investorCardImage12 from '../img/DilekDayinlarli.webp'
import investorCardImage13 from '../img/AliFerzanNalbantoglu.webp'
import speedInvestLogo from '../img/speedinvest-logo.webp'
import debaVenturesLogo from '../img/debaventures-logo.webp'

export const Investors = () => {
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="mb-120-collapse-16">
            <div className="row m-auto">
              <div className="col-sm">
                <h2 className="about-team-title text-center mb-0">
                  Yatırımcılarımız
                </h2>
                <div className="about-team-message">
                  Mundi’nin vizyonuna inanan ve deneyimleriyle Mundi’ye değer
                  katan yatırımcılarımız var.
                </div>
              </div>
            </div>
            <div className="row mx-auto">
              <div className="col-sm-12">
                <div className="row mx-auto about-investor-logo-frame">
                  <div className="col-sm-6">
                    <div className="card about-card-body h-100">
                      <div className="d-flex justify-content-center my-auto">
                        <img
                          src={speedInvestLogo}
                          alt="speedinvest"
                          className="about-investor-logo"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="card about-card-body h-100">
                      <div className="d-flex justify-content-center my-auto">
                        <img
                          src={debaVenturesLogo}
                          alt="debaventures"
                          className="about-investor-logo"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mb-120 mt-30 m-auto">
              <div className="row row-cols-1 row-cols-sm-2 g-4 mt-25 mb-120 m-auto">
                <div className="col-sm-4 col-lg-3">
                  <div className="card text-center about-card-body h-100">
                    <div className="text-center">
                      <img
                        className="card-img-top about-card-image text-center"
                        src={investorCardImage1}
                        alt="mundi"
                      ></img>{' '}
                    </div>
                    <div className="card-body about-text-frame">
                      <h5 className="card-title about-card-title">
                        Mehmet Ali Arat
                      </h5>
                    </div>
                  </div>
                </div>
                <div className="col-sm-4 col-lg-3">
                  <div className="card text-center about-card-body h-100">
                    <div className="text-center">
                      <img
                        className="card-img-top about-card-image text-center"
                        src={investorCardImage9}
                        alt="mundi"
                      ></img>{' '}
                    </div>
                    <div className="card-body about-text-frame">
                      <h5 className="card-title about-card-title">
                        Onur Aydoğan
                      </h5>
                    </div>
                  </div>
                </div>
                <div className="col-sm-4 col-lg-3">
                  <div className="card text-center about-card-body h-100">
                    <div className="text-center">
                      <img
                        className="card-img-top about-card-image text-center"
                        src={investorCardImage12}
                        alt="mundi"
                      ></img>{' '}
                    </div>
                    <div className="card-body about-text-frame">
                      <h5 className="card-title about-card-title">
                        Dilek Dayınlarlı
                      </h5>
                    </div>
                  </div>
                </div>
                <div className="col-sm-4 col-lg-3">
                  <div className="card text-center about-card-body h-100">
                    <div className="text-center">
                      <img
                        className="card-img-top about-card-image text-center"
                        src={investorCardImage10}
                        alt="mundi"
                      ></img>{' '}
                    </div>
                    <div className="card-body about-text-frame">
                      <h5 className="card-title about-card-title">
                        Göktekin Dinçerler
                      </h5>
                    </div>
                  </div>
                </div>
                <div className="col-sm-4 col-lg-3">
                  <div className="card text-center about-card-body h-100">
                    <div className="text-center">
                      <img
                        className="card-img-top about-card-image text-center"
                        src={investorCardImage2}
                        alt="mundi"
                      ></img>{' '}
                    </div>
                    <div className="card-body about-text-frame">
                      <h5 className="card-title about-card-title">
                        Ali Fuat Erbil
                      </h5>
                    </div>
                  </div>
                </div>
                <div className="col-sm-4 col-lg-3">
                  <div className="card text-center about-card-body h-100">
                    <div className="text-center">
                      <img
                        className="card-img-top about-card-image text-center"
                        src={investorCardImage3}
                        alt="mundi"
                      ></img>{' '}
                    </div>
                    <div className="card-body about-text-frame">
                      <h5 className="card-title about-card-title">
                        Burhan Karaçam
                      </h5>
                    </div>
                  </div>
                </div>
                <div className="col-sm-4 col-lg-3">
                  <div className="card text-center about-card-body h-100">
                    <div className="text-center">
                      <img
                        className="card-img-top about-card-image text-center"
                        src={investorCardImage13}
                        alt="mundi"
                      ></img>{' '}
                    </div>
                    <div className="card-body about-text-frame">
                      <h5 className="card-title about-card-title">
                        Ali Nalbantoğlu
                      </h5>
                    </div>
                  </div>
                </div>
                <div className="col-sm-4 col-lg-3">
                  <div className="card text-center about-card-body h-100">
                    <div className="text-center">
                      <img
                        className="card-img-top about-card-image text-center"
                        src={investorCardImage5}
                        alt="mundi"
                      ></img>{' '}
                    </div>
                    <div className="card-body about-text-frame">
                      <h5 className="card-title about-card-title">
                        Sait Ergun Özen
                      </h5>
                    </div>
                  </div>
                </div>
                <div className="col-sm-4 col-lg-3">
                  <div className="card text-center about-card-body h-100">
                    <div className="text-center">
                      <img
                        className="card-img-top about-card-image text-center"
                        src={investorCardImage11}
                        alt="mundi"
                      ></img>{' '}
                    </div>
                    <div className="card-body about-text-frame">
                      <h5 className="card-title about-card-title">
                        Andaç Türkmen
                      </h5>
                    </div>
                  </div>
                </div>
                <div className="col-sm-4 col-lg-3">
                  <div className="card text-center about-card-body h-100">
                    <div className="text-center">
                      <img
                        className="card-img-top about-card-image text-center"
                        src={investorCardImage6}
                        alt="mundi"
                      ></img>{' '}
                    </div>
                    <div className="card-body about-text-frame">
                      <h5 className="card-title about-card-title">
                        Bedii Can Yücaoğlu
                      </h5>
                    </div>
                  </div>
                </div>
                <div className="col-sm-4 col-lg-3">
                  <div className="card text-center about-card-body h-100 ">
                    <div className="text-center">
                      <img
                        className="card-img-top about-card-image text-center"
                        src={investorCardImage7}
                        alt="mundi"
                      ></img>{' '}
                    </div>
                    <div className="card-body about-text-frame">
                      <h5 className="card-title about-card-title">
                        Melih Murat Yücaoğlu
                      </h5>
                    </div>
                  </div>
                </div>
                <div className="col-sm-4 col-lg-3">
                  <div className="card text-center about-card-body h-100 ">
                    <div className="text-center">
                      <img
                        className="card-img-top about-card-image text-center"
                        src={investorCardImage8}
                        alt="mundi"
                      ></img>{' '}
                    </div>
                    <div className="card-body about-text-frame">
                      <h5 className="card-title about-card-title">
                        Birol Yücel
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

import React, { useState } from 'react'
import { AppDownloadModal } from './appdownloadmodal'

export const DownloadButton = () => {
  const [openModal, setOpenModal] = useState(true)

  function handleClick(event) {
    event.preventDefault()
    setOpenModal(true)
  }
  return (
    <>
      <button
        className="reserve-button d-none d-sm-block m-md-auto"
        onClick={handleClick}
        data-bs-toggle="modal"
        data-bs-target="#appModal"
      >
        Uygulamayı İndir
      </button>
      <div className="text-center">
        <a
          className="reserve-button nounderline order-sm-1 order-md-3 d-sm-none mx-auto"
          href="https://onelink.to/mundi"
          target="_blank"
          rel="noreferrer"
        >
          Uygulamayı İndir
        </a>
      </div>
      <div>
        <AppDownloadModal openModal={openModal} />
      </div>
    </>
  )
}

import React from 'react'
import phone1 from '../img/phone1.webp'
import phone4 from '../img/phone4.webp'
import shortphone1 from '../img/shortphone1.webp'
import shortphone4 from '../img/shortphone4.webp'

export const Slider = () => {
  return (
    <div className="container px-0">
      <div className="row col-12 mx-auto">
        <div
          id="carouselExampleIndicators"
          className="carousel slide phone-slider px-0"
          data-bs-ride="carousel"
          data-bs-interval="36000000"
        >
          <div className="container">
            <div className="carousel-indicators">
              <button
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide-to="0"
                className="active"
                aria-current="true"
                aria-label="Slide 1"
              ></button>
              <button
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide-to="1"
                aria-label="Slide 2"
              ></button>
            </div>
            <div className="carousel-inner justify-content-center mx-auto">
              <div className="carousel-item active">
                <div className="row row-cols-1 row-cols-sm-2 g-4 justify-content-center mx-auto">
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 order-2 order-sm-1 mx-auto">
                    <div className="summary-title">
                      Mundi ile birikimlerini değerlendir
                    </div>
                    <div className="summary-message">
                      <ul>
                        <li>
                          {' '}
                          Yüksek getiri aramana gerek yok. Mundi’nin aracı kurum
                          partneri birikimlerin için sana uygun yatırım
                          imkanlarını ve bakiyen için avantajlı nema oranlarını
                          bulur.
                        </li>
                        <li className="mt-30">
                          Mundi’ye aktardığın birikimin boşta kalmaz, sermaye
                          piyasası yatırımlarına yönlendirilinceye kadar
                          otomatik olarak mevzuata uygun şekilde nemalanmaya
                          başlar.
                        </li>
                      </ul>
                      <div className="mt-55">
                        <div className="d-none d-sm-block d-flex align-items-center">
                          <button
                            type="button"
                            className="reserve-button text-center align-self-center text-nowrap"
                            data-bs-toggle="modal"
                            data-bs-target="#appModal"
                          >
                            Uygulamayı İndir
                          </button>
                        </div>
                        <div className="mb-3 d-block d-sm-none">
                          <button className="standart-button fullsize-button w-100">
                            <a
                              className="reserve-button nounderline text-center d-sm-none"
                              href="https://onelink.to/mundi"
                              target="_blank"
                              rel="noreferrer"
                            >
                              Uygulamayı İndir
                            </a>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 order-1 order-sm-2 mx-auto">
                    <div className="text-center">
                      <img
                        className="phone-slider-img-size mx-auto d-none d-sm-none d-md-block mt-75"
                        src={phone1}
                        alt="mundi-vector"
                      ></img>{' '}
                      <img
                        className="phone-slider-img-size d-md-none"
                        src={shortphone1}
                        alt="mundi-vector"
                      ></img>{' '}
                    </div>
                  </div>
                </div>
                <div className="mb-120"></div>
              </div>
              <div className="carousel-item">
                <div className="row row-cols-1 row-cols-sm-2 g-4 justify-content-center mx-auto">
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 order-2 order-sm-1 mx-auto">
                    <div className="summary-title">
                      İşlem maliyeti stresine girme
                    </div>
                    <div className="summary-message">
                      <ul>
                        <li>
                          Mundi’den banka hesaplarına yapacağın Türk Lirası
                          transferlerinde havale veya EFT ücreti uygulanmaz.
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 order-1 order-sm-2 mx-auto">
                    <div className="text-center">
                      <img
                        className="phone-slider-img-size mx-auto d-none d-sm-none d-md-block mt-75"
                        src={phone4}
                        alt="mundi-vector"
                      ></img>{' '}
                      <img
                        className="phone-slider-img-size d-md-none"
                        src={shortphone4}
                        alt="mundi-vector"
                      ></img>{' '}
                    </div>
                  </div>
                </div>
                <div className="mb-120"></div>
              </div>
            </div>
            <button
              className="carousel-control-prev d-none d-md-block d-sm-none"
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide="prev"
            >
              <span
                className="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
              <span className="visually-hidden">Previous</span>
            </button>
            <button
              className="carousel-control-next d-none d-md-block d-sm-none"
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide="next"
            >
              <span
                className="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              <span className="visually-hidden">Next</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

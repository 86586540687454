import React, { useState, useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import img from '../img/mudi-logo.webp'
import facebook from '../img/Facebook.webp'
import twitter from '../img/Twitter.webp'
import instagram from '../img/Instagram.webp'
import linkedin from '../img/LinkedIn.webp'
import applestore from '../img/appstore.webp'
import googleplay from '../img/googleplay.webp'
import { AppDownloadModal } from './appdownloadmodal'

export const Layout = () => {
  const [openModal, setOpenModal] = useState(true)

  const handleClick = (event) => {
    event.preventDefault()
    setOpenModal(true)
  }

  useEffect(() => {
    const links = Array.from(document.querySelectorAll('.nav-link'))
    links.forEach((link) => {
      if (link.getAttribute('href') === window.location.pathname) {
        link.classList.add('active')
      }
    })
  }, [])

  return (
    <>
      <section>
        <div className="container-sm">
          <nav className="navbar navbar-expand-xl navbar-light fixed-top bg3 mx-auto">
            <div className="container-sm mt-10">
              <a className="navbar-brand order-sm-0 order-md-0" href="/">
                <img className="menu-image" src={img} alt="logo"></img>
              </a>
              <div className="order-sm-1 order-xl-2 d-flex align-items-center">
                <button
                  type="button"
                  onClick={handleClick}
                  className="reserve-button text-center d-none d-md-block align-self-center text-nowrap"
                  data-bs-toggle="modal"
                  data-bs-target="#appModal"
                >
                  Uygulamayı İndir
                </button>
                <a
                  className="reserve-button nounderline text-center d-md-none my-auto"
                  href="https://2ykh.adj.st?adj_t=1dsp7b1f"
                  target="_blank"
                  rel="noreferrer"
                >
                  İndir
                </a>
                <button
                  className="navbar-toggler navbar-toggler-margin my-auto"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span className="navbar-toggler-icon"></span>
                </button>
              </div>
              <div
                className="order-sm-2 order-xl-1 collapse navbar-collapse ml-20 align-self"
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav text-center mx-auto">
                  <li className="nav-item text-center">
                    <a
                      className="nav-link menu-item text-center"
                      aria-current="page"
                      href="/"
                    >
                      Anasayfa
                    </a>
                  </li>
                  <li className="nav-item text-center">
                    <a
                      className="nav-link menu-item text-center"
                      href="/ozellikler"
                    >
                      Özellikler
                    </a>
                  </li>
                  <li className="nav-item text-center">
                    <a
                      className="nav-link menu-item text-center"
                      href="/hakkimizda"
                    >
                      Hakkımızda
                    </a>
                  </li>
                  <li className="nav-item text-center">
                    <a
                      className="nav-link menu-item text-center"
                      href="/destek"
                    >
                      Destek
                    </a>
                  </li>
                  <li className="nav-item text-center">
                    <a
                      className="nav-link menu-item text-center text-nowrap"
                      href="/kurumsal"
                    >
                      Şirketin için Mundi
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </div>
        <AppDownloadModal openModal={openModal} />
      </section>
      <section>
        <Outlet />
      </section>

      <footer className="footer">
        <div className="container-sm">
          <div className="row mx-auto mt-30">
            <div className="col-sm-4 mt-30 footer-padding">
              <div className="row">
                {' '}
                <div className="content-center-mobile">
                  <img className="footer-image" src={img} alt="logo"></img>
                </div>
                <div className="mt-25 content-center-mobile">
                  <div className="footer-icon-display">
                    <a
                      target="_blank"
                      href="https://www.facebook.com/getmundiapp"
                      rel="noreferrer"
                    >
                      <img
                        src={facebook}
                        alt="facebook"
                        className="facebook-icon-size"
                      ></img>
                    </a>
                    <a
                      target="_blank"
                      href="https://twitter.com/get_mundi"
                      rel="noreferrer"
                    >
                      <img
                        src={twitter}
                        alt="twitter"
                        className="twitter-icon-size"
                      ></img>
                    </a>
                    <a
                      target="_blank"
                      href="https://www.instagram.com/get_mundi/"
                      rel="noreferrer"
                    >
                      <img
                        src={instagram}
                        alt="instagram"
                        className="instagram-icon-size"
                      ></img>
                    </a>
                    <a
                      target="_blank"
                      href="https://www.linkedin.com/company/getmundiapp"
                      rel="noreferrer"
                    >
                      <img
                        src={linkedin}
                        alt="linkedin"
                        className="linkedin-icon-size"
                      ></img>
                    </a>
                  </div>
                </div>
              </div>
              <div className="row mt-20 content-center-mobile">
                <ul className="footer-badge-display list-unstyled">
                  <li className="mt-10">
                    <a
                      href="https://2ykh.adj.st?adj_t=1dwce6ml"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        className="footer-store-img img-fluid"
                        src={applestore}
                        alt="logo"
                      ></img>
                    </a>
                  </li>
                  <li className="mt-10 footer-badge-padding">
                    <a
                      href="https://2ykh.adj.st?adj_t=1dwce6ml"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        className="footer-store-img img-fluid"
                        src={googleplay}
                        alt="logo"
                      ></img>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-sm-8">
              <div className="row">
                <div className="col-sm-6 col-lg-3 mt-30">
                  <span className="title">Özellikler</span>
                  <p className="mt-25">
                    <a className="footer-link" href="/ozellikler#NedenMundi">
                      Neden Mundi?
                    </a>
                  </p>
                  <p>
                    <a
                      className="footer-link"
                      href="/ozellikler#ÜrünÖzellikleri"
                    >
                      Ürün Özellikleri
                    </a>
                  </p>
                  <p>
                    <a className="footer-link" href="/#GuncelFaizOranlari">
                      Güncel Nema Oranları
                    </a>
                  </p>
                  <p>
                    <a
                      className="footer-link"
                      href="/ozellikler#SeffafGelirModeli"
                    >
                      Şeffaf Gelir Modeli
                    </a>
                  </p>
                  <p>
                    <a
                      className="footer-link"
                      href="/ozellikler#KarsilastirmaTablosu"
                    >
                      Karşılaştırma Tablosu
                    </a>
                  </p>
                </div>
                <div className="col-sm-6 col-lg-3 mt-30">
                  <span className="title">Hakkımızda</span>
                  <p className="mt-25">
                    <a className="footer-link" href="/hakkimizda#Ekip">
                      Ekip
                    </a>
                  </p>
                  <p>
                    <a
                      className="footer-link"
                      href="/hakkimizda#Yatirimcilarimiz"
                    >
                      Yatırımcılarımız
                    </a>
                  </p>
                  <p>
                    <a className="footer-link" href="/hakkimizda#isortaklari">
                      İş Ortaklarımız
                    </a>
                  </p>
                  <p>
                    <a
                      className="footer-link"
                      href="/basin-kiti.pdf"
                      target="_blank"
                    >
                      Basın Kiti
                    </a>
                  </p>
                  <p>
                    <a className="footer-link" href="/yasal-bilgiler">
                      Yasal Bilgiler
                    </a>
                  </p>
                </div>
                <div className="col-sm-6 col-lg-3 mt-30">
                  <span className="title">Destek</span>
                  <p className="mt-25">
                    <a className="footer-link" href="/destek#SSS">
                      Sıkça Sorulan Sorular
                    </a>
                  </p>
                  <p>
                    <a className="footer-link" href="/destek#SupportForm">
                      İletişim Formu
                    </a>
                  </p>
                  <p>
                    <a
                      className="footer-link"
                      href="/destek#companyinformation"
                    >
                      Kurumsal Bilgiler
                    </a>
                  </p>
                </div>
                <div className="col-sm-6 col-lg-3 mt-30">
                  <span className="title text-nowrap">Şirketin için Mundi</span>
                  <p className="mt-25">
                    <a
                      className="footer-link"
                      href="/kurumsal#GuncelNemaOranlari"
                    >
                      Güncel Nema Oranları
                    </a>
                  </p>
                  <p>
                    <a
                      className="footer-link"
                      href="/kurumsal#SirketinIcinMundi"
                    >
                      Neden Mundi?
                    </a>
                  </p>
                  <p>
                    <a className="footer-link" href="/kurumsal#CorporateForm">
                      Hemen Başvur
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="footer-line">&nbsp;</div>
          </div>
          <div className="row mb-20">
            <div className="col-sm-12 col-md-6">
              <span className="copyright">
                © Mundi {new Date().getFullYear()} Tüm hakları saklıdır.
              </span>
            </div>
            <div className="col-sm-12 col-md-6 cookie-text-align">
              <a className="copyright" href="/kvkk">
                KVKK Politikası
              </a>{' '}
              |{' '}
              <a className="copyright" href="/CookiePolicy">
                Çevrimiçi Gizlilik ve Çerez Politikası
              </a>
            </div>
          </div>
        </div>
      </footer>
    </>
  )
}
